import { gql } from 'graphql-request';

const verdDriversQuery = gql`
  query($storeId: Int!, $branchId: Int, $includeDeactivated: Boolean, $driverId: Int) {
    allStoreDrivers(
      storeId: $storeId
      branchId: $branchId
      includeDeactivated: $includeDeactivated
      driverId: $driverId
    ) {
      id
      name
      phone
      email
      isActive
      branches
      onShift
      jobType
      language
      assignedOrdersCount
    }
  }
`;

export default verdDriversQuery;
