import React, { useContext, useState, useMemo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import { List, AutoSizer } from 'react-virtualized';

import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { Input } from 'components/form/elements';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import Store from 'common/components/Store';
import { PLACEHOLDER_SEARCH_STORES, NO_STORES_MATCHING } from 'constants/translations';
import { Text } from 'components/service';

const StoresSwitch = ({ queryToAppend = '' }) => {
  const { selectedStore, stores, setSelectedStore, setDzmsSelectedBranch } = useContext(userContext);
  const { translate, direction } = useContext(localeContext);
  const [searchValue, setSearchValue] = useState('');

  const filteredStores = useMemo(() => {
    const storesWithoutSelected = stores.filter(store => store?.id !== selectedStore.id);
    if (!searchValue || searchValue.length === 0) return storesWithoutSelected;
    return storesWithoutSelected.filter(
      store =>
        store?.titleEn?.toLowerCase().includes(searchValue.toLowerCase()) || store?.titleAr?.includes(searchValue),
    );
  }, [searchValue, stores]);

  const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => {
    const store = filteredStores[index];
    return (
      <div className="mb-4" key={key} style={style}>
        <Store
          isSelected={selectedStore.id === store.id}
          onClick={e => {
            e.stopPropagation();
            navigate(`/${store.id}/${queryToAppend}`);
            setSelectedStore(store);
            setDzmsSelectedBranch(null);
          }}
          key={index}
          {...store}
        />
      </div>
    );
  };

  return (
    <>
      <div className="divide-y divide-gray-300">
        <div className="mb-5">
          <Store
            onClick={e => {
              e.stopPropagation();
              navigate(`/${selectedStore.id}/${queryToAppend}`);
            }}
            isSelected
            {...selectedStore}
          />
        </div>
        <div className="pt-5 mb-5 flex justify-between items-center" style={{ direction }}>
          <Input
            type="text"
            search
            autoFocus
            icon={<SearchIcon />}
            width="full"
            value={searchValue}
            onChange={e => {
              const { value } = e.target;
              setSearchValue(value);
            }}
            placeholder={translate(PLACEHOLDER_SEARCH_STORES)}
          />
        </div>
      </div>
      <div className="h-full">
        {filteredStores?.length ? (
          <AutoSizer>
            {({ height, width }) => (
              <List
                width={width}
                height={height}
                rowCount={filteredStores.length}
                rowHeight={85}
                rowRenderer={rowRenderer}
                overscanRowCount={1}
                className='overflow-scroll'
              />
            )}
          </AutoSizer>
        ) : (
          <Text value={NO_STORES_MATCHING} />
        )}
      </div>
    </>
  );
};

StoresSwitch.propTypes = {
  queryToAppend: PropTypes.string,
};

export default StoresSwitch;
