import React, { useContext, useState } from "react"
import axios from "axios"
import * as R from "ramda"
import { Mutation } from "@apollo/client/react/components"
import { useQuery, useMutation } from "@apollo/client"
import { Formik, Form } from "formik"
import { context as userContext } from "context/user"
import { context as notificationsContext } from "context/notifications"
import { context as localeContext } from "context/locale"
import { Field, Text } from "components/service"
import * as translations from "constants/translations"
import { breadcrumbs } from "constants/translations"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import {
  Select,
  Input,
  Languages,
  Stepper,
  InfoCheckbox,
  PhoneInput,
  RadioList,
} from "components/form/elements"
import { Add, Toggle, Uploader, Modal } from "components/kit"
import { Countries } from "components/data"
import { Label, Row, Footer, Section, Container } from "components/form/generic"
import cx from "classnames"
import { useSelectedStore } from "hooks/index"
import { Button, Spinner } from "components/kit"
import * as data from "./data"
import * as schemas from "./schemas"
import * as paths from "paths.js"
import {
  uploadLegalImages,
  removeNationalBack,
  removeNationalFront,
  removeLicense,
  removeAuthorizedSignatory,
} from "rest.js"
import { BUSINESS_INFORMATION_LINKS } from "constants/helperLinks"

const frontTokenSource = axios.CancelToken.source()
const backTokenSource = axios.CancelToken.source()
const licenseTokenSource = axios.CancelToken.source()
const authorizedSignatoryTokenSource = axios.CancelToken.source()

export default () => {
  const { selectedStore } = useContext(userContext)
  const { lang, direction, translate } = useContext(localeContext)
  const notifications = useContext(notificationsContext)
  const storeId = useSelectedStore()
  const [loadingImages, setLoadingImages] = useState(false)
  const legalData = useQuery(schemas.LEGAL_DATA, {
    variables: { storeId: storeId },
    fetchPolicy: "network-only",
  })
  const settings = useQuery(schemas.SETTINGS, {
    variables: { storeId: storeId },
    fetchPolicy: "network-only",
  })
  const [updateSettings, { loading: isSettingsUpdating }] = useMutation(
    schemas.UPDATE_SETTINGS
  )
  const [updateLegal, { loading: isLegalUpdating }] = useMutation(
    schemas.UPDATE_LEGAL_DATA
  )
  const [addLegal, { loading: isLegalAdding }] = useMutation(
    schemas.ADD_LEGAL_DATA
  )
  const storeTypes = useQuery(schemas.STORE_TYPES, {
    fetchPolicy: "network-only",
  })
  const legalInfo = legalData.data && legalData.data.legalData
  const hasLegalData = !!legalInfo

  const uploadImages = data => {
    setLoadingImages(true)
    Promise.all(
      [
        data.nationalFrontCopyUrl &&
          uploadLegalImages(
            storeId,
            { front: data.nationalFrontCopyUrl },
            frontTokenSource.token
          ),
        data.nationalBackCopyUrl &&
          uploadLegalImages(
            storeId,
            { back: data.nationalBackCopyUrl },
            backTokenSource.token
          ),
        data.licenseCopyUrl &&
          uploadLegalImages(
            storeId,
            { license: data.licenseCopyUrl },
            licenseTokenSource.token
          ),
        data.authorizedSignatoryCopyUrl &&
          uploadLegalImages(
            storeId,
            {
              authorizedSignatory: data.authorizedSignatoryCopyUrl,
            },
            authorizedSignatoryTokenSource.token
          ),
      ].filter(R.identity)
    )
      .then(res => {
        res && setLoadingImages(false)
        notifications.show(
          <Text value={translations.STORE_INFORMATION_UPDATED} />
        )
      })
      .catch(err => {
        notifications.show(
          <Text value={translations.INVALID_FORMAT_UPLOAD} />,
          "error"
        )
        setLoadingImages(false)
      })
  }

  return (
    <Modal>
      {({ open, close }) => (
        <Mutation mutation={schemas.UPDATE_RESTAURANT}>
          {(updateRestaurant, { loading }) => (
            <Layout
              top={
                <Breadcrumbs
                  helperLinks={BUSINESS_INFORMATION_LINKS}
                  path={breadcrumbs.SETUP_BUSINESS_INFORMATION}
                />
              }
            >
              <div
                className={cx(
                  "pb-6 flex",
                  lang === "ar" && "flex-row-reverse"
                )}
              >
                <div className="w-full xl:w-2/3">
                  {legalData.loading || storeTypes.loading ? (
                    <Spinner />
                  ) : (
                    <Formik
                      initialValues={{
                        storeType:
                          settings.data && settings.data.settings.storeTypeId,
                        ...selectedStore,
                        taxInclusive: !!selectedStore.taxInclusive,
                        ...{
                          legal: {
                            firstName:
                              (legalInfo && legalInfo.firstName) || null,
                            lastName: (legalInfo && legalInfo.lastName) || null,
                            email: (legalInfo && legalInfo.email) || null,
                            nationalNumber:
                              (legalInfo && legalInfo.nationalNumber) || null,

                            nationalBackCopyUrl:
                              (legalInfo && legalInfo.nationalBackCopyUrl) ||
                              null,
                            nationalFrontCopyUrl:
                              (legalInfo && legalInfo.nationalFrontCopyUrl) ||
                              null,
                            companyNameAr: 
                              (legalInfo && legalInfo.companyNameAr) || null,
                            companyNameEn:
                              (legalInfo && legalInfo.companyNameEn) || null,
                            vatIdNumber: 
                              (legalInfo && legalInfo.vatIdNumber) || null,
                            licenseNumber:
                              (legalInfo && legalInfo.licenseNumber) || null,
                            licenseCopyUrl:
                              (legalInfo && legalInfo.licenseCopyUrl) || null,
                            authorizedSignatoryCopyUrl:
                              (legalInfo &&
                                legalInfo.authorizedSignatoryCopyUrl) ||
                              null,
                            isLicensed:
                              (legalInfo && legalInfo.isLicensed) || null,
                          },
                          phoneNumber:
                            (legalInfo && legalInfo.phoneNumber) || "",
                        },
                      }}
                      validationSchema={data.validationSchema}
                      onSubmit={async data => {
                        try {
                          await Promise.all(
                            [
                              updateSettings({
                                variables: {
                                  storeId,
                                  storeTypeId: data.storeType,
                                },
                              }),
                              updateRestaurant({
                                variables: {
                                  id: selectedStore.id,
                                  preferredLanguage: data.preferredLanguage,
                                  titleEn: data.titleEn,
                                  titleAr: data.titleAr,
                                  taxPercentage: data.taxPercentage,
                                  taxInclusive: data.taxInclusive,
                                },
                              }),
                              hasLegalData
                                ? updateLegal({
                                    variables: {
                                      storeId: selectedStore.id,
                                      firstName: data.legal.firstName || null,
                                      lastName: data.legal.lastName || null,
                                      email: data.legal.email || null,
                                      nationalNumber:
                                        data.legal.nationalNumber || null,
                                      phoneNumber: data.phoneNumber || null,
                                      nationalBackCopyUrl:
                                        data.legal.nationalBackCopyUrl || null,
                                      nationalFrontCopyUrl:
                                        data.legal.nationalFrontCopyUrl || null,
                                      companyNameAr: 
                                        data.legal.companyNameAr || null,
                                      companyNameEn: 
                                        data.legal.companyNameEn || null,
                                      vatIdNumber: 
                                        data.legal.vatIdNumber || null,
                                      licenseNumber:
                                        data.legal.licenseNumber || null,
                                      licenseCopyUrl:
                                        data.legal.licenseCopyUrl || null,
                                      authorizedSignatoryCopyUrl:
                                        data.legal.authorizedSignatoryCopyUrl ||
                                        null,
                                      isLicensed: data.legal.isLicensed || null,
                                    },
                                  }).then(res => {
                                    uploadImages(data)
                                  })
                                : addLegal({
                                    variables: {
                                      storeId: selectedStore.id,
                                      firstName: data.legal.firstName || null,
                                      lastName: data.legal.lastName || null,
                                      email: data.legal.email || null,
                                      nationalNumber:
                                        data.legal.nationalNumber || null,
                                      phoneNumber: data.phoneNumber || null,
                                      nationalBackCopyUrl:
                                        data.legal.nationalBackCopyUrl || null,
                                      nationalFrontCopyUrl:
                                        data.legal.nationalFrontCopyUrl || null,
                                      companyNameAr: 
                                        data.legal.companyNameAr || null,
                                      companyNameEn: 
                                        data.legal.companyNameEn || null,
                                      vatIdNumber: 
                                        data.legal.vatIdNumber || null,
                                      licenseNumber:
                                        data.legal.licenseNumber || null,
                                      licenseCopyUrl:
                                        data.legal.licenseCopyUrl || null,
                                      authorizedSignatoryCopyUrl:
                                        data.legal.authorizedSignatoryCopyUrl ||
                                        null,
                                      isLicensed: data.legal.isLicensed || null,
                                    },
                                  }).then(res => {
                                    uploadImages(data)
                                  }),
                            ].filter(R.identity)
                          )
                        } catch (error) {
                          Object.keys(
                            error.graphQLErrors[0].extensions.exception.body
                          ).forEach(err => {
                            const value =
                              error.graphQLErrors[0].extensions.exception.body[
                                err
                              ]
                            notifications.show(`${err} ${value[0]}`, "error")
                          })
                        }
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          <Section
                            title={<Text value={translations.STORE_INFO} />}
                          >
                            <Text
                              value={translations.STORE_NAME}
                              className="text-base -mb-4"
                            />
                            <Row className="flex-col md:flex-row">
                              <div className="flex flex-col md:flex-row justify-between items-start">
                                <div
                                  className={cx(
                                    "w-full mb-6 md:mb-0",
                                    lang === "en"
                                      ? "mr-0 md:mr-4"
                                      : "ml-0 md:ml-4"
                                  )}
                                >
                                  <Label
                                    title={
                                      <Text value={translations.ENGLISH} />
                                    }
                                    testIdSwitchLangEN="store-name-switch-en"
                                    testIdSwitchLangAR="store-name-switch-ar"
                                    testIdswitchLang="store-name-switch-lang"
                                    subtitle={
                                      <Text
                                        value={
                                          translations.DESC_ENGLISH_STORE_NAME
                                        }
                                      />
                                    }
                                  >
                                    <Field
                                      type="text"
                                      name="titleEn"
                                      placeholder="Starbucks"
                                      component={Input}
                                      data-testid="store-name-en"
                                    />
                                  </Label>
                                </div>
                                <div className="w-full mb-0 md:mb-0">
                                  <Label
                                    title={<Text value={translations.ARABIC} />}
                                    testIdSwitchLangEN="store-name-switch-en"
                                    testIdSwitchLangAR="store-name-switch-ar"
                                    testIdswitchLang="store-name-switch-lang"
                                    subtitle={
                                      <Text
                                        value={
                                          translations.DESC_ARABIC_STORE_NAME
                                        }
                                      />
                                    }
                                  >
                                    <Field
                                      direction="rtl"
                                      type="text"
                                      name="titleAr"
                                      placeholder="اسم المطعم"
                                      component={Input}
                                      data-testid="store-name-ar"
                                    />
                                  </Label>
                                </div>
                              </div>
                            </Row>
                            <Row>
                              <Label
                                htmlFor="storeType"
                                title={<Text value={translations.STORE_TYPE} />}
                                subtitle={
                                  <Text
                                    value={translations.YOUR_STORE_INDUSTRY}
                                  />
                                }
                              >
                                <Field
                                  name="storeType"
                                  placeholder={
                                    lang === "en"
                                      ? translations.SELECT_YOUR_STORE_TYPE[0]
                                      : translations.SELECT_YOUR_STORE_TYPE[1]
                                  }
                                  component={Select}
                                  testId="store-type"
                                >
                                  {storeTypes.data.storeTypes.map((item, i) => (
                                    <option
                                      data-testid="data-store-type"
                                      value={item.id}
                                      key={i}
                                    >
                                      {translate(item)}
                                    </option>
                                  ))}
                                </Field>
                              </Label>
                            </Row>
                            <Row>
                              <Label
                                htmlFor="countryId"
                                title={<Text value={translations.COUNTRY} />}
                              >
                                <Countries>
                                  {countries => (
                                    <Field
                                      disabled={true}
                                      name="countryId"
                                      placeholder={
                                        lang === "en"
                                          ? translations.SELECT_COUNTRY[0]
                                          : translations.SELECT_COUNTRY[1]
                                      }
                                      component={Select}
                                      testId="store-country"
                                    >
                                      {countries.map((c, i) => (
                                        <option
                                          data-testid="data-store-country"
                                          key={i}
                                          value={c.id}
                                        >
                                          {c.titleEn}
                                        </option>
                                      ))}
                                    </Field>
                                  )}
                                </Countries>
                              </Label>
                              <Label
                                htmlFor="preferredLanguage"
                                title={<Text value={translations.LANGUAGE} />}
                                subtitle={
                                  <Text
                                    value={translations.CHOOSE_STORE_LANGUAGE}
                                  />
                                }
                              >
                                <Field
                                  name="preferredLanguage"
                                  component={Languages}
                                  testId="store-switch-lang"
                                />
                              </Label>
                            </Row>
                          </Section>
                          <Section
                            title={<Text value={translations.LEGAL_INFO} />}
                          >
                            <Row>
                              <Label
                                title={<Text value={translations.FIRST_NAME} />}
                              >
                                <Field
                                  type="text"
                                  name="legal.firstName"
                                  placeholder={
                                    lang === "en"
                                      ? translations.FIRST_NAME_PLACEHOLDER[0]
                                      : translations.FIRST_NAME_PLACEHOLDER[1]
                                  }
                                  component={Input}
                                  data-testid="first-name"
                                />
                              </Label>
                              <Label
                                title={<Text value={translations.LAST_NAME} />}
                              >
                                <Field
                                  type="text"
                                  name="legal.lastName"
                                  placeholder={
                                    lang === "en"
                                      ? translations.LAST_NAME_PLACEHOLDER[0]
                                      : translations.LAST_NAME_PLACEHOLDER[1]
                                  }
                                  component={Input}
                                  data-testid="last-name"
                                />
                              </Label>
                            </Row>
                            <Row>
                              <Label
                                title={
                                  <Text value={translations.EMAIL_ADDRESS} />
                                }
                              >
                                <Field
                                  type="text"
                                  name="legal.email"
                                  placeholder={
                                    lang === "en"
                                      ? translations.EMAIL_PLACEHOLDER[0]
                                      : translations.EMAIL_PLACEHOLDER[1]
                                  }
                                  component={Input}
                                  data-testid="email-address"
                                />
                              </Label>
                              <Label
                                title={
                                  <Text
                                    value={translations.PHONE_NUMBER_ORDER}
                                  />
                                }
                              >
                                <Field
                                  type="text"
                                  name="phoneNumber"
                                  country={selectedStore.countryCode.toLowerCase()}
                                  placeholder={
                                    lang === "en"
                                      ? translations
                                          .MOBILE_NUMBER_PLACEHOLDER[0]
                                      : translations
                                          .MOBILE_NUMBER_PLACEHOLDER[1]
                                  }
                                  component={PhoneInput}
                                  testId="phone-number"
                                />
                              </Label>
                            </Row>
                            <Row>
                              <Label
                                title={
                                  <Text
                                    value={translations.NATIONAL_ID_NUMBER}
                                  />
                                }
                              >
                                <Field
                                  type="text"
                                  name="legal.nationalNumber"
                                  placeholder={
                                    lang === "en"
                                      ? translations.ENTER_VALID_ID_NUMBER[0]
                                      : translations.ENTER_VALID_ID_NUMBER[1]
                                  }
                                  component={Input}
                                  data-testid="legal-national-number"
                                />
                              </Label>
                              <div />
                            </Row>
                            <Row>
                              <Label
                                title={
                                  <Text
                                    value={translations.UPLOAD_NATIONAL_ID}
                                  />
                                }
                                description={
                                  <Text value={translations.FRONT_COPY} />
                                }
                              >
                                <Uploader
                                  imageUrl={
                                    values.legal &&
                                    values.legal.nationalFrontCopyUrl
                                  }
                                  onRemove={() => {
                                    removeNationalFront(storeId)
                                    setFieldValue("nationalFrontCopyUrl", null)
                                    setFieldValue(
                                      "legal.nationalFrontCopyUrl",
                                      null
                                    )
                                  }}
                                  size="small"
                                  direction="row"
                                  info={
                                    <Text value={translations.UPLOAD_DRAG} />
                                  }
                                  justify="start"
                                  onUpload={image =>
                                    setFieldValue("nationalFrontCopyUrl", image)
                                  }
                                  onCancel={() => frontTokenSource.cancel()}
                                  close={close}
                                  open={open}
                                  accept="image/*"
                                  testId_uploadImg="upload-national-front-copy"
                                  testId_BtnUpload="upload-national-front-copy-btn"
                                />
                              </Label>
                              <Label
                                title={
                                  <span className="text-transparent">|</span>
                                }
                                description={
                                  <Text value={translations.BACK_COPY} />
                                }
                              >
                                <Uploader
                                  imageUrl={
                                    values.legal &&
                                    values.legal.nationalBackCopyUrl
                                  }
                                  onRemove={() => {
                                    removeNationalBack(storeId)
                                    setFieldValue("nationalBackCopyUrl", null)
                                    setFieldValue(
                                      "legal.nationalBackCopyUrl",
                                      null
                                    )
                                  }}
                                  size="small"
                                  direction="row"
                                  info={
                                    <Text value={translations.UPLOAD_DRAG} />
                                  }
                                  justify="start"
                                  onUpload={image =>
                                    setFieldValue("nationalBackCopyUrl", image)
                                  }
                                  onCancel={() => backTokenSource.cancel()}
                                  close={close}
                                  open={open}
                                  accept="image/*"
                                  testId_uploadImg="upload-national-back-copy"
                                  testId_BtnUpload="upload-national-back-copy-btn"
                                />
                              </Label>
                            </Row>
                          </Section>

                          <Field
                            title={
                              <Text value={translations.LICENSED_BUSINESS} />
                            }
                            description={
                              <Text value={translations.ENABLE_LICENCE} />
                            }
                            component={InfoCheckbox}
                            testId="btn-enable-disable-license"
                            name="legal.isLicensed"
                            body={
                              values.legal &&
                              values.legal.isLicensed && (
                                <div className="px-4">
                                  <Container>
                                    <Row>
                                      <Label
                                        title={
                                          <Text
                                            value={translations.COMPANY_NAME_EN}
                                          />
                                        }
                                      >
                                        <Field
                                          type="text"
                                          name="legal.companyNameEn"
                                          placeholder={
                                            lang === "en"
                                              ? translations
                                                  .ENTER_YOUR_OFFICIAL_EN[0]
                                              : translations
                                                  .ENTER_YOUR_OFFICIAL_EN[1]
                                          }
                                          component={Input}
                                          data-testid="company-name-en"
                                        />
                                      </Label>
                                      <Label
                                        title={
                                          <Text
                                            value={translations.COMPANY_NAME_AR}
                                          />
                                        }
                                      >
                                        <Field
                                          type="text"
                                          name="legal.companyNameAr"
                                          placeholder={
                                            lang === "en"
                                              ? translations
                                                  .ENTER_YOUR_OFFICIAL_AR[0]
                                              : translations
                                                  .ENTER_YOUR_OFFICIAL_AR[1]
                                          }
                                          component={Input}
                                          data-testid="company-name-ar"
                                        />
                                      </Label>
                                    </Row>
                                    <Row>
                                      <Label
                                        title={
                                          <Text
                                            value={translations.LICENCE_NUM}
                                          />
                                        }
                                      >
                                        <Field
                                          type="text"
                                          name="legal.licenseNumber"
                                          placeholder={
                                            lang === "en"
                                              ? translations.EX_444[0]
                                              : translations.EX_444[1]
                                          }
                                          component={Input}
                                          data-testid="license-number"
                                        />
                                      </Label>
                                      <Label
                                        title={
                                          <Text
                                            value={translations.VAT_ID_NUMBER}
                                          />
                                        }
                                      >
                                        <Field
                                          type="text"
                                          name="legal.vatIdNumber"
                                          placeholder={
                                            lang === "en"
                                              ? translations.EX_444[0]
                                              : translations.EX_444[1]
                                          }
                                          component={Input}
                                          data-testid="vat-id-number"
                                        />
                                      </Label>
                                    </Row>
                                    <Row>
                                      <Label
                                        title={
                                          <Text
                                            value={translations.LICENCE_COPY}
                                          />
                                        }
                                      >
                                        <Uploader
                                          imageUrl={
                                            values.legal &&
                                            values.legal.licenseCopyUrl
                                          }
                                          size="small"
                                          direction="row"
                                          onRemove={() => {
                                            setFieldValue(
                                              "licenseCopyUrl",
                                              null
                                            )
                                            setFieldValue(
                                              "legale.licenseCopyUrl",
                                              null
                                            )
                                            removeLicense(storeId)
                                          }}
                                          info={
                                            <Text
                                              value={translations.UPLOAD_DRAG}
                                            />
                                          }
                                          justify="start"
                                          onUpload={image =>
                                            setFieldValue(
                                              "licenseCopyUrl",
                                              image
                                            )
                                          }
                                          onCancel={() =>
                                            licenseTokenSource.cancel()
                                          }
                                          close={close}
                                          open={open}
                                          accept="image/*"
                                          testId_uploadImg="upload-license-copy"
                                          testId_BtnUpload="upload-license-copy-btn"
                                        />
                                      </Label>
                                      <Label
                                        title={
                                          <Text
                                            value={
                                              translations.AUTHORIZED_SIGNATORY
                                            }
                                          />
                                        }
                                      >
                                        <Uploader
                                          imageUrl={
                                            values.legal &&
                                            values.legal
                                              .authorizedSignatoryCopyUrl
                                          }
                                          size="small"
                                          direction="row"
                                          onRemove={() => {
                                            removeAuthorizedSignatory(storeId)
                                            setFieldValue(
                                              "authorizedSignatoryCopyUrl",
                                              null
                                            )
                                            setFieldValue(
                                              "legal.authorizedSignatoryCopyUrl",
                                              null
                                            )
                                          }}
                                          info={
                                            <Text
                                              value={translations.UPLOAD_DRAG}
                                            />
                                          }
                                          justify="start"
                                          onUpload={image =>
                                            setFieldValue(
                                              "authorizedSignatoryCopyUrl",
                                              image
                                            )
                                          }
                                          onCancel={() =>
                                            authorizedSignatoryTokenSource.cancel()
                                          }
                                          close={close}
                                          open={open}
                                          accept="image/*"
                                          testId_uploadImg="upload-authorized-signatory-copy"
                                          testId_BtnUpload="upload-authorized-signatory-copy-btn"
                                        />
                                      </Label>
                                    </Row>
                                    <Row>
                                      <Toggle
                                        testId={"toggle-tax"}
                                        isvisible={
                                          !!selectedStore.taxPercentage
                                        }
                                        link={
                                          <Add
                                            title={
                                              <Text
                                                value={translations.ADD_TAX}
                                              />
                                            }
                                          />
                                        }
                                        content={
                                          <>
                                          <Row>
                                            <Label
                                              title={
                                                <Text
                                                  value={translations.TAX}
                                                />
                                              }
                                              subtitle={
                                                <Text
                                                  value={
                                                    translations.TAX_DESCRIPTION
                                                  }
                                                />
                                              }
                                            >
                                              <Field
                                                name="taxPercentage"
                                                min={0}
                                                step={0.1}
                                                left="%"
                                                placeholder={translate(
                                                  translations.ENTER_TAX
                                                )}
                                                component={Stepper}
                                                validation={n => n >= 0}
                                                data-testid="store-tax"
                                              />
                                            </Label>
                                          </Row>
                                          <Row className="mt-2 -mx-3">
                                            <Field
                                              name="taxInclusive"
                                              component={RadioList}
                                              items={[
                                                {
                                                  title: <Text key="tax_exclusive" value={translations.TAX_EXCLUSIVE} />,
                                                  value: false,
                                                },
                                                {
                                                  title: <Text key="tax_inclusive" value={translations.TAX_INCLUSIVE} />,
                                                  value: true,
                                                },
                                              ]}
                                            />
                                          </Row>
                                          </>
                                        }
                                      />
                                    </Row>
                                  </Container>
                                </div>
                              )
                            }
                          />
                          <Footer>
                            <Button
                              kind="primary"
                              isSpinning={
                                loading ||
                                isLegalUpdating ||
                                isLegalAdding ||
                                isSettingsUpdating ||
                                loadingImages
                              }
                              data-testid="btn-save-business-information"
                            >
                              <Text value={translations.SAVE_CHANGE} />
                            </Button>
                          </Footer>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </Layout>
          )}
        </Mutation>
      )}
    </Modal>
  )
}
