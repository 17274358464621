/* global google */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useLoadScript, Marker, Circle } from '@react-google-maps/api';

import { MapIcon } from '@zydadesign/zac-icons-react';
import * as paths from 'paths.js';
import { withBackParam } from 'utils/history';
import * as translations from 'constants/translations';
import { Spinner } from 'components/kit';
import { Link, Text } from 'components/service';
import { dataLayerRemoval, primaryColorUtils, selectedZoneIdUtils, zoneTypeUtils } from '../../Zones/utils';

const { REACT_APP_GOOGLE_MAPS_KEY } = process.env;
const MapView = ({ zones, branches, tableViewController }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY,
  });
  const [map, setMap] = useState(null);
  const [showMapView, setShowMapView] = useState(false);

  // - handle map viewport (not Utils because of map usage)
  const handleViewPort = lnglat => {
    const bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < lnglat.length; i++) {
      bounds.extend(lnglat[i]);
    }
    map.fitBounds(bounds);
    map.setCenter(bounds.getCenter());
  };

  useEffect(() => {
    if (map) {
      const { data: dataLayer } = map;
      dataLayerRemoval(dataLayer, map);
      dataLayer.addGeoJson({ type: 'FeatureCollection', features: zones });
      dataLayer.setStyle(() => ({
        strokeColor: primaryColorUtils,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: primaryColorUtils,
        fillOpacity: 0.4,
      }));
    }
  }, [map, zones]);

  useEffect(() => {
    if (map && tableViewController.branchViewPort) {
      handleViewPort(tableViewController.branchViewPort);
    }
  }, [tableViewController.branchViewPort, map]);

  if (loadError) {
    return <Text value={translations.MAP_FAILED_TO_LOAD} />;
  }

  return isLoaded ? (
    <div
      className="w-full h-40 mb-6 relative"
      aria-hidden="true"
      onMouseEnter={() => setShowMapView(true)}
      onMouseLeave={() => setShowMapView(false)}
    >
      {showMapView && (
        <Link to={`${paths.zonesCreation}?${withBackParam()}`} omitQuery>
          <div
            aria-hidden="true"
            onClick={() => localStorage.removeItem(selectedZoneIdUtils.SELECTEDZONEID)}
            className="absolute top-0 left-0 right-0 z-10 h-full w-full bg-gray-600 bg-opacity-70 flex items-center justify-center cursor-pointer"
          >
            <div className="bg-white py-2 px-3 shadow flex items-center justify-center">
              <MapIcon width="32" color="#1354C2" className="px-1" />
              <Text className="px-1 text-primary-base font-medium" value={translations.VIEW_MAP} />
            </div>
          </div>
        </Link>
      )}

      <GoogleMap
        onLoad={ref => {
          setMap(ref);
        }}
        mapContainerClassName="h-full w-full"
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          draggable: false,
          scrollwheel: false,
          disableDoubleClickZoom: false,
          zoomControl: false,
        }}
      >
        {branches.map(branchItem => (
          <Marker key={branchItem.id} position={{ lat: branchItem.lat, lng: branchItem.lng }} />
        ))}
        {!!zones.length &&
          map &&
          zones.map(
            zoneItem =>
              zoneItem.geometry.type === zoneTypeUtils.POINT && (
                <Circle
                  center={{ lat: zoneItem.geometry.coordinates[1], lng: zoneItem.geometry.coordinates[0] }}
                  radius={zoneItem.properties.radius}
                  options={{
                    strokeColor: primaryColorUtils,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: primaryColorUtils,
                    fillOpacity: 0.4,
                  }}
                />
              ),
          )}
      </GoogleMap>
    </div>
  ) : (
    <div className="h-full w-full d-flex align-center justify-center">
      <Spinner />
    </div>
  );
};

MapView.propTypes = {
  zones: PropTypes.arrayOf(
    PropTypes.shape({
      properties: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
  ),
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
  tableViewController: PropTypes.shape({
    branchViewPort: PropTypes.arrayOf(
      PropTypes.shape({
        lng: PropTypes.number,
        lat: PropTypes.number,
      }),
    ),
  }),
};

export default MapView;
