import React, { useContext } from 'react';
import cx from 'classnames';
import { Tag } from '@zydadesign/zac-react';

import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { formatDate } from 'utils/date';
import { CREDIT, SUBSCRIPTION } from 'constants/translations';
import { INVOICE_STATUS, INVOICE_TYPE_HUMANIZE } from 'constants/billing';
import { getInvoiceStatusActionText } from './utils';

const InvoicesRowTable = ({ item, count, index, currency }) => {
  const { direction, translate } = useContext(localeContext);
  const cellClassName = cx(
    'px-4 border-b border-gray-300 whitespace-nowrap last:w-full',
    count - 1 === index ? ' pt-3 pb-8' : ' py-3',
  );

  return (
    <tr key={item.number}>
      <td className={`${cx(cellClassName)} w-30p`}>
        {item.billingCycleStartDate ? formatDate(item.billingCycleStartDate) : '-'}
      </td>
      <td className={`${cx(cellClassName)} w-2/5`}>
        <div className="w-52 px-2 -mx-2 h-10 flex items-center gap-2">
          <Tag
            size="medium"
            color={item.typeHumanize === INVOICE_TYPE_HUMANIZE.SMS_PACKAGE ? 'teal' : 'orange'}
            inner={{
              text: translate(item.typeHumanize === INVOICE_TYPE_HUMANIZE.SMS_PACKAGE ? CREDIT : SUBSCRIPTION),
            }}
          />
          {direction === 'ltr'
            ? item.serviceData.titleEn && item.serviceData.titleEn.split(' ')[0] !== 'Legacy'
              ? item.serviceData.titleEn && item.serviceData.titleEn.split(' ')[0]
              : item.serviceData.titleEn && item.serviceData.titleEn.split(' ')[1]
            : item.serviceData.titleAr && item.serviceData.titleAr.split(' ')[1]}
        </div>
      </td>
      <td className={`${cx(cellClassName)} w-30p`}>
        <div className="flex items-center gap-1">
          <Text value={currency} postfix={item.total} />
        </div>
      </td>
      <td className={`${cx(cellClassName)} w-30p`}>
        <div className="flex items-center h-10">
          <a className="inline-block text-center" href={item.paymentUrl} target="blank">
            <Text
              className={cx(
                item?.status === INVOICE_STATUS.CANCELLED ? 'text-zyda-grey-45 opacity-60' : 'text-primary-base',
              )}
              value={getInvoiceStatusActionText(item?.status)}
            />
          </a>
        </div>
      </td>
    </tr>
  );
};

export default InvoicesRowTable;
