import React from 'react';
import moment from 'moment';

declare global {
  interface Window {
    fcWidgetMessengerConfig: any;
  }
}

const Freshchat = ({ user, store, subscription, isSubscriptionLoading }) => {
  if (!isSubscriptionLoading && user && store) {
    window.fcWidgetMessengerConfig = {
      email: user.email,
      externalId: user.id,
      phone: user.phoneNumber,
      firstName: user.name,
      created_at: user.createdAt ? moment(user.createdAt).unix() : null,
      meta: {
        cf_user_id: user.id,
        cf_user_name: user.name,
        cf_user_role: !!user.roles?.length ? user.roles[0].role : 'NA',
        cf_store_name: store.titleEn,
        cf_store_id: store.id,
        cf_store_front_url: store.fullUrl,
        cf_subscription_plan: subscription?.countryPlan?.plan?.titleEn || 'NA',
        cf_country_code: store.countryCode,
        cf_currency: store.currency?.titleEn,
        cf_plan_duration: subscription?.serviceData?.interval || 'NA',
        cf_active_subscription_value: subscription?.total || 'NA',
        cf_activation_time: subscription?.goActiveAt || 'NA',
        cf_subscription_expiry: subscription?.expiresAt || 'NA',
        cf_store_subscription_status: subscription?.status || 'NA',
        cf_subscription_close_date: !!subscription?.invoices?.length ? subscription.invoices[0].createdAt : 'NA',
        cf_monthly_spend: store.monthlyTotalSales,
        cf_number_of_branches: store.numberOfBranches || 0,
      },
    };
  }
  return '';
};

export default Freshchat;
