import React, { useContext } from 'react';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';

import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import * as translations from 'constants/translations';
import Beamer from '../Beamer';
import '../../../messaging_init_in_sw';
import Freshchat from '../Freshchat';

export default ({ user, name, store, children }) => {
  const { translate, lang } = useContext(localeContext);
  const { showSlideOver } = useContext(userContext);
  const isSlideOverOpen = showSlideOver;
  const title = [translations.ZYDA, store, name]
    .filter(R.identity)
    .map(item => translate(item))
    .join(' - ');
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {user && store && !isSlideOverOpen && <Freshchat user={user} store={store} />}
      {user && store && !isSlideOverOpen && <Beamer id={process.env.REACT_APP_BEAMER_ID} lang={lang} />}
      {children}
    </>
  );
};
