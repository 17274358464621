import React, { useState, useContext } from 'react';
import cx from 'classnames';
import { useMutation } from '@apollo/client';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { useTablet } from 'hooks/index';
import { Button } from 'components/kit';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { ReactComponent as UsersIcon } from 'assets/users.svg';
import { ReactComponent as BullhornIcon } from 'assets/bullhorn.svg';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import FacebookBusinessManagerIcon from 'assets/facebook-business-manager.svg';
import { context as notificationsContext } from 'context/notifications';
import { fbRedirectUrl } from 'constants/fb';
import UNINSTALL_FB from './schema';
import FacebookActionCard from './FacebookActionCard';
import IntegrationsConnectionTemplate from '../IntegrationsConnectionTemplate';

const FacebookPartnerConnect = () => {
  const { selectedStore } = useContext(userContext);
  const { id: restaurantId, titleEn, titleAr, fbeBusinessManagerId } = selectedStore;
  const { lang, direction } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const [installed, setInstalled] = useState(selectedStore.isFbeConnected);
  const isTablet = useTablet();
  const [uninstallFB, { loading: isStillUpdating }] = useMutation(UNINSTALL_FB, {
    onCompleted: () => {
      notifications.show(<Text value={translations.FACEBOOK_UNINSTALLED} />);
      setInstalled(false);
    },
    onError: () => notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error'),
  });

  return (
    <div className="border rounded-lg w-full">
      <IntegrationsConnectionTemplate
        imageSrc={FacebookBusinessManagerIcon}
        imageAlt={lang === 'en' ? translations.FACEBOOK_PARTNER_TITLE[0] : translations.FACEBOOK_PARTNER_TITLE[1]}
        title={translations.FACEBOOK_PARTNER_TITLE}
        subtitle={translations.FACEBOOK_PARTNER_DESCRIPTION}
        direction={direction}
        isConnected={installed}
        badge={{
          hasBadge: true,
          title: installed ? translations.CONNECTED : translations.NEW,
          color: installed ? 'bg-green-600' : 'bg-primary-base',
        }}
        actionButtonSection={{
          hasButton: true,
          onClick: !installed
            ? () => {
                sessionStorage.setItem('facebook_business_id', restaurantId);
                window.location = `https://www.facebook.com/dialog/oauth?client_id=846583795969804&display=page&redirect_uri=https://dash.zyda.com/&state=${restaurantId}&response_type=token&scope=manage_business_extension${fbRedirectUrl(
                  selectedStore,
                )}`;
              }
            : () =>
                uninstallFB({
                  variables: {
                    restaurantId,
                  },
                }),
          buttonDisabled: isStillUpdating,
          isCopyable: false,
        }}
        isTablet={isTablet}
      />
      {installed && (
        <>
          <hr className="self-center mx-4 bg-gray-400" />
          <div className={cx('flex justify-between p-6', isTablet && 'flex-col')}>
            <div className="flex justify-between">
              <div>
                <Text className="text-gray-600 font-semibold text-xs" value={translations.BUSINESS_NAME} />
                <p className="leading-compact">{direction === 'ltr' ? titleEn : titleAr}</p>
              </div>
              <div className={cx(direction === 'ltr' ? 'ml-8' : 'mr-8')}>
                <Text className="text-gray-600 font-semibold text-xs" value={translations.BUSINESS_ID} />
                <p className="leading-compact">zyda-{restaurantId}</p>
              </div>
            </div>
            <div className={cx('flex flex-row', isTablet && 'self-center mt-4')}>
              <a
                className="text-primary-base"
                href={`https://www.facebook.com/facebook_business_extension?app_id=846583795969804&external_business_id=${restaurantId}`}
              >
                <Button>
                  <Text className="text-sm" value={translations.MANAGE_FEATURES} />
                </Button>
              </a>
            </div>
          </div>
        </>
      )}
      <hr className="self-center mx-4 bg-gray-400" />
      <div className="mt-4 px-6">
        <div className={cx('flex', isTablet ? 'flex-col' : 'justify-between')}>
          <FacebookActionCard
            fbLink={`https://business.facebook.com/latest/posts/?business_id=${fbeBusinessManagerId}`}
            description={translations.PROMOTE_FB}
            buttonText={translations.PROMOTE_SERVICES}
            installed={installed}
            icon={<BullhornIcon direction={direction} className="self-center" />}
            direction={direction}
          />
          <FacebookActionCard
            fbLink={`https://business.facebook.com/latest/inbox/all?business_id=${fbeBusinessManagerId}`}
            description={translations.REACH_FB}
            buttonText={translations.MANAGE_CUSTOMERS}
            installed={installed}
            icon={<UsersIcon className="self-center" />}
            direction={direction}
          />
          <FacebookActionCard
            fbLink={`https://business.facebook.com/latest/ad_center/?business_id=${fbeBusinessManagerId}`}
            description={translations.CAMPAIGNS_MANAGE}
            buttonText={translations.RUN_ADS}
            installed={installed}
            icon={<CalendarIcon className="self-center" />}
            direction={direction}
          />
        </div>
      </div>
    </div>
  );
};

export default FacebookPartnerConnect;
