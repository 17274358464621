import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Tooltip } from '@zydadesign/zac-react';

import { useSelectedStore } from 'hooks';
import * as translations from 'constants/translations';
import { SENDER_ID_TYPES } from 'pages/marketing/constants';
import { Text } from 'components/service';
import { SENDER_ID } from './schema';

const SenderId = ({ title }) => {
  const storeId = useSelectedStore();
  const [senderId, setSenderId] = useState(null);

  // get sender id
  useQuery(SENDER_ID, {
    variables: {
      storeId,
    },
    onCompleted: data => setSenderId(data.senderId),
    onError: () => setSenderId(null),
  });

  return senderId ? (
    <>
      {title && title}
      <div className="flex items-center flex-shrink-0">
        <Text value={[senderId, senderId]} className="text-zyda-grey-60 mr-1" />
        <div className="flex-shrink-0">
          <Tooltip
            info={
              <Text
                className="w-343 p-2"
                value={
                  senderId === SENDER_ID_TYPES.ORDER_DIRECT
                    ? translations.SENDER_ID_DEFAULT_NOTE
                    : translations.SENDER_ID_CUSTOM_NOTE
                }
                payload={senderId}
              />
            }
            size="small"
            placement="bottom"
          />
        </div>
      </div>
    </>
  ) : null;
};

SenderId.propTypes = {
  title: PropTypes.node,
};

export default SenderId;
