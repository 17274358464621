import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { TrashIcon } from '@zydadesign/zac-icons-react';

import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import * as hoursUtils from 'components/form/elements/Hours/utils';
import { useCreateDeliveryZone, useUpdateDeliveryZone, useDeleteDeliveryZone } from 'service/hooks/deliveryZones';
import { useSelectedStore } from 'hooks';
import { DELIVERY_ZONE_GEO_JSON_FEATURE_COLLECTION_TEMPLATE } from 'constants/index';
import { convertTime } from 'utils';
import { Button, Modal } from 'components/kit';
import ZoneForm from '../ZoneForm';
import {
  convertedDeliveryTimeUtils,
  dzCrudErrorHandler,
  selectedZoneIdUtils,
  zonesPageSize,
  zoneTypeUtils,
} from '../utils';
import DeleteZone from './DeleteZoneModal';
import { trackDeliveryZoneEvents } from '../../ZonesList/utils';

const Edition = ({
  zoneController,
  setZoneController,
  setZones,
  branches,
  setDzTemplatesParams,
  setDzSuggestedTemplatesParams,
}) => {
  const { translate, direction } = useContext(localeContext);
  const { user } = useContext(userContext);

  const notifications = useContext(notificationsContext);

  const [pending, setPending] = useState(false);
  const { selectedZone, shapeRef, prevZone, selectedTemplateData } = zoneController;
  const { properties, geometry, geoShape } = selectedZone;
  const {
    id: selectedZoneId,
    zone_name: zoneName,
    pos_external_id: posExternalId,
    delivery_fee: deliveryFee,
    minimum_order: minValue,
    published: enableZone,
    delivery_time: deliveryTime,
    opening_hours: openingHours,
    branch_reference_id: branchId,
  } = properties;
  const { type: geometryType } = geometry;
  const { paths, center, radius } = prevZone;
  const createDeliveryZone = useCreateDeliveryZone();
  const selectedStoreId = useSelectedStore();
  const updateDeliveryZone = useUpdateDeliveryZone();
  const deleteDeliveryZone = useDeleteDeliveryZone();
  const { pos: POSIntegration } = useContext(userContext);
  const needsPOSExternalId = POSIntegration?.supportZoneMapping;
  const initialValues = {
    zoneName,
    posExternalId: posExternalId || '',
    deliveryFee,
    minValue,
    enableZone,
    deliveryTime: [deliveryTime, 'mins'],
    openingHours: zoneController.selectedSuggestedZone
      ? zoneController.selectedBranch.openingHours
      : openingHours || hoursUtils.generateInitialValue('11:00', '21:30'),
    branchId,
    needsPOSExternalId,
  };

  const resetBulk = () => {
    setDzTemplatesParams({ pageSize: zonesPageSize, currentPage: 1, totalPages: null, searchValue: '' });
    setDzSuggestedTemplatesParams({ pageSize: zonesPageSize, currentPage: 1, totalPages: null });
  };

  const handleFormSubmit = async (values, setErrors) => {
    const convertedDeliveryTime = convertedDeliveryTimeUtils(
      values.deliveryTime[1],
      values.deliveryTime[0],
      convertTime,
    );

    if (geometryType === zoneTypeUtils.POLYGON) {
      const coordinates = [[]];
      const nextPath = shapeRef
        .getPath()
        .getArray()
        .map(latLng => {
          coordinates[0].push([latLng.lng(), latLng.lat()]);
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      const newGeometry = { ...geometry, coordinates };
      const cleanZone = { ...selectedZone, geometry: newGeometry, properties: undefined, paths: undefined };
      const geoJsonFeatureCollection = DELIVERY_ZONE_GEO_JSON_FEATURE_COLLECTION_TEMPLATE;
      geoJsonFeatureCollection.features = [cleanZone];
      setPending(true);

      const response = await updateDeliveryZone({
        params: {
          id: selectedZoneId,
          zoneName: values.zoneName,
          posExternalId: values.posExternalId,
          deliveryFee: parseFloat(values.deliveryFee),
          minimumOrder: values.minValue ? parseFloat(values.minValue) : 0,
          deliveryTime: parseFloat(convertedDeliveryTime),
          geoShape: geoJsonFeatureCollection,
          published: values.enableZone,
          branchReferenceId: parseFloat(values.branchId),
          openingHours: values.openingHours,
        },
      });

      if (response.hasError) {
        setPending(false);
        dzCrudErrorHandler(response, setErrors, translate, translations);
      } else {
        setPending(false);
        trackDeliveryZoneEvents(selectedStoreId, user.id, 'Update Delivery Zone Map', {
          ...values,
          openingHours: JSON.stringify(values.openingHours),
          zoneType: geometryType,
        });
        const switchColors = () => {
          if (parseFloat(zoneController.selectedBranch.id) !== parseFloat(values.branchId)) {
            return {
              fillColor: branches.find(branchItem => branchItem.id === parseFloat(values.branchId)).color,
              fillOpacity: 0.2,
              strokeOpacity: 0.2,
            };
          }
          return {};
        };
        setZones(prevState =>
          prevState.map(zoneItem =>
            zoneItem.properties.id === selectedZoneId
              ? {
                  ...zoneItem,
                  paths: nextPath,
                  properties: {
                    ...zoneItem.properties,
                    zone_name: values.zoneName,
                    pos_external_id: values.posExternalId,
                    delivery_fee: values.deliveryFee,
                    minimum_order: values.minValue,
                    published: values.enableZone,
                    delivery_time: convertedDeliveryTime,
                    opening_hours: values.openingHours,
                    branch_reference_id: parseFloat(values.branchId),
                    ...switchColors(),
                  },
                }
              : zoneItem,
          ),
        );
        setZoneController({
          ...zoneController,
          isAdd: false,
          isEdit: false,
          drawerMode: null,
          showDrawerMode: false,
          enableDrawerMode: true,
          shapeRef: null,
          prevZone: {
            paths: null,
            center: null,
            radius: null,
          },
          updateZoneNotify: true,
          selectedSuggestedZone: null,
          selectedZone: null,
          isCricle: false,
        });
        resetBulk();
      }
    }

    if (geometryType === zoneTypeUtils.POINT) {
      setPending(true);

      const response = await updateDeliveryZone({
        params: {
          id: selectedZoneId,
          zoneName: values.zoneName,
          posExternalId: values.posExternalId,
          deliveryFee: parseFloat(values.deliveryFee),
          minimumOrder: values.minValue ? parseFloat(values.minValue) : 0,
          deliveryTime: parseFloat(convertedDeliveryTime),
          geoShape,
          published: values.enableZone,
          branchReferenceId: parseFloat(values.branchId),
          radius: shapeRef.getRadius(),
          openingHours: values.openingHours,
        },
      });

      if (response.hasError) {
        setPending(false);
        dzCrudErrorHandler(response, setErrors, translate, translations);
      } else {
        setPending(false);
        trackDeliveryZoneEvents(selectedStoreId, user.id, 'Update Delivery Zone Map', {
          ...values,
          openingHours: JSON.stringify(values.openingHours),
          zoneType: geometryType,
        });
        const switchColors = () => {
          if (parseFloat(zoneController.selectedBranch.id) !== parseFloat(values.branchId)) {
            return {
              fillColor: branches.find(branchItem => branchItem.id === parseFloat(values.branchId)).color,
              fillOpacity: 0.2,
              strokeOpacity: 0.2,
            };
          }
          return {};
        };
        setZones(prevState =>
          prevState.map(zoneItem =>
            zoneItem.properties.id === selectedZoneId
              ? {
                  ...zoneItem,
                  properties: {
                    ...zoneItem.properties,
                    zone_name: values.zoneName,
                    pos_external_id: values.posExternalId,
                    delivery_fee: values.deliveryFee,
                    minimum_order: values.minValue,
                    published: values.enableZone,
                    delivery_time: convertedDeliveryTime,
                    opening_hours: values.openingHours,
                    branch_reference_id: parseFloat(values.branchId),
                    radius: shapeRef.getRadius(),
                    ...switchColors(),
                  },
                }
              : zoneItem,
          ),
        );
        setZoneController({
          ...zoneController,
          isAdd: false,
          isEdit: false,
          drawerMode: null,
          showDrawerMode: false,
          enableDrawerMode: true,
          shapeRef: null,
          prevZone: {
            paths: null,
            center: null,
            radius: null,
          },
          updateZoneNotify: true,
          selectedSuggestedZone: null,
          selectedZone: null,
          isCricle: false,
        });
        resetBulk();
      }
    }

    localStorage.removeItem(selectedZoneIdUtils.SELECTEDZONEID);
  };

  const handleFormSubmitSuggested = async (values, setErrors) => {
    const convertedDeliveryTime = convertedDeliveryTimeUtils(
      values.deliveryTime[1],
      values.deliveryTime[0],
      convertTime,
    );

    setPending(true);
    const addNewZone = {
      ...zoneController.selectedZone,
      paths: selectedTemplateData.paths,
      properties: {
        ...zoneController.selectedZone.properties,
        id: Date.now(),
        zone_name: values.zoneName,
        pos_external_id: values.posExternalId,
        delivery_fee: parseFloat(values.deliveryFee),
        minimum_order: parseFloat(values.minValue),
        published: values.enableZone,
        opening_hours: values.openingHours,
        delivery_time: convertedDeliveryTime,
        fillOpacity: 0.5,
      },
    };
    const updatedGeoShape = {
      ...addNewZone.geoShape,
      features: [
        {
          ...addNewZone.geoShape.features[0],
          geometry: { ...addNewZone.geoShape.features[0].geometry, coordinates: selectedTemplateData.coordinates },
        },
      ],
    };

    const response = await createDeliveryZone({
      params: {
        restaurantReferenceId: parseInt(selectedStoreId),
        branchReferenceId: addNewZone.properties.branch_reference_id,
        zoneName: addNewZone.properties.zone_name,
        posExternalId: addNewZone.properties.pos_external_id,
        minimumOrder: addNewZone.properties.minimum_order ? addNewZone.properties.minimum_order : 0,
        deliveryFee: addNewZone.properties.delivery_fee,
        deliveryTime: addNewZone.properties.delivery_time,
        geoShape: updatedGeoShape,
        openingHours: addNewZone.properties.opening_hours,
        published: addNewZone.properties.published,
      },
    });

    if (response.hasError) {
      setPending(false);
      dzCrudErrorHandler(response, setErrors, translate, translations);
    } else {
      trackDeliveryZoneEvents(selectedStoreId, user.id, 'Create Delivery Zone Map Predefined', {
        ...values,
        openingHours: JSON.stringify(values.openingHours),
        zoneType: geometryType,
      });
      setZones(prevState =>
        prevState.concat({
          type: addNewZone.type,
          geometry: { ...updatedGeoShape.features[0].geometry, coordinates: selectedTemplateData.coordinates },
          properties: { ...addNewZone.properties, id: parseInt(response.createDeliveryZone.deliveryZone.id) },
          paths: selectedTemplateData.paths,
        }),
      );
      setZoneController({
        ...zoneController,
        isAdd: false,
        isEdit: false,
        drawerMode: null,
        showDrawerMode: false,
        enableDrawerMode: true,
        shapeRef: null,
        prevZone: {
          paths: null,
          center: null,
          radius: null,
        },
        updateZoneNotify: true,
        selectedSuggestedZone: null,
        selectedZone: null,
        selectedTemplateData: {
          coordinates: null,
          paths: null,
        },
      });
      resetBulk();
    }
    localStorage.removeItem(selectedZoneIdUtils.SELECTEDZONEID);
  };

  const handleCancelMode = () => {
    if (!zoneController.selectedSuggestedZone) {
      if (geometryType === zoneTypeUtils.POLYGON) {
        shapeRef.setPaths(paths);
      }
      if (geometryType === zoneTypeUtils.POINT) {
        shapeRef.setCenter(center);
        shapeRef.setRadius(radius);
      }
    }

    setZoneController({
      ...zoneController,
      isEdit: false,
      drawerMode: null,
      showDrawerMode: false,
      enableDrawerMode: true,
      shapeRef: null,
      selectedZone: null,
      prevZone: {
        paths: null,
        center: null,
        radius: null,
      },
      selectedSuggestedZone: null,
      isCricle: false,
      selectedTemplateData: {
        coordinates: null,
        paths: null,
      },
    });
    resetBulk();

    localStorage.removeItem(selectedZoneIdUtils.SELECTEDZONEID);
  };

  const handleRemoveZone = async () => {
    setPending(true);
    const response = await deleteDeliveryZone({ deliveryZoneId: selectedZoneId });
    if (response.hasError) {
      setPending(false);
      notifications.show(<Text value={translations.DELIVERY_ZONE_NOT_FOUND} />, 'error');
    } else {
      setPending(false);
      setZones(prevState => prevState.filter(item => item.properties.id !== selectedZoneId));
      trackDeliveryZoneEvents(selectedStoreId, user.id, 'Delete Delivery Zone Map', {
        id: selectedZoneId,
      });
      setZoneController({
        ...zoneController,
        showDrawerMode: false,
        isAdd: false,
        isEdit: false,
        drawerMode: null,
        enableDrawerMode: true,
        shapeRef: null,
        prevZone: {
          paths: null,
          center: null,
          radius: null,
        },
        selectedZone: null,
        deleteZoneNotify: true,
        showZoneTemplates: false,
        selectedSuggestedZone: null,
        isCricle: false,
      });
      resetBulk();

      localStorage.removeItem(selectedZoneIdUtils.SELECTEDZONEID);
    }
  };

  return (
    <div className="bg-white p-4 mb-4 shadow" style={{ direction }}>
      <div className="flex justify-between align-center mb-4">
        <Text className="text-base" value={translations.ZONE_PROPERTIES} />
        {!zoneController.selectedSuggestedZone && (
          <Modal>
            {({ open, close }) => (
              <Button
                kind="secondaryError"
                type="button"
                isMenu
                onClick={e => {
                  e.preventDefault();
                  open({
                    size: 'max-w-md',
                    title: <Text value={translations.ZONE} />,
                    body: <DeleteZone close={close} handleRemoveZone={handleRemoveZone} />,
                  });
                }}
              >
                <TrashIcon width="18px" color="#E04848" />
              </Button>
            )}
          </Modal>
        )}
      </div>
      <ZoneForm
        isEdit
        handleCancelMode={handleCancelMode}
        handleFormSubmit={zoneController.selectedSuggestedZone ? handleFormSubmitSuggested : handleFormSubmit}
        initialValues={initialValues}
        branches={branches}
        pending={pending}
        isDisabled={zoneController.isCricle || zoneController.selectedSuggestedZone}
        needsPOSExternalId={needsPOSExternalId}
      />
    </div>
  );
};

Edition.propTypes = {
  zoneController: PropTypes.shape({
    selectedZone: PropTypes.shape({
      properties: PropTypes.shape({
        id: PropTypes.number,
        zone_name: PropTypes.string,
        pos_external_id: PropTypes.string,
        delivery_fee: PropTypes.number,
        minimum_order: PropTypes.number,
        published: PropTypes.bool,
        opening_hours: PropTypes.arrayOf(
          PropTypes.shape({
            day: PropTypes.string,
          }),
        ),
        delivery_time: PropTypes.number,
        branch_reference_id: PropTypes.number,
      }),
      geometry: PropTypes.shape({
        type: PropTypes.string,
      }),
      deliveryTime: PropTypes.arrayOf([PropTypes.number, PropTypes.string]),
    }),
    selectedSuggestedZone: PropTypes.shape({
      id: PropTypes.number,
    }),
    prevZone: PropTypes.shape({
      radius: PropTypes.number,
      paths: PropTypes.arrayOf(PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number })),
      center: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
    }),
    shapeRef: PropTypes.shape({
      id: PropTypes.number,
      setPaths: PropTypes.func,
      setCenter: PropTypes.func,
      setRadius: PropTypes.func,
      getCenter: PropTypes.func,
      getRadius: PropTypes.func,
      getPath: PropTypes.func,
    }),
    selectedBranch: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  setZoneController: PropTypes.func.isRequired,
  setZones: PropTypes.func.isRequired,
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
  setDzTemplatesParams: PropTypes.func.isRequired,
  setDzSuggestedTemplatesParams: PropTypes.func.isRequired,
};

export default Edition;
