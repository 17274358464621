import React, { useContext } from 'react';
import cx from 'classnames';
import { Tag, Button } from '@zydadesign/zac-react';
import { CheckIcon } from '@zydadesign/zac-icons-react';

import { formatDate } from 'utils/date';
import * as paths from 'paths.js';
import { Text, Link } from 'components/service';
import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';

const CurrentSubscription = ({
  titleEn,
  titleAr,
  expiresAt,
  interval,
  isCurrentInvoiceUnpaid,
  subscriptionStatus,
  cancelationRequest,
  features,
}) => {
  const { direction, translate } = useContext(localeContext);
  const displayInterval = subscriptionInterval => {
    const intervalsArray = [
      { id: 'month', en: ' Monthly', ar: ' شهرياً' },
      { id: 'quarter', en: ' Quarterly', ar: ' ربع سنوياً' },
      {
        id: 'semi_annual',
        en: ' Semi-annually',
        ar: ' نصف سنوياً',
      },
      { id: 'annual', en: ' Yearly', ar: ' سنوياً' },
    ];

    const intervalTemp = intervalsArray.find(intervalObj => intervalObj.id === subscriptionInterval);
    if (direction === 'ltr') return intervalTemp ? intervalTemp.en : '';
    return intervalTemp ? intervalTemp.ar : '';
  };

  const planTitle =
    direction === 'ltr'
      ? titleEn && titleEn.split(' ')[0] !== 'Legacy'
        ? titleEn && titleEn.split(' ')[0]
        : titleEn && titleEn.split(' ')[1]
      : titleAr && titleAr.split(' ')[1];

  return (
    <div
      className={cx(
        'border rounded-md border-zyda-grey-45 border-opacity-10 grid grid-cols-2 p-6 w-full lg:w-2/3',
        direction === 'ltr' && 'divide-x',
      )}
    >
      <div>
        <Tag size="medium" color="teal" inner={{ text: translate(translations.NEW_PLAN) }} />
        <div className="mt-2">
          <p className={cx('font-semibold text-2xl mb-2', direction === 'ltr' ? 'mr-2' : 'ml-2')}>
            {planTitle} <span>{displayInterval(interval)}</span>
          </p>
          {subscriptionStatus !== 'setup' && (
            <div>
              <div className="flex">
                <Text
                  value={cancelationRequest ? translations.EXPIRES_ON : translations.NEXT_BILLING_DATE}
                  postfixElement={
                    <span className={cx('font-medium', direction === 'ltr' ? 'ml-1' : 'mr-1')}>
                      {expiresAt && formatDate(expiresAt)}
                    </span>
                  }
                />
              </div>
              {isCurrentInvoiceUnpaid && (
                <div className="grid grid-cols-1 md:grid-cols-3 mt-5 mb-14">
                  <Link to={paths.renew}>
                    <Button type="button" size="small" rounded text={translate(translations.BLOCK_TABS_PAY_NOW)} />
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="pl-9">
        <Text className="text-base font-medium mb-4" value={[titleEn, titleAr]} />
        <ul className="list-none">
          {features?.featuresEn.map((feature, index) => (
            <li key={feature} className="flex gap-2 mb-2">
              <CheckIcon width="24px" color="#37995D" />
              <Text value={[feature, features?.featuresAr[index]]} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CurrentSubscription;
