import React from 'react';
import { Button } from '@zydadesign/zac-react';

import * as paths from 'paths.js';
import { Text, Link } from 'components/service';
import { Table } from 'components/form/elements';
import * as translations from 'constants/translations';
import InvoicesRowTable from '../Invoices/InvoicesRowTable';
import InvoicesFirstColumn from '../Invoices/InvoicesFirstColumn';
import { Spinner } from 'components/kit';

const BillingActionCard = ({ invoices = [], currency, translate, isLoadingInvoices }) => (
  <div>
    <Text className="text-xl font-semibold mb-4" value={translations.INVOICES} />
    {isLoadingInvoices ? (
      <div className="w-full justify-center">
        <Spinner />
      </div>
    ) : (
      <div>
        <Table
          dataHeader={[
            {
              key: 'invoiceId',
              name: <Text value={translations.INVOICE_NO} className="inline" />,
              sort: false,
            },
            {
              key: 'invoiceStartDate',
              name: <Text value={translations.INVOICE_START_DATE} className="inline" />,
              sort: false,
            },
            {
              key: 'invoicePlan',
              name: <Text value={translations.TYPE_URL} className="inline" />,
              sort: false,
            },

            {
              key: 'invoiceAmount',
              name: <Text value={translations.INOICE_AMOUNT} className="inline" />,
              sort: false,
            },
            {
              key: 'action',
              name: <Text value="" className="inline" />,
              sort: false,
            },
          ]}
          hasSideBorders={false}
          customCol={(invoice, i) => (
            <InvoicesFirstColumn index={i} key={invoice?.number} count={invoices.length} item={invoice} />
          )}
          dataBody={invoices}
          customRow={(item, i) => (
            <InvoicesRowTable
              count={invoices.length}
              key={item.number}
              index={i}
              item={item}
              lowStock={invoices}
              currency={currency}
            />
          )}
        />
        <div className="pt-5 w-36">
          <Link to={paths.invoices}>
            <Button variant="tertiary" size="small" rounded text={translate(translations.VIEW_INVOICES)} />
          </Link>
        </div>
      </div>
    )}
  </div>
);

export default BillingActionCard;
