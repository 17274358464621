import React from 'react';
import * as paths from 'paths.js';
import PropTypes from 'prop-types';

import { Link, Text } from 'components/service';
import { More, Modal } from 'components/kit';
import { useReplaceParams } from 'hooks/index';
import * as translations from 'constants/translations';
import { DeleteRider } from 'components/common/verd';

const RidersMobileList = ({ riders, handleRiderActivation }) => {
  const replace = useReplaceParams();

  return (
    <div>
      {riders.map(rider => (
        <div className="flex justify-between mb-3 pb-3 border-b border-gray-300">
          <Link
            className="flex"
            to={replace(paths.editRider, {
              params: { riderId: rider.id },
            })}
            key={rider.id}
          >
            <div>
              <div className="font-semibold">{rider.name}</div>
              <div className="text-gray-500 mb-1">{rider.jobType}</div>
              <div className="text-xs">
                {rider.email} • {rider.phone}
              </div>
            </div>
          </Link>
          <Modal>
            {({ open, close }) => (
              <More
                kind="action"
                size="smIcon"
                iconSize="md"
                items={[
                  {
                    title: <Text value={rider.isActive ? translations.DEACTIVATE : translations.ACTIVATE} />,
                    onClick: () => {
                      rider.isActive
                        ? open({
                            title: rider.name,
                            body: (
                              <DeleteRider
                                rider={rider}
                                onClose={close}
                                handleRiderActivation={handleRiderActivation}
                              />
                            ),
                            size: 'max-w-md',
                          })
                        : handleRiderActivation(rider);
                    },
                  },
                ]}
              />
            )}
          </Modal>
        </div>
      ))}
    </div>
  );
};

RidersMobileList.propTypes = {
  riders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      assignedOrdersCount: PropTypes.number,
      isActive: PropTypes.bool,
      jobType: PropTypes.string,
      onShift: PropTypes.bool,
      branches: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

export default RidersMobileList;
