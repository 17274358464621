import React, { useContext } from 'react';
import cx from 'classnames';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { Text, Field } from 'components/service';
import { Label, Row, Container } from 'components/form/generic';
import { Input, PhoneInput, RadioList, CheckboxList } from 'components/form/elements';
import * as translations from 'constants/translations';
import { RIDER_JOB_TYPES } from 'constants/riderJobTypes';
import { RIDER_LANGUAGES } from 'constants/riderLanguages';

const RiderForm = () => {
  const { lang } = useContext(localeContext);
  const { branches } = useContext(userContext);

  return (
    <div className="pb-32 md:pb-12">
      <Container>
        <Row>
          <Label title={<Text value={translations.FULL_NAME} />}>
            <Field
              type="text"
              name="name"
              placeholder={lang === 'en' ? translations.ENTER_FULL_NAME[0] : translations.ENTER_FULL_NAME[1]}
              component={Input}
            />
          </Label>
        </Row>
        <Row>
          <Label title={<Text value={translations.PHONE_NUMBER} />}>
            <Field
              type="text"
              name="phone"
              placeholder={lang === 'en' ? translations.ENTER_PHONE[0] : translations.ENTER_PHONE[1]}
              component={PhoneInput}
            />
          </Label>
        </Row>
        <Row>
          <Label title={<Text value={translations.EMAIL} />}>
            <div className="w-full">
              <Field
                type="text"
                name="email"
                placeholder={lang === 'en' ? translations.ENTER_EMAIL[0] : translations.ENTER_EMAIL[1]}
                component={Input}
              />
            </div>
          </Label>
        </Row>

        <Row>
          <Label title={<Text value={translations.LANGUAGE} />}>
            <Field
              name="language"
              items={[
                {
                  value: RIDER_LANGUAGES.ENGLISH,
                  title: <Text value={translations.ENGLISH} />,
                },
                {
                  value: RIDER_LANGUAGES.ARABIC,
                  title: <Text value={translations.ARABIC} />,
                },
              ]}
              component={RadioList}
            />
          </Label>
        </Row>

        <Row>
          <Label title={<Text value={translations.JOB_TYPE} />}>
            <Field
              name="jobType"
              items={[
                {
                  value: RIDER_JOB_TYPES.FULL_TIME,
                  title: <Text value={translations.FULLTIME} />,
                },
                {
                  value: RIDER_JOB_TYPES.FREELANCE,
                  title: <Text value={translations.FREELANCER} />,
                },
              ]}
              component={RadioList}
            />
          </Label>
        </Row>
        {branches && (
          <Row>
            <Label title={<Text value={translations.RIDERS_BRANCHES} />}>
              <div className={cx('w-full', branches.length > 7 && 'overflow-y-scroll overflow-x-hidden h-48')}>
                <Field
                  name="branches"
                  component={CheckboxList}
                  items={branches.map(branch => ({
                    value: Number(branch.id),
                    title: <Text value={branch} />,
                  }))}
                />
              </div>
            </Label>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default RiderForm;
