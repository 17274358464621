import { gql } from 'graphql-request';

const updateStoreDriverMutation = gql`
  mutation($input: UpdateStoreDriverMutationInput!) {
    updateStoreDriver(input: $input) {
      id
      branches
      storeId
      jobType
      email
      name
      phone
      isActive
      onShift
      errors {
        field
        messages
      }
    }
  }
`;

export default updateStoreDriverMutation;
