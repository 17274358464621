import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Field, Text } from 'components/service';
import { Input } from 'components/form/elements';
import { Label } from 'components/form/generic';
import { ReactComponent as RetentionCampaignIcon } from 'assets/retention-campaign-refresh.svg';
import { ReactComponent as SegmentTargetingCampaignIcon } from 'assets/segment-targeting-users.svg';
import {
  RETENTION,
  RETENTION_DESCRIPTION,
  SEGMENT_TARGETING,
  SEGMENT_TARGETING_DESCRIPTION,
  CAMPAIGN_TITLE,
  EX_CHECK_OUR_OFFERS,
} from 'constants/translations';
import CampaignTypeRadioGroup from './CampaignTypeRadioGroup';
import { CAMPAIGN_TYPES, CAMPAIGN_OBJECT_KEYS } from './constants';

function CampaignTypeStep({ handleChange, lang, translate, isEdit = false, resetValuesOnTypeChange }) {
  return (
    <div className="pt-6 md:pt-10">
      <div className={cx('w-full mb-2 lg:w-5/12', lang === 'en' ? 'md:pr-3' : 'md:pl-3')}>
        <Label title={<Text value={CAMPAIGN_TITLE} />}>
          <Field
            type="text"
            name={CAMPAIGN_OBJECT_KEYS.TITLE}
            disabled={isEdit}
            maxLength={50}
            placeholder={translate(EX_CHECK_OUR_OFFERS)}
            component={Input}
          />
        </Label>
      </div>
      <Field
        name={CAMPAIGN_OBJECT_KEYS.TYPE}
        items={[
          {
            title: RETENTION,
            value: CAMPAIGN_TYPES.RETENTION,
            description: RETENTION_DESCRIPTION,
            icon: fill => <RetentionCampaignIcon fill={fill} />,
          },
          {
            title: SEGMENT_TARGETING,
            value: CAMPAIGN_TYPES.SEGMENT_TARGETING,
            description: SEGMENT_TARGETING_DESCRIPTION,
            icon: fill => <SegmentTargetingCampaignIcon fill={fill} />,
          },
        ]}
        component={CampaignTypeRadioGroup}
        onChange={e => {
          handleChange(e);
          resetValuesOnTypeChange();
        }}
      />
    </div>
  );
}

CampaignTypeStep.propTypes = {
  handleChange: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  resetValuesOnTypeChange: PropTypes.func.isRequired,
  lang: PropTypes.oneOf(['en', 'ar']),
};

export default CampaignTypeStep;
