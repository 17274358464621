import React, { useContext } from 'react';
import cx from 'classnames';

import { context as localeContext } from 'context/locale';

const Text = ({
  full = true,
  prefix,
  selectedLang,
  value,
  postfix,
  postfixElement,
  className,
  payload,
  onClick,
  testId,
  forcedDirection,
}) => {
  const { lang, direction, translate } = useContext(localeContext);

  return (
    <span
      className={cx('block', full && 'w-full', className)}
      style={forcedDirection ? { direction: forcedDirection } : { direction }}
      onClick={onClick}
      data-testid={testId}
    >
      {prefix && `${prefix} `}
      {translate(value, (selectedLang = selectedLang ? selectedLang : lang), payload)}
      {postfix && ` ${postfix}`}
      {postfixElement && postfixElement}
    </span>
  );
};

export default Text;
