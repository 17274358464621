import React, { useContext } from "react"
import cx from "classnames"
import { context as localeContext } from "context/locale"
import Datetime from "react-datetime"
import { Error } from "components/form/generic"
import { ReactComponent as Time } from "assets/time.svg"
import "./index.css"

export default ({ name, disabled, value, error, onChange, ...props }) => {
  const { direction, lang } = useContext(localeContext)

  return (
    <div>
      <div className="flex flex-col relative">
        <Datetime
          className={cx("form-time-picker", props && props.dateTimeClassName)}
          dateFormat={false}
          timeFormat="h:mm A"
          inputProps={{
            disabled,
            className: cx(
              "w-full text-sm flex-shrink flex-grow flex-auto flex-1 text-zyda-black-100 appearance-none w-full h-10 leading-tight focus:outline-none border",
              lang === "ar" ? "pl-2 pr-10" : "pr-2 pl-10",
              error
                ? "border-zyda-red-500 bg-zyda-red-50"
                : "border-gray-300",
              lang === "ar" && "text-right"
            ),
            style: { direction: "ltr" },
            onKeyDown: e => {
              /**
               * Filtering out non letter/number input
               */
              if (e.key !== e.key.toLowerCase()) {
                return
              }

              if (!/[0-9]|:/.test(e.key)) {
                e.preventDefault()
              }
            },
          }}
          name={name}
          value={value}
          onChange={value => {
            if (typeof value === "string") return

            onChange({
              target: {
                name,
                value: value.format("h:mm A"),
              },
            })
          }}
          viewMode="time"
        />
        <div
          className={cx(
            "absolute top-0 bottom-0 flex items-center",
            lang === "ar" ? "right-0 pr-3" : "left-0 pl-3"
          )}
        >
          <Time />
        </div>
      </div>
      {error && <Error direction={direction}>{error}</Error>}
    </div>
  )
}
