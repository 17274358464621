import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import { useQuery } from '@apollo/client';

import EmptyState from 'components/common/EmptyState';
import { RocketIcon } from '@zydadesign/zac-icons-react';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import * as paths from 'paths';
import * as translations from 'constants/translations';
import { Button, Modal } from 'components/kit';
import { Text } from 'components/service';
import { ReactComponent as LoyaltyEmpty } from 'assets/loyalty-emptyList.svg';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import CashBackProgram from './components/CashbackProgram';
import AddCashBack from './components/AddCashBack';
import { CASHBACK_PROGRAMS_LIST } from './schemas';
import { BACKEND_GRAPHQL_SERVER } from '../../../constants';
import { Spinner } from '../../../components/kit';
import FeatureEnablement from '../../../common/components/FeatureEnablement';
import useDeactivateProgram from './hooks/useDeactivateProgram';
import DeactivateProgramConfirmation from './components/DeactivateProgramConfirmation';
import CASHBACK_PROGRAM_STATUS_ENUM from './constants';

const Loyalty = () => {
  const { direction } = useContext(localeContext);
  const { selectedStore, settings } = useContext(userContext);
  const { subdomain, isCommissionBased, id } = selectedStore || {};
  const { onlinePaymentEnabled } = settings || {};

  const { data, isLoading } = useQuery(CASHBACK_PROGRAMS_LIST, {
    context: { serverName: BACKEND_GRAPHQL_SERVER },
    variables: { subdomain },
  });
  const [deactivateProgram] = useDeactivateProgram();

  const isCreatingProgramDisabled = isCommissionBased || !onlinePaymentEnabled;
  const isDisabledForOnlinePayment = !isCommissionBased && !onlinePaymentEnabled;
  const sortArray = [
    CASHBACK_PROGRAM_STATUS_ENUM.ACTIVE,
    CASHBACK_PROGRAM_STATUS_ENUM.SCHEDULED,
    CASHBACK_PROGRAM_STATUS_ENUM.ENDED,
  ];

  const cashbackPrograms =
    data?.cashbackPrograms &&
    [...data?.cashbackPrograms].sort((a, b) => sortArray.indexOf(a.status) - sortArray.indexOf(b.status));

  return (
    <Modal>
      {({ open, close }) => (
        <Layout
          top={
            <Breadcrumbs
              links={[paths.loyalty]}
              path={translations.breadcrumbs.LOYALTY}
              right={
                <>
                  <div className="flex px-4">
                    <Button
                      kind="primary"
                      disabled={isCreatingProgramDisabled}
                      onClick={() =>
                        open({
                          title: <Text value={translations.CREATE_CASHBACK_PROGRAM} />,
                          body: <AddCashBack onCancel={close} onComplete={close} />,
                        })
                      }
                    >
                      <Text value={translations.CREATE_CASHBACK_PROGRAM} />
                    </Button>
                  </div>
                </>
              }
            />
          }
          direction={direction}
        >
          {isLoading && (
            <div className="w-full h-screen justify-center flex overflow-hidden">
              <Spinner />
            </div>
          )}
          {cashbackPrograms?.length > 0 && !isCreatingProgramDisabled && (
            <div className="xl:w-1/2" style={{ direction }}>
              {cashbackPrograms?.map(program => (
                <CashBackProgram
                  program={program}
                  onDeactivate={({ descriptionText, actionButtonText }) => {
                    open({
                      title: program.name,
                      body: (
                        <div style={{ direction }}>
                          <DeactivateProgramConfirmation
                            descriptionText={descriptionText}
                            actionButtonText={actionButtonText}
                            onClose={close}
                            onConfirm={async () => {
                              await deactivateProgram(program.id);
                              close();
                            }}
                          />
                        </div>
                      ),
                    });
                  }}
                />
              ))}
            </div>
          )}
          {cashbackPrograms?.length === 0 && !isCreatingProgramDisabled && (
            <EmptyState
              iconComponent={
                <div className="mt-64">
                  <LoyaltyEmpty />
                </div>
              }
              emptyStateText={translations.LOYALTY_EMPTY}
            />
          )}
          {isCreatingProgramDisabled && (
            <FeatureEnablement
              hint={
                <div className="flex items-center">
                  <RocketIcon width="20px" />
                  <Text className="text-sm mx-2" value={translations.UNLOCK_LOYALITY_PROGRAMS} />
                </div>
              }
              title={
                <Text className="text-4xl font-semibold my-3" value={translations.ACQUIRE_THE_POWER_OF_LOYALITY} />
              }
              actionButton={
                <Button
                  kind="primary"
                  isPillShape
                  isRounded
                  className="self-start mt-10"
                  onClick={e => {
                    e.stopPropagation();
                    if (isDisabledForOnlinePayment) navigate(`/${id}/setup/payment-gateways`);
                  }}
                >
                  <Text
                    className="font-light"
                    value={
                      isDisabledForOnlinePayment
                        ? translations.ENABLE_ONLINE_PAYMENTS
                        : translations.UPGRADE_YOUR_FREE_PLAN
                    }
                  />
                </Button>
              }
              description={
                <div className="px-8 py-4 w-2/5">
                  <Text className="font-semibold " value={translations.CONVERT_NEW_TO_LOYALS} />
                  {translations.REASONS_TO_CONVERT_NEW_TO_LOYALS.map(value => (
                    <div className="flex justify-start">
                      <Text prefix="•" className="text-gray-500 my-3 inline-flex w-auto px-2" />
                      <Text className="text-gray-500 my-3 inline-flex w-auto" value={value} />
                    </div>
                  ))}
                </div>
              }
            />
          )}
        </Layout>
      )}
    </Modal>
  );
};
export default Loyalty;
