import React, { useContext, useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useMutation } from '@apollo/client';
import { InfoIcon } from '@zydadesign/zac-icons-react';

import * as translations from 'constants/translations';
import * as paths from 'paths.js';
import { withBackParam } from 'utils/history';
import { context as localeContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import { context as userContext } from 'context/user';
import { breadcrumbs } from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Button, Spinner } from 'components/kit';
import { DELIVERY_LINKS_NEW } from 'constants/helperLinks';
import { Link, Text } from 'components/service';
import { useFetchBranches } from 'service/hooks';
import BranchSelection from './BranchSelection';
import ZonesSearch from './ZonesSearch';
import { tableViewControllerUtils } from './utils';
import Table from './Table';
import useFetchDeliveryZones from '../../../../service/hooks/deliveryZones/useFetchDeliveryZones';
import { selectedZoneIdUtils } from '../Zones/utils';
import MapView from './MapView';
import EmptyState from './EmptyState';
import { UPDATE_BRANCH_DELIVERY } from './schemas';
import BulkEdit from './BulkEdit';

const ZonesList = () => {
  const { lang, direction, translate } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const { selectedStore, dzmsSelectedBranch, setDzmsSelectedBranch } = useContext(userContext);
  const { id: selectedStoreId, currency } = selectedStore;

  const [isDeliveryEnabled, setIsDeliveryEnabled] = useState(true);
  const [tableViewController, setTableViewController] = useState(tableViewControllerUtils);
  const [zones, setZones] = useState([]);
  const [localBranches, setLocalBranches] = useState([]);
  const [bulkZones, setBulkZones] = useState([]);

  const { data: dzmsBranches, isLoading: dzmsBranchesLoading, error: dzmsBranchesError } = useFetchBranches({
    restaurantReferenceId: parseFloat(selectedStoreId),
  });
  const { data, isLoading } = useFetchDeliveryZones({
    restaurantReferenceId: selectedStoreId,
    branchReferenceId: tableViewController.selectedBranch || null,
  });

  useEffect(() => {
    if (!dzmsBranchesLoading) {
      if (dzmsBranches.length >= 1) {
        setLocalBranches(
          dzmsBranches.map(branch => ({
            id: branch.referenceId,
            title: <Text value={[branch.titleEn, branch.titleAr]} />,
            titleEn: branch.titleEn,
            titleAr: branch.titleAr,
            branchViewPort: branch.branchViewPort.coordinates[0].map(cord => ({ lat: cord[1], lng: cord[0] })),
            lat: branch.lonlat.coordinates[1],
            lng: branch.lonlat.coordinates[0],
            deliveryOrdersEnabled: branch.deliveryOrdersEnabled,
            openingHours: branch.openingHours,
          })),
        );
      }
    }
  }, [dzmsBranchesLoading, dzmsBranches]);

  useEffect(() => {
    if (localBranches.length >= 1 && !dzmsSelectedBranch) {
      setTableViewController(prevState => ({
        ...prevState,
        selectedBranch: localBranches[0].id,
        branchViewPort: localBranches[0].branchViewPort,
        branchOpeningHours: localBranches[0].openingHours,
      }));
    }
  }, [localBranches]);

  useEffect(() => {
    if (!isLoading) {
      if (data.featureCollection.features !== null) {
        setZones(data.featureCollection.features);
      }
    }
  }, [data]);

  useEffect(() => {
    if (tableViewController.selectedZoneId) {
      setZones(prevState => prevState.filter(item => item.properties.id !== tableViewController.selectedZoneId));
    }
  }, [tableViewController.selectedZoneId]);

  const filterZones = useMemo(() => {
    if ((!zones || zones.length === 0) && data) return [...zones];

    return zones.filter(item =>
      item.properties.zone_name.toLowerCase().includes(tableViewController.zoneSearchValue.toLowerCase()),
    );
  }, [tableViewController.zoneSearchValue, zones]);

  useEffect(() => {
    if (tableViewController.selectedBranch) {
      setZones(prevState =>
        prevState.filter(
          zoneItem => zoneItem.properties.branch_reference_id === parseFloat(tableViewController.selectedBranch),
        ),
      );
    }
  }, [tableViewController.selectedBranch]);

  useEffect(() => {
    if (tableViewController.selectedBranch && localBranches.length >= 1) {
      const localBranch = localBranches.find(branch => branch.id === tableViewController.selectedBranch);
      if (localBranch) {
        setTableViewController(prevState => ({
          ...prevState,
          branchViewPort: localBranch.branchViewPort,
          branchOpeningHours: localBranch.openingHours,
        }));
        setDzmsSelectedBranch(localBranch);
        setIsDeliveryEnabled(localBranch.deliveryOrdersEnabled);
      }
    }
  }, [tableViewController.selectedBranch, localBranches]);

  const [updateBranch] = useMutation(UPDATE_BRANCH_DELIVERY, {
    onError: () => notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error'),
    onCompleted: res => {
      setLocalBranches(prevState =>
        prevState.map(branchItem =>
          branchItem.id === parseInt(res.updateBranch.id)
            ? {
                ...branchItem,
                deliveryOrdersEnabled: true,
              }
            : branchItem,
        ),
      );
      notifications.show(<Text value={translations.ENABLE_DELIVERY_SUCCESS_TEXT} />);
      setIsDeliveryEnabled(true);
    },
  });
  // - get dzms selected branch
  useEffect(() => {
    if (dzmsSelectedBranch) {
      setTableViewController(prevState => ({
        ...prevState,
        selectedBranch: dzmsSelectedBranch.id,
      }));
    }
  }, []);

  return (
    <Layout
      top={
        <Breadcrumbs
          helperLinks={DELIVERY_LINKS_NEW}
          path={breadcrumbs.SETUP_DELIVERY_LIST}
          right={
            !dzmsBranchesLoading &&
            dzmsBranches.length >= 1 && (
              <Link to={`${paths.zonesCreation}?${withBackParam()}`} omitQuery>
                <Button kind="primary" onClick={() => localStorage.removeItem(selectedZoneIdUtils.SELECTEDZONEID)}>
                  <Text value={translations.ADD_DELIVERY_ZONE} />
                </Button>
              </Link>
            )
          }
        />
      }
    >
      <div className={cx('pb-6 flex', lang === 'ar' && 'flex-row-reverse')}>
        <div className="w-full" style={{ direction }}>
          {dzmsBranchesError ? (
            <Text value={translations.SOMETHING_WENT_WRONG} />
          ) : dzmsBranchesLoading ? (
            <Spinner />
          ) : dzmsBranches.length === 0 ? (
            <EmptyState />
          ) : (
            <>
              <MapView branches={localBranches} zones={zones} tableViewController={tableViewController} />
              <div className="flex justify-between items-center flex-wrap">
                <div className="flex w-3/5">
                  <div className={cx('w-1/4', direction === 'ltr' ? 'mr-6' : 'ml-6')}>
                    <BranchSelection
                      branches={localBranches}
                      tableViewController={tableViewController}
                      setTableViewController={setTableViewController}
                      setBulkZones={setBulkZones}
                    />
                  </div>
                  <div className="flex-auto">
                    <ZonesSearch
                      tableViewController={tableViewController}
                      setTableViewController={setTableViewController}
                    />
                  </div>
                </div>
                {bulkZones.length >= 1 && (
                  <BulkEdit
                    bulkZones={bulkZones}
                    setBulkZones={setBulkZones}
                    setZones={setZones}
                    branches={localBranches}
                    tableViewController={tableViewController}
                  />
                )}
              </div>
              {!isDeliveryEnabled && (
                <div
                  className="py-2 px-4 border border-yellow-200 mb-4 bg-zyda-yellow-25 flex items-center justify-between flex-wrap"
                  style={{ direction }}
                >
                  <div className="flex">
                    <InfoIcon
                      width="16"
                      color="#B48A00"
                      className={cx('flex-shrink-0', direction === 'rtl' ? 'ml-2' : 'mr-2')}
                    />
                    <div>
                      <span className={cx('text-xs font-semibold', direction === 'rtl' ? 'ml-2' : 'mr-2')}>
                        {translate(translations.BRANCH_DELIVERY_DISABLED)}
                      </span>
                      <span className="text-xs">{translate(translations.ENABLE_DELIVERY_TEXT)}</span>
                    </div>
                  </div>
                  <Button
                    kind="link"
                    className="font-normal"
                    weight="normal"
                    isMenu
                    onClick={() => {
                      updateBranch({
                        variables: {
                          id: tableViewController.selectedBranch.toString(),
                          restaurantId: selectedStoreId.toString(),
                          deliveryOrdersEnabled: true,
                        },
                      });
                    }}
                  >
                    <Text value={translations.ENABLE_DELIVERY} />
                  </Button>
                </div>
              )}

              <div className="mt-1">
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Table
                    zones={filterZones}
                    currency={currency}
                    setTableViewController={setTableViewController}
                    setZones={setZones}
                    branches={localBranches}
                    bulkZones={bulkZones}
                    setBulkZones={setBulkZones}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ZonesList;
