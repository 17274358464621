import React, { useContext } from 'react';
import { Form } from 'formik';
import { useMutation } from '@apollo/client';
import * as paths from 'paths.js';

import { useSelectedStore } from 'hooks/index';
import { context as localeContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import { Text, Link } from 'components/service';
import { breadcrumbs } from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { RiderData, RiderForm } from 'components/common/verd';
import { Button } from 'components/kit';
import * as translations from 'constants/translations';
import ADD_RIDER from './schemas';
import { useCreateStoreDriver } from '../../../service/hooks';

const CreateRiderPage = () => {
  const { direction } = useContext(localeContext);
  const selectedStoreId = useSelectedStore();
  const notifications = useContext(notificationsContext);
  const [{ loading }] = useMutation(ADD_RIDER);
  const createStoreDriver = useCreateStoreDriver();

  const handleCreateStoreDriver = async variables => {
    const response = await createStoreDriver({ storeId: selectedStoreId, ...variables });
    if (response.hasError) {
      response.errors?.map(error => notifications.show(error.message, 'error'));
    }
  };

  return (
    <RiderData onSubmit={variables => handleCreateStoreDriver(variables)}>
      <Layout
        top={
          <div>
            <Breadcrumbs links={[paths.verd]} path={breadcrumbs.VERD_CREATE_RIDER} />
          </div>
        }
      >
        <div style={{ direction }} className="pb-6">
          <Form className="w-full md:w-2/3">
            <RiderForm />
            <div className="flex justify-end">
              <div className="mr-3">
                <Link to={paths.verd}>
                  <Button kind="secondary">
                    <Text value={translations.CANCEL} />
                  </Button>
                </Link>
              </div>
              <Button isSpinning={loading} kind="primary">
                <Text value={translations.ADD} />
              </Button>
            </div>
          </Form>
        </div>
      </Layout>
    </RiderData>
  );
};

export default CreateRiderPage;
