import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import moment from 'moment';
import { ExternalLinkIcon } from '@zydadesign/zac-icons-react';

import { Text } from 'components/service';
import * as paths from 'paths.js';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { More, Button } from 'components/kit';
import { CheckboxAlt } from 'components/form/elements';

import { useReplaceParams } from 'hooks';
import { checkEqual, checkOnDaysCounter, selectedZoneIdUtils } from '../../../Zones/utils';
import ZoneStatus from '../ZoneStatus';
import { openingHoursUtils } from '../../utils';
import DeleteZone from '../DeleteZone';
import EditZone from '../EditZone';

const TableRow = ({
  item,
  currency,
  open,
  close,
  setTableViewController,
  setZones,
  branches,
  bulkZones,
  setBulkZones,
}) => {
  const { lang, direction, translate } = useContext(localeContext);
  const [isChecked, setIsChecked] = useState(false);
  const replace = useReplaceParams();
  const { properties } = item;
  const {
    zone_name: zoneName,
    delivery_fee: deliveryFee,
    minimum_order: minValue,
    published,
    delivery_time: deliveryTime,
    opening_hours: openingHours,
    id,
  } = properties;

  const [hoursInit, setHoursInit] = useState({ days: 0, time: '10am : 11pm' });
  const dataCellClassName = 'py-3 px-5 text-xs border-b border-gray-300 whitespace-nowrap';

  useEffect(() => {
    if (openingHours) {
      let availableDays = 0;
      const filteredCheckedDays = checkOnDaysCounter(openingHours, openingHoursUtils.OFF_DAY);
      const only1DayOnLength = filteredCheckedDays.length === 1;
      const enhanceDisplayText = () => {
        if (only1DayOnLength) {
          return `[${moment(filteredCheckedDays[0].openAt, ['HH.mm']).format(
            'hh:mm a',
          )} : ${moment(filteredCheckedDays[0].closeAt, ['HH.mm']).format('hh:mm a')}]`;
        }

        if (
          checkEqual(filteredCheckedDays, openingHoursUtils.OPEN_AT) &&
          checkEqual(filteredCheckedDays, openingHoursUtils.CLOSE_AT) &&
          filteredCheckedDays.length >= 1
        ) {
          return `[${moment(filteredCheckedDays[0].openAt, ['HH.mm']).format(
            'hh:mm a',
          )} : ${moment(filteredCheckedDays[0].closeAt, ['HH.mm']).format('hh:mm a')}]`;
        }

        return lang === 'en' ? translations.MULTIPLE_DELIVERY_HOURS[0] : translations.MULTIPLE_DELIVERY_HOURS[1];
      };

      openingHours.forEach(obj => {
        if (!obj.offDay) {
          availableDays += 1;
        }
      });

      setHoursInit({ days: availableDays, time: enhanceDisplayText() });
    }
  }, [openingHours]);

  useEffect(() => {
    const isItemChecked = bulkZones.find(bulkItem => bulkItem.properties.id === id);
    setIsChecked(!!isItemChecked);
  }, [bulkZones]);

  return (
    <tr className={isChecked && 'bg-primary-50'}>
      <td className={dataCellClassName}>
        <CheckboxAlt
          onChange={e => {
            const { value } = e.target;
            setIsChecked(value);
            if (value) {
              setBulkZones(prevState => prevState.concat(item));
            } else {
              const filteredBulkZones = bulkZones.filter(bulkItem => bulkItem.properties.id !== id);
              setBulkZones(filteredBulkZones);
            }
          }}
          value={isChecked}
        />
      </td>
      <td className={dataCellClassName}>
        <Button
          kind="tertiaryLink"
          type="button"
          isMenu
          onClick={e => {
            e.preventDefault();
            open({
              title: (
                <div className="flex-1 flex items-center justify-between w-11/12">
                  <Text value={translations.ZONE_PROPERTIES} />
                  <div
                    className="flex items-center justify-center flex-shrink-0 cursor-pointer"
                    aria-hidden="true"
                    onClick={() => {
                      localStorage.setItem(selectedZoneIdUtils.SELECTEDZONEID, JSON.stringify(item.properties));
                      navigate(replace(paths.zonesCreation, { omitQuery: true }));
                    }}
                  >
                    <Text className="text-xs text-primary-base" value={translations.VIEW_ON_MAP} />
                    <ExternalLinkIcon width="32" color="#1354C2" className="px-1" />
                  </div>
                </div>
              ),
              body: (
                <EditZone
                  onClose={close}
                  item={item}
                  setZones={setZones}
                  setTableViewController={setTableViewController}
                  branches={branches}
                  open={open}
                />
              ),
              size: 'max-w-2xl',
            });
          }}
          disabled={isChecked}
        >
          <Text className="text-primary-base text-sm" value={[zoneName, zoneName]} />
        </Button>
      </td>
      <td className={dataCellClassName}>
        <Text prefix={deliveryFee} value={currency} />
      </td>
      <td className={dataCellClassName}>{minValue ? <Text prefix={minValue} value={currency} /> : '-'}</td>
      <td className={dataCellClassName}>
        <ZoneStatus direction={direction} published={published} />
      </td>
      <td className={dataCellClassName}>
        {deliveryTime ? <Text prefix={deliveryTime} value={translations.MINS} /> : '-'}
      </td>
      <td className={dataCellClassName}>
        <div className="flex justify-between items-center">
          {openingHours
            ? !hoursInit.days
              ? translate(translations.NO_DAYS_SELECTED)
              : `${hoursInit.days} ${lang === 'en' ? translations.DAYS[0] : translations.DAYS[1]}. ${hoursInit.time}`
            : '-'}
          <More
            kind="tertiary"
            size="sm"
            items={[
              {
                title: <Text value={translations.EDIT} />,
                onClick: () =>
                  open({
                    title: (
                      <div className="flex-1 flex items-center justify-between w-11/12">
                        <Text value={translations.ZONE_PROPERTIES} />
                        <div
                          className="flex items-center justify-center flex-shrink-0 cursor-pointer"
                          aria-hidden="true"
                          onClick={() => {
                            localStorage.setItem(selectedZoneIdUtils.SELECTEDZONEID, JSON.stringify(item.properties));
                            navigate(replace(paths.zonesCreation, { omitQuery: true }));
                          }}
                        >
                          <Text className="text-xs text-primary-base" value={translations.VIEW_ON_MAP} />
                          <ExternalLinkIcon width="32" color="#1354C2" className="px-1" />
                        </div>
                      </div>
                    ),
                    body: (
                      <EditZone
                        onClose={close}
                        item={item}
                        setZones={setZones}
                        setTableViewController={setTableViewController}
                        branches={branches}
                        open={open}
                      />
                    ),
                    size: 'max-w-2xl',
                  }),
              },
              {
                title: <Text value={translations.VIEW_ON_MAP} />,
                onClick: () => {
                  localStorage.setItem(selectedZoneIdUtils.SELECTEDZONEID, JSON.stringify(item.properties));
                  navigate(replace(paths.zonesCreation, { omitQuery: true }));
                },
              },
              {
                title: <Text value={translations.DELETE} />,
                onClick: () =>
                  open({
                    title: <Text value={translations.ZONE} />,
                    body: (
                      <DeleteZone onClose={close} selectedZoneId={id} setTableViewController={setTableViewController} />
                    ),
                    size: 'max-w-md',
                  }),
              },
            ]}
            disabled={isChecked}
          />
        </div>
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  item: PropTypes.shape({
    properties: PropTypes.shape({
      zone_name: PropTypes.string,
      delivery_fee: PropTypes.number,
      minimum_order: PropTypes.number,
      published: PropTypes.bool,
      delivery_time: PropTypes.number,
      opening_hours: PropTypes.arrayOf(
        PropTypes.shape({
          openAt: PropTypes.string,
          closeAt: PropTypes.string,
        }),
      ),
      id: PropTypes.number,
    }),
  }).isRequired,
  currency: PropTypes.shape({
    titleEn: PropTypes.string,
  }).isRequired,
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  setTableViewController: PropTypes.func.isRequired,
  setZones: PropTypes.func.isRequired,
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
  bulkZones: PropTypes.arrayOf(
    PropTypes.shape({
      properties: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
  ),
  setBulkZones: PropTypes.func.isRequired,
};

export default TableRow;
