/**
 * Utility library for using the freshchat
 */
const freshchat = {
  /**
   * Inititalizes freshchat sdk
   */
  init() {
    let createFreshchatScript = () => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = '//fw-cdn.com/2222086/2891096.js';
      script.chat = 'true';
      let first = document.getElementsByTagName('script')[0];
      first.parentNode.insertBefore(script, first);
    };
    if (window.attachEvent) {
      window.attachEvent('onload', createFreshchatScript);
    } else {
      window.addEventListener('load', createFreshchatScript, false);
    }
  },
};

export default freshchat;
