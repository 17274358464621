import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import * as translations from 'constants/translations';
import { context as notificationsContext } from 'context/notifications';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { Text } from 'components/service';
import { Button } from 'components/kit';
import { useAssignDriverToOrder } from 'service/hooks';
import updateDeliveryCourierCache from 'utils/updatingCache/updateDeliveryCourierCache';
import { useSelectedStore } from 'hooks';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';

const StoreRider = ({
  order,
  rider,
  verd,
  mutateFetchVerdDrivers,
  assignedDriverId,
  setIsLoading,
  onToggle,
  isCourierNotDeclinedTheRequest,
  isExpectedAtValid,
  openOrderDeliveryConfirmationPopUp,
}) => {
  const { lang } = useContext(localeContext);
  const selectedStoreId = useSelectedStore();
  const { user } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const assignDriverToOrder = useAssignDriverToOrder();
  const riderHasFullCapacity = rider.assignedOrdersCount === verd.driverMaxCapacity;

  const handleUpdateOrderInCache = selectedDriver => {
    const updatedOrder = {
      ...order,
      deliveryCourier: {
        ...order.deliveryCourier,
        courierId: selectedDriver ? Number(verd.courierId) : '',
        driverPhoneNumber: selectedDriver ? selectedDriver?.phone : '',
        driverName: selectedDriver ? selectedDriver?.name : '',
        deliveryOrderStatus: ORDER_RIDER_STATUS.PENDING,
        isInternalDelivery: selectedDriver && true,
      },
      deliveryCourierId: selectedDriver ? Number(verd.courierId) : '',
    };
    const orderVariables = {
      orderId: order.id,
      storeId: selectedStoreId,
    };
    updateDeliveryCourierCache(orderVariables, updatedOrder);
    setIsLoading(false);
  };

  const handleAssignDriverToOrder = async (selectedDriver, notifyUser, newExpectedAt) => {
    const driverId = selectedDriver.id;
    setIsLoading(true);
    const response = await assignDriverToOrder({
      driverId,
      orderNumber: order.number,
      courierId: verd.courierId,
      userId: user?.id,
      ...(notifyUser && { notifyUser }),
      ...(newExpectedAt && { scheduledDeliveryAt: newExpectedAt }),
    });
    setIsLoading(false);
    if (response.hasError) {
      response.errors?.map(error => notifications.show(error.message, 'error'));
    } else {
      mutateFetchVerdDrivers();
      handleUpdateOrderInCache(selectedDriver);
    }
  };

  const handleUnassignAssignDriverToOrder = async () => {
    setIsLoading(true);
    const response = await assignDriverToOrder({ driverId: -1, orderNumber: order.number, userId: user?.id });
    setIsLoading(false);
    if (response.hasError) {
      response.errors?.map(error => notifications.show(error.message, 'error'));
    } else {
      mutateFetchVerdDrivers();
      handleUpdateOrderInCache();
    }
  };

  return (
    <div
      className={cx('p-2 w-100 flex flex-1 border-b justify-between', lang === 'ar' ? 'flex-row-reverse' : 'flex-row')}
    >
      <span className="flex flex-col items-start" style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
        <span className="text-sm text-gray-700">{rider.name}</span>
        <div>
          <Text
            className={cx('text-xs inline-block w-auto', riderHasFullCapacity ? 'text-red-600' : 'text-green-600')}
            value={riderHasFullCapacity ? translations.MAX_CAPACITY : translations.AVAILABLE}
          />
          {rider.onShift && rider.assignedOrdersCount > 0 && (
            <>
              <Text className="text-xs text-gray-400 inline-block w-auto mx-1" value={translations.UNDERSCORE} />
              <Text
                className="text-xs text-gray-400 inline-block w-auto"
                value={translations.ASSIGNED_ORDERS}
                payload={rider.assignedOrdersCount}
              />
            </>
          )}
        </div>
      </span>
      {rider?.id === assignedDriverId ? (
        <Button
          style={{
            width: '110px',
          }}
          kind="outline"
          weight="thin"
          onClick={() => {
            onToggle();
            handleUnassignAssignDriverToOrder();
          }}
          size="sm"
        >
          <Text className="text-xs" value={translations.UNASSIGN} />
        </Button>
      ) : (
        <Button
          style={{
            width: '110px',
          }}
          kind="outline"
          weight="thin"
          className={cx(riderHasFullCapacity && 'opacity-40')}
          disabled={riderHasFullCapacity}
          onClick={() => {
            onToggle();
            !isExpectedAtValid || isCourierNotDeclinedTheRequest
              ? openOrderDeliveryConfirmationPopUp({ assignDriverToOrder: handleAssignDriverToOrder, assignee: rider })
              : handleAssignDriverToOrder(rider);
          }}
          size="sm"
        >
          <Text className="text-xs" value={translations.ASSIGN} />
        </Button>
      )}
    </div>
  );
};

StoreRider.propTypes = {
  order: PropTypes.shape({
    number: PropTypes.string,
    id: PropTypes.number,
    deliveryCourier: {
      driverAssigned: PropTypes.bool,
      driverName: PropTypes.string,
      driverPhoneNumber: PropTypes.number,
      referenceId: PropTypes.string,
      externalOrderIdentifierLink: PropTypes.string,
      externalOrderIdentifierType: PropTypes.string,
      trackingLink: PropTypes.string,
      hasDriverInfo: PropTypes.bool,
      courierDetails: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        displayNameAr: PropTypes.string,
        displayNameEn: PropTypes.string,
      }),
    },
  }),
  rider: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
  verd: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    displayNameEn: PropTypes.string,
    displayNameAr: PropTypes.string,
  }),
  mutateFetchVerdDrivers: PropTypes.func,
  assignedDriverId: PropTypes.number,
  isCourierNotDeclinedTheRequest: PropTypes.bool,
  onToggle: PropTypes.func,
  setIsLoading: PropTypes.func,
  isExpectedAtValid: PropTypes.bool,
  openOrderDeliveryConfirmationPopUp: PropTypes.func,
};

export default StoreRider;
