import React from "react"
import cx from "classnames"

export default ({
  isError,
  name,
  value,
  onChange,
  size = "regular",
  testId,
}) => {
  const itemClassName = {
    small: "py-1 px-2 rounded font-light text-xs",
    regular: "py-2 px-4 rounded text-sm",
  }
  const change = value => onChange({ target: { name, value } })

  return (
    <div>
      <div
        className={cx(
          size === "small" ? "p-px" : "p-1",
          "rounded inline-flex cursor-pointer",
          isError ? "bg-zyda-red-300" : "bg-gray-300"
        )}
        data-testid={testId}
      >
        <span
          className={cx(
            itemClassName[size],
            value === "english" ? "bg-white" : isError && "text-white"
          )}
          onClick={() => change("english")}
          data-testid="language-en"
        >
          English
        </span>
        <span
          className={cx(
            itemClassName[size],
            value === "arabic" ? "bg-white" : isError && "text-white"
          )}
          onClick={() => change("arabic")}
          data-testid="language-ar"
        >
          عربي
        </span>
      </div>
      {/* {isError && <Error>{isError}</Error>} */}
    </div>
  )
}
