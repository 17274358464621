import React from "react"
import * as notifications from "./notifications"
import * as user from "./user"
import * as locale from "./locale"
import * as modal from "./modal"
import * as newOrders from "./new-orders"
import * as customers from "./customers"

export default ({ children }) => (
  <user.Provider>
    <locale.Provider>
      <notifications.Provider>
        <modal.Provider>
          <newOrders.Provider>
            <customers.Provider>{children}</customers.Provider>
          </newOrders.Provider>
        </modal.Provider>
      </notifications.Provider>
    </locale.Provider>
  </user.Provider>
)
