export const getToken = () => localStorage.getItem("token")
export const isAuthenticated = () => !!getToken()
export const getUserId = () => localStorage.getItem("user_id")
export const signIn = (token, userId) => {
  localStorage.setItem("token", token)
  localStorage.setItem("user_id", userId)
}
export const signOut = () => {
  localStorage.removeItem("token")
  localStorage.removeItem("user_id")
}

export const DASHBOARD_LAYOUT = { RETAIL: "retail", RESTAURANT: "restaurant" }
