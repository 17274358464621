import React, { useContext, useState, useEffect } from 'react';

import moment from 'moment';
import { useQuery } from '@apollo/client';

import { LinkDiagonalIcon } from '@zydadesign/zac-icons-react';
import { useSelectedStore, useReplaceParams, useQueryState, useMobile } from 'hooks';
import { breadcrumbs } from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { Link } from 'components/service';
import { Button, More, Modal, Spinner, DropDown, LoadingContainer, Tabs } from 'components/kit';
import { ReactComponent as AddIcon } from 'assets/add.svg';
import { Table, TableFooter } from 'components/form/elements';
import * as paths from 'paths.js';
import * as schemas from './schemas';
import cx from 'classnames';
import VouchersTableRow from './VouchersTableRow';
import EmptyState from '../EmptyState';
import { context as notificationsContext } from 'context/notifications';
import { VOUCHERS_LINKS } from 'constants/helperLinks';
import { copyToClipboard } from 'pages/setup/integrations/utils';
import { getStoreURl } from 'components/common/dashboard/Layout/utils';

const VouchersTable = ({ status }) => {
  const selectedStoreId = useSelectedStore();
  const { direction, lang } = useContext(localeContext);
  const [query, setQuery] = useQueryState(
    {
      page: 1,
    },
    {
      page: parseInt,
    },
  );

  const LIMIT = 10;

  useEffect(() => {
    setQuery({ page: 1 });
  }, [status]);

  const vouchersVariables = {
    storeId: selectedStoreId,
    status: status,
    page: query.page,
    limit: LIMIT,
  };

  const { data, loading } = useQuery(schemas.VOUCHERS, {
    fetchPolicy: 'network-only',
    variables: vouchersVariables,
  });

  moment.locale(lang === 'ar' ? 'ar-kw' : 'en-gb');

  const listData = !data?.vouchersList?.vouchers
    ? []
    : data?.vouchersList?.vouchers.map(item => {
        return {
          id: item.id,
          code: item.code,
          voucherType: item.type,
          codeUsage: (
            <>
              <span className={cx(item.voucherRedemptionsCount === item.redemptionLimit && 'text-gray-700')}>
                {item.voucherRedemptionsCount}
              </span>
              <span className="mx-1 text-gray-700">/</span>
              <span className="text-gray-700">
                {item.redemptionLimit === 0 ? (
                  <Text value={translations.UNLIMITED} className="inline text-xs" />
                ) : (
                  item.redemptionLimit
                )}
              </span>
            </>
          ),
          validFrom: item.validFrom,
          validTo: item.validUntil,
          description: item.description,
          descriptionAr: item.descriptionAr,
          status: item.status,
          publish: item.isShowcased,
          expired: item.expired,
          active: item.active,
          voucherRedemptionsCount: item.voucherRedemptionsCount,
          redemptionLimit: item.redemptionLimit,
          amount: item.amount,
          minSubtotalAmount: item.minSubtotalAmount,
          validTimeFrom: item.validTimeFrom,
          validTimeTo: item.validTimeTo,
          redemptionLimitPerUser: item.redemptionLimitPerUser,
          isFreeDelivery: item.isFreeDelivery,
        };
      });

  return (
    <Layout
      top={
        <Breadcrumbs
          helperLinks={VOUCHERS_LINKS}
          path={breadcrumbs.MARKETING_VOUCHERS}
          right={
            <Link to={paths.createVoucher}>
              <Button kind="primary">
                <AddIcon className={cx(direction === 'ltr' ? 'mr-2' : 'ml-2')} />
                <Text value={translations.ADD_VOUCHER} />
              </Button>
            </Link>
          }
        />
      }
    >
      <div style={{ direction }}>
        {!loading && status === 'all' && !data?.vouchersList?.vouchers.length ? (
          <EmptyState />
        ) : (
          <div className={'pb-32'}>
            <div>
              <div className="mb-4">
                <Tabs
                  scrollable
                  items={[
                    {
                      title: <Text value={translations.ALL} className="mx-3" />,
                      to: paths.allVouchers,
                    },
                    {
                      title: <Text value={translations.ACTIVE} className="mx-3" />,
                      to: paths.activeVouchers,
                    },
                    {
                      title: <Text value={translations.DEACTIVATED} className="mx-3" />,
                      to: paths.deactivatedVouchers,
                    },
                    {
                      title: <Text value={translations.SCHEDULED_VOUCHER} className="mx-3" />,
                      to: paths.scheduleVouchers,
                    },
                    {
                      title: <Text value={translations.EXPIRED} className="mx-3" />,
                      to: paths.expiredVouchers,
                    },
                  ]}
                />
              </div>
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <div className="w-full">
                    <Table
                      dataHeader={[
                        {
                          key: 'code',
                          name: <Text value={translations.CODE_VOUCHER} />,
                          inView: false,
                        },
                        {
                          key: 'voucherType',
                          name: <Text value={translations.VOUCHER_TYPE} />,
                          inView: false,
                        },
                        {
                          key: 'codeUsage',
                          name: <Text value={translations.CODE_USAGE} />,
                          inView: false,
                        },
                        {
                          key: 'validFrom',
                          name: <Text value={translations.VALID_FROM} />,
                          inView: false,
                        },
                        {
                          key: 'validTo',
                          name: <Text value={translations.VALID_TO} />,
                          inView: false,
                        },
                        {
                          key: 'status',
                          name: <Text value={translations.STATUS_VOUCHER} />,
                          inView: false,
                        },
                        {
                          key: 'publish',
                          name: <Text value={translations.PUBLISH} />,
                          inView: false,
                        },
                      ]}
                      dataBody={listData}
                      customCol={(item, index) => (
                        <FirstCol key={index} value={item.code} index={index} totalCount={listData.length} />
                      )}
                      customRow={(item, index) => (
                        <VouchersTableRow
                          key={index}
                          item={item}
                          index={index}
                          totalCount={listData.length}
                          vouchersVariables={vouchersVariables}
                          storeId={selectedStoreId}
                        />
                      )}
                      footer={
                        <TableFooter
                          totalRecords={data?.vouchersList?.totalCount}
                          pageLimit={LIMIT}
                          currentPage={query.page}
                          onPageChanged={({ currentPage }) => setQuery({ page: currentPage })}
                          totalCount={listData.length}
                        />
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default VouchersTable;

const FirstCol = ({ value, index, totalCount }) => {
  const [showCopy, setShowCopy] = useState(false);
  const { lang } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const storeUrl = getStoreURl(selectedStore);

  const isMobile = useMobile();
  const cellClassName = cx(
    'px-4 border-b border-gray-300 truncate w-68',
    totalCount <= 2 ? (totalCount - 1 > index ? 'py-5' : 'pt-5 pb-40') : 'py-5',
  );
  const maxWidth = isMobile ? '10rem' : '20rem';
  const handleCopyLink = () => {
    copyToClipboard(`${storeUrl}/?voucher=${value}`);
    notifications.show(<Text value={translations.VOUCHER_CODE_COPIED} />, 'success');
  };

  return (
    <tr>
      <td className={cx(cellClassName)} onMouseEnter={() => setShowCopy(true)} onMouseLeave={() => setShowCopy(false)}>
        <div className="w-full flex flex-row items-center justify-between truncate">
          <div className="flex flex-row">{value}</div>
          {showCopy && (
            <div
              aria-hidden
              onClick={handleCopyLink}
              className={cx(
                'flex flex-row items-center cursor-pointer w-1/2 truncate',
                lang === 'ar' ? 'mr-3' : 'ml-3',
              )}
            >
              <LinkDiagonalIcon color="#234cfb" width="20" />
              <Text
                className={cx('text-primary-base truncate	text-xs', lang === 'ar' ? 'mr-1' : 'ml-1')}
                value={translations.COPY_LINK}
              />
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};
