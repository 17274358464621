import React, { useState, useEffect, useContext, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import moment from 'moment';
import cx from 'classnames';
import { shallowEqual } from 'recompose';
import PropTypes from 'prop-types';

import { useSelectedStore } from 'hooks/index';
import { Field, Text } from 'components/service';
import { Button } from 'components/kit';
import { Label, Container, Row } from 'components/form/generic';
import * as translations from 'constants/translations';
import { context as notificationsContext } from 'context/notifications';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { Stepper, RadioList, DatePicker, TimePicker, TabsCheckList } from 'components/form/elements';
import { calcBusyTime } from 'components/common/orders/Frame/utils';
import UPDATE_BRANCH from './schemas';
import {
  handleErrorMessages,
  handleInitialValues,
  BUSY_TYPES,
  DELIVERY_BUSY_MODE,
  TIME_FORMAT,
  handleCompleteMutation,
} from './utils';

const SetAsBusy = ({ branchId, onCancel, setIsBusy, busyModeTranslated }) => {
  const { selectedStore, branches, setBranches } = useContext(userContext);
  const selectedBranch = branches.find(branch => branch.id === branchId);
  const [busyTime, setBusyTime] = useState(0);
  const { lang, direction } = useContext(localeContext);
  const storeId = useSelectedStore();
  const notifications = useContext(notificationsContext);
  const formikRef = useRef();
  const now = moment();

  const { timeZone } = selectedStore || {};
  const { busyMode, deliveryOrdersEnabled, pickupOrdersEnabled } = selectedBranch || {};

  useEffect(() => {
    const branchBusyTime = calcBusyTime(selectedBranch, timeZone);
    setBusyTime(branchBusyTime);
  }, [branchId]);

  const [updateBranch, { loading: isUpdatingBranch }] = useMutation(UPDATE_BRANCH, {
    onCompleted: data =>
      handleCompleteMutation(
        data,
        branches,
        branchId,
        setBranches,
        setIsBusy,
        setBusyTime,
        onCancel,
        timeZone,
        notifications,
        selectedBranch,
        lang,
      ),
    onError: err => handleErrorMessages(err, notifications, formikRef),
  });

  const initialValues = handleInitialValues(
    busyTime,
    timeZone,
    now,
    busyMode,
    deliveryOrdersEnabled,
    pickupOrdersEnabled,
  );

  const isBusyTimeArray = Array.isArray(busyTime);

  return (
    <Formik
      ref={formikRef}
      initialValues={initialValues}
      onSubmit={async (data, { setSubmitting }) => {
        let busyUntil = null;
        let busyFrom = null;
        if (data.busyType === BUSY_TYPES.DURATION) {
          if (!data.busyUntilDuration[0] || data.busyUntilDuration[0] <= 0) {
            notifications.show(<Text value={translations.AMOUNT_MORE_THAN_0} />, 'error');
            setSubmitting(false);
            return;
          }
          const allMins =
            data.busyUntilDuration[1] === TIME_FORMAT.HRS ? data.busyUntilDuration[0] * 60 : data.busyUntilDuration[0];
          const hours = Math.floor(allMins / 60);
          const mins = allMins % 60;
          busyUntil = moment()
            .startOf(TIME_FORMAT.MINUTE)
            .add(hours, TIME_FORMAT.HOURS)
            .add(mins, TIME_FORMAT.MINUTES);
        } else {
          busyFrom = moment(`${data.busyFromDate} ${data.busyFromTime}`, 'YYYY-MM-DD HH:mm A')
            .tz(timeZone, true)
            .startOf(TIME_FORMAT.MINUTE)
            .format();
          busyUntil = moment(`${data.busyUntilDate} ${data.busyUntilTime}`, 'YYYY-MM-DD HH:mm A')
            .tz(timeZone, true)
            .startOf(TIME_FORMAT.MINUTE)
            .format();
        }

        await updateBranch({
          variables: {
            storeId,
            id: branchId,
            busyUntil,
            busyFrom,
            busyMode: data.busyMode,
            busy: true,
          },
        });
      }}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <div className="px-4">
            <Container>
              {!!busyTime && (
                <Row className="bg-gray-100 px-4 py-3">
                  <div className={cx('flex justify-between items-center', lang === 'ar' && 'flex-row-reverse')}>
                    <div className="flex flex-col text-sm">
                      <span className="text-gray-1200 font-normal">
                        <Text
                          prefix={busyModeTranslated}
                          value={isBusyTimeArray ? translations.WILL_SET_TO_BUSY : translations.IS_BUSY_UNTIL}
                        />
                      </span>
                      <span className="font-medium">
                        {Array.isArray(busyTime)
                          ? `${busyTime[0].locale(lang === 'ar' ? 'ar' : 'en-gb').format(TIME_FORMAT.DAY_MONTH_FORMAT)}
                        - ${busyTime[1].locale(lang === 'ar' ? 'ar' : 'en-gb').format(TIME_FORMAT.DAY_MONTH_FORMAT)}
                        `
                          : `${busyTime.locale(lang === 'ar' ? 'ar' : 'en-gb').format(TIME_FORMAT.DAY_MONTH_FORMAT)}
                        `}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="h-5 text-xs text-primary-base"
                      onClick={() => {
                        updateBranch({
                          variables: {
                            storeId,
                            id: branchId,
                            busy: false,
                            busyMode: null,
                          },
                        });
                      }}
                      disabled={isUpdatingBranch || isSubmitting}
                    >
                      <Text value={isBusyTimeArray ? translations.REMOVE : translations.SET_AS_AVAILABLE} />
                    </button>
                  </div>
                </Row>
              )}
              <Row>
                <Field
                  name="busyMode"
                  key="busyMode"
                  items={[
                    {
                      title: <Text value={translations.DELIVERY} />,
                      value: DELIVERY_BUSY_MODE.DELIVERY,
                      disabled: !deliveryOrdersEnabled,
                    },
                    {
                      title: <Text value={translations.PICKUP_SHORT} />,
                      value: DELIVERY_BUSY_MODE.PICKUP,
                      disabled: !pickupOrdersEnabled,
                    },
                    {
                      title: <Text value={translations.BOTH} />,
                      value: DELIVERY_BUSY_MODE.BOTH,
                      disabled: !deliveryOrdersEnabled || !pickupOrdersEnabled,
                    },
                  ]}
                  component={TabsCheckList}
                />
              </Row>
              <Field
                name="busyType"
                component={RadioList}
                elementStyle={{
                  direction,
                }}
                items={[
                  {
                    value: BUSY_TYPES.DURATION,
                    className: 'mb-5',
                    body: (
                      <>
                        <Row className="mb-2">
                          <Label title={<Text value={translations.SET_AS_BUSY_FOR} />} />
                        </Row>
                        <Row className={cx(lang === 'ar' ? '-mr-8' : '-ml-8')}>
                          <Field
                            testId_openDropDown="select-time-option"
                            testId_selectedData="default-time-option"
                            testId_listData="time-option-list"
                            data-testid="enter-time-txt"
                            name="busyUntilDuration"
                            max={values.busyUntilDuration[1] === TIME_FORMAT.HRS ? 48 : 2880}
                            min={0}
                            step={1}
                            options={[
                              {
                                id: TIME_FORMAT.MINS,
                                title: <Text value={translations.MINUTES} />,
                              },
                              {
                                id: TIME_FORMAT.HRS,
                                title: <Text value={translations.HOURS} />,
                              },
                            ]}
                            dropdown
                            component={Stepper}
                          />
                        </Row>
                        <Row className={cx('mt-2', lang === 'ar' ? '-mr-7' : '-ml-7')}>
                          <Label
                            className={cx(lang === 'ar' ? '-mr-7' : '-ml-7')}
                            subtitle={<Text value={translations.MAX_TIME} />}
                          />
                        </Row>
                      </>
                    ),
                  },
                  {
                    value: BUSY_TYPES.DATETIME,
                    body: (
                      <>
                        <Label title={<Text value={translations.SCHEDULE_AS_BUSY_FOR} />} />
                        <Row className={cx('pt-2', lang === 'ar' ? '-mr-7' : '-ml-7')}>
                          <Label textSize="text-xs" title={<Text value={translations.FROM} />}>
                            <Field
                              key="busyFromDate"
                              name="busyFromDate"
                              minDate={new Date()}
                              component={DatePicker}
                              dateFormat={TIME_FORMAT.DATE_FORMAT}
                              containerClassName={`mb-4 ${lang === 'ar' ? 'ml-4' : 'mr-4'}`}
                              allowFutureDates
                            />
                            <Field
                              name="busyFromTime"
                              key="busyFromTime"
                              minDate={new Date()}
                              component={TimePicker}
                              dateTimeClassName="placement-down"
                            />
                          </Label>
                        </Row>
                        <Row className={cx(lang === 'ar' ? '-mr-7' : '-ml-7')}>
                          <Label textSize="text-xs" title={<Text value={translations.TO} />}>
                            <Field
                              name="busyUntilDate"
                              key="busyUntilDate"
                              minDate={new Date()}
                              component={DatePicker}
                              dateFormat={TIME_FORMAT.DATE_FORMAT}
                              containerClassName={`mb-2 ${lang === 'ar' ? 'ml-4' : 'mr-4'}`}
                              allowFutureDates
                            />
                            <Field
                              name="busyUntilTime"
                              key="busyUntilTime"
                              minDate={new Date()}
                              component={TimePicker}
                              dateTimeClassName="placement-down"
                            />
                          </Label>
                        </Row>
                      </>
                    ),
                  },
                ]}
              />
            </Container>
          </div>
          <div className="ml-auto">
            <div className="w-full flex items-center mt-4 mb-3 px-4">
              <Button
                data-testid="busy-set-btn"
                isSpinning={isSubmitting}
                kind="secondaryError"
                disabled={isUpdatingBranch || isSubmitting || (!!busyTime && shallowEqual(initialValues, values))}
                full
                borderColor="border-zyda-grey-100"
              >
                <Text value={busyTime ? translations.SAVE_CHANGE : translations.SET_AS_BUSY} />
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SetAsBusy.propTypes = {
  branchId: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  setIsBusy: PropTypes.func.isRequired,
  busyModeTranslated: PropTypes.string,
};

SetAsBusy.defaultProps = {
  branchId: '',
  busyModeTranslated: '',
};
export default SetAsBusy;
