import React, { useState, useContext } from "react"
import axios from "axios"
import { useQuery, useMutation } from "@apollo/client"
import { Field, Text } from "components/service"
import { useSelectedStore } from "hooks/index"
import { Formik, Form } from "formik"
import * as translations from "constants/translations"
import { breadcrumbs } from "constants/translations"
import {
  Label,
  Row,
  Footer,
  LangSwitch,
  Container,
  TogglesWrap,
  Section,
} from "components/form/generic"
import { Button, Toggle, Spinner, Uploader } from "components/kit"
import { Input, ColorPicker, CheckboxAlt } from "components/form/elements"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import * as paths from "paths.js"
import * as data from "./data"
import * as schemas from "./schemas"
import { uploadStoreImages } from "rest.js"
import { context as localeContext } from "context/locale"
import { context as notificationsContext } from "context/notifications"
import cx from "classnames"
import { BRANDING_LINKS } from "constants/helperLinks"

const logoTokenSource = axios.CancelToken.source()
const headerTokenSource = axios.CancelToken.source()

export default () => {
  const [isCoverVisible, setCoverVisible] = useState()
  const storeId = useSelectedStore()
  const store = useQuery(schemas.FETCH_STORE, {
    variables: { storeId },
  })
  const [updateBranding] = useMutation(schemas.UPDATE)
  const { lang, direction } = useContext(localeContext)
  const notifications = useContext(notificationsContext)

  return (
    <Layout
      top={
        <Breadcrumbs
          helperLinks={BRANDING_LINKS}
          path={breadcrumbs.SETUP_BRANDING}
        />
      }
    >
      <div
        className={cx("pb-6 flex", lang === "ar" && "flex-row-reverse")}
      >
        <div className="w-full xl:w-2/3" style={{ direction }}>
          {store.loading ? (
            <Spinner />
          ) : (
            <Formik
              initialValues={{
                ...store.data.restaurant,
                hideStoreName: store.data.settings.hideStoreName,
                headerColor:
                  store.data.settings.theme.palette.headerBackgroundColor ||
                  "#000",
                primaryColor:
                  store.data.settings.theme.palette.primaryColor || "#000",
              }}
              onSubmit={async (data, { setSubmitting, resetForm }) => {
                try {
                  await updateBranding({
                    variables: {
                      storeId: storeId.toString(),
                      ...data,
                    },
                  })
                  notifications.show(
                    <Text value={translations.STORE_INFORMATION_UPDATED} />
                  )
                } catch (error) {
                  notifications.show(
                    <Text value={translations.SOMETHING_WENT_WRONG} />,
                    "error"
                  )
                }

                resetForm(data)
                setSubmitting(false)
              }}
            >
              {({ values, isSubmitting, dirty }) => (
                <Form>
                  <Section
                    title={<Text value={translations.ESSENTIAL_DETAILS} />}
                  >
                    <Row>
                      <Label
                        title={<Text value={translations.IMAGE} />}
                        description={
                          <Text value={translations.DESC_UPLOAD_YOUR_LOGO} />
                        }
                      >
                        <Uploader
                          testId_uploadImg="uplaod-logo-image"
                          testId_BtnUpload="upload-logo-btn"
                          imageUrl={store.data.restaurant.logoUrl}
                          info={<Text value={translations.UPLOAD_DRAG} />}
                          justify="start"
                          type="wide"
                          direction="row"
                          onUpload={image =>
                            uploadStoreImages(
                              storeId,
                              { logo: image },
                              logoTokenSource.token
                            )
                          }
                          onCancel={() => logoTokenSource.cancel()}
                        />
                      </Label>
                    </Row>
                    <div>
                      <Text
                        value={translations.PAGE_TITLE}
                        className="text-base mb-2"
                      />

                      <Row className="flex-col md:flex-row">
                        <div className="flex flex-col md:flex-row justify-between items-start">
                          <div
                            className={cx(
                              "w-full mb-6 md:mb-0",
                              lang === "en" ? "mr-0 md:mr-4" : "ml-0 md:ml-4"
                            )}
                          >
                            <Label
                              title={<Text value={translations.ENGLISH} />}
                              testIdSwitchLangEN="page-switch-en"
                              testIdSwitchLangAR="page-switch-ar"
                              testIdswitchLang="page-switch-lang"
                              subtitle={
                                <Text
                                  value={translations.DESC_ENGLISH_STORE_NAME}
                                />
                              }
                            >
                              <Field
                                type="text"
                                name="pageTitleEn"
                                component={Input}
                                data-testid="page-title-en"
                              />
                            </Label>
                          </div>
                          <div className="w-full mb-0 md:mb-0">
                            <Label
                              title={<Text value={translations.ARABIC} />}
                              testIdSwitchLangEN="page-switch-en"
                              testIdSwitchLangAR="page-switch-ar"
                              testIdswitchLang="page-switch-lang"
                              subtitle={
                                <Text
                                  value={translations.DESC_ARABIC_STORE_NAME}
                                />
                              }
                            >
                              <Field
                                direction="rtl"
                                type="text"
                                name="pageTitleAr"
                                component={Input}
                                data-testid="page-title-ar"
                              />
                            </Label>
                          </div>
                        </div>
                      </Row>
                    </div>
                    <Row>
                      <Field
                        title={<Text value={translations.HIDE_STORE_NAME} />}
                        name="hideStoreName"
                        component={CheckboxAlt}
                        testId="hide-store-info"
                      />
                    </Row>
                    <div>
                      <Text
                        value={translations.SLOGAN}
                        className="text-base mb-2"
                      />
                      <Row className="flex-col md:flex-row">
                        <div className="flex flex-col md:flex-row justify-between items-start">
                          <div
                            className={cx(
                              "w-full mb-6 md:mb-0",
                              lang === "en" ? "mr-0 md:mr-4" : "ml-0 md:ml-4"
                            )}
                          >
                            <Label
                              title={<Text value={translations.ENGLISH} />}
                              testIdSwitchLangEN="slogan-switch-en"
                              testIdSwitchLangAR="slogan-switch-ar"
                              testIdswitchLang="slogan-switch-lang"
                            >
                              <Field
                                type="text"
                                name="descriptionEn"
                                component={Input}
                                data-testid="slogan-en"
                              />
                            </Label>
                          </div>
                          <div className="w-full mb-0 md:mb-0">
                            <Label
                              title={<Text value={translations.ARABIC} />}
                              testIdSwitchLangEN="slogan-switch-en"
                              testIdSwitchLangAR="slogan-switch-ar"
                              testIdswitchLang="slogan-switch-lang"
                            >
                              <Field
                                direction="rtl"
                                type="text"
                                name="descriptionAr"
                                component={Input}
                                data-testid="slogan-ar"
                              />
                            </Label>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Section>
                  <Section title={<Text value={translations.STORE_THEME} />}>
                    <Row>
                      <Label
                        htmlFor="primaryColor"
                        title={<Text value={translations.PRIMARY_COLOR} />}
                        subtitle={
                          <Text value={translations.PICK_PRIMARY_COLOR} />
                        }
                      >
                        <Field name="primaryColor" component={ColorPicker} />
                      </Label>
                      {(values.photoUrl || isCoverVisible) && (
                        <Label
                          htmlFor="headerCover"
                          title={<Text value={translations.HEADER_COVER} />}
                          subtitle={
                            <Text value={translations.UPLOAD_HEADER_COVER} />
                          }
                        >
                          <Uploader
                            testId_uploadImg="upload-cover-image"
                            testId_BtnUpload="upload-cover-btn"
                            imageUrl={store.data.restaurant.photoUrl}
                            size="small"
                            direction="row"
                            info={<Text value={translations.UPLOAD_DRAG} />}
                            justify="start"
                            onUpload={image =>
                              uploadStoreImages(
                                storeId,
                                { header: image },
                                headerTokenSource.token
                              )
                            }
                            onCancel={() => headerTokenSource.cancel()}
                          />
                        </Label>
                      )}
                    </Row>
                    {!values.photoUrl && !isCoverVisible && (
                      <button
                        className="text-primary-base"
                        onClick={() => setCoverVisible(true)}
                      >
                        <Text value={translations.ADD_COVER} />
                      </button>
                    )}
                  </Section>
                  <Footer>
                    <Button
                      kind="primary"
                      data-testid="branding-save-btn"
                      isSpinning={isSubmitting}
                    >
                      <Text value={translations.SAVE_CHANGE} />
                    </Button>
                  </Footer>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </Layout>
  )
}
