import React, { useContext, useState } from 'react';
import cx from 'classnames';
import { useMutation, useQuery } from '@apollo/client';
import { Volume2Icon } from '@zydadesign/zac-icons-react';

import { context as userContext } from 'context/user';
import Spinner from 'components/kit/VariantImageUploader/Spinner';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { Button, Modal } from 'components/kit';
import { ReactComponent as IconInfo } from 'assets/icons-info.svg';
import { RadioList } from 'components/form/elements';
import { useSelectedStore } from 'hooks';
import { context as notificationsContext } from 'context/notifications';
import { ORDER_RING_OPTIONS } from 'constants/orderRingerEnums';
import { UPDATE_RINGER, USER_RING } from './schema';

const ringingOptions = [
  {
    value: ORDER_RING_OPTIONS.CONTINUOUS,
    title: <Text value={translations.RINGER_CONTINUOUS} />,
    subTitle: <Text value={translations.RINGER_CONTINUOUS_NOTE} />,
    className: 'text-sm font-medium text-black',
  },
  {
    value: ORDER_RING_OPTIONS.SINGLE,
    title: <Text value={translations.RINGER_SINGLE} />,
    subTitle: <Text value={translations.RINGER_SINGLE_NOTE} />,
    className: 'text-sm font-medium text-black',
  },
];

const OrderRinger = () => {
  const notifications = useContext(notificationsContext);
  const { translate, direction, lang } = useContext(localeContext);
  const { user } = useContext(userContext);
  const storeId = useSelectedStore();
  const [ringType, setRingType] = useState();

  const { loading: loadingData } = useQuery(USER_RING, {
    variables: {
      storeId,
      id: user.id,
    },
    onCompleted: data => {
      const { storeRingerSetting: { ringerType } = ORDER_RING_OPTIONS.SINGLE || {} } = data?.user || {};
      setRingType(ringerType);
      localStorage.setItem('ringer_type', ringerType);
    },
  });

  const [updateRinger, { loading: isStillUpdating }] = useMutation(UPDATE_RINGER, {
    variables: {
      storeId,
      userId: user.id,
      ringerType: ringType === ORDER_RING_OPTIONS.SINGLE ? 0 : 1,
    },
    onCompleted: ({ userUpdateSelf }) => {
      const { storeRingerSetting: { ringerType } = {} } = userUpdateSelf || {};
      if (ringerType) {
        setRingType(
          ringerType.toLowerCase() === ORDER_RING_OPTIONS.SINGLE ? ringingOptions[1].value : ringingOptions[0].value,
        );
        localStorage.setItem('ringer_type', ringerType);
        notifications.show(<Text value={translations.USER_RING_FREQUENCY_UPDATED} />);
      }
    },
    onError: () => notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error'),
  });

  return (
    <Modal>
      {({ open, close }) =>
        loadingData ? (
          <Spinner />
        ) : (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => null}
            className="cursor-pointer hover:border-primary-base"
            onClick={() =>
              open({
                size: 'max-w-sm',
                title: (
                  <div>
                    <Text className="font-semibold text-base" value={translations.RING_TONE} />
                    <Text className="font-medium text-xs text-gray-500" value={translations.RINGER_SUB_HEAD} />
                  </div>
                ),
                body: (
                  <div>
                    <div className="mt-6 mb-0 mx-4 w-90p">
                      <div className="flex flex-col items-center">
                        <RadioList
                          items={ringingOptions}
                          value={
                            ringType.toLowerCase() === ORDER_RING_OPTIONS.SINGLE
                              ? ringingOptions[1].value
                              : ringingOptions[0].value
                          }
                          onChange={option => {
                            if (option.target.value) {
                              setRingType(option.target.value);
                            }
                          }}
                          elementClassName="px-0 py-1"
                          elementStyle={{
                            direction,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                          containerStyle={{
                            marginBottom: '1.50rem',
                            minWidth: '100%',
                            paddingLeft: '0',
                          }}
                          selectedTitleColor="text-black"
                          ringer
                        />
                        <div className="flex">
                          <IconInfo width="16px" height="16px" />
                          <Text
                            className={cx(
                              'text-xs text-zyda-grey-150 items-center leading-tight mb-6',
                              direction === 'rtl' ? 'mr-2' : 'ml-2',
                            )}
                            value={translations.RINGER_TIP}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="border-t border-zyda-grey-50">
                      <div className="mt-4 mb-0 mx-4 w-90p">
                        <div className="flex justify-end w-full">
                          <Button
                            role="button"
                            kind="primary"
                            onClick={() => {
                              updateRinger();
                              close();
                            }}
                            isSpinning={isStillUpdating}
                            disabled={isStillUpdating}
                            data-testid="order-ring-save"
                          >
                            <p className="text-sm font-semibold text-white">{translate(translations.SAVE_CHANGE)}</p>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              })
            }
          >
            <div
              className={cx(
                'flex flex-row rounded cursor-pointer hover:bg-gray-200 items-center p-2 my-1',
                lang === 'ar' && 'flex-row-reverse',
              )}
            >
              <div className={cx('flex items-center justify-between h-full', lang === 'ar' ? 'pl-2' : 'pr-2')}>
                <Volume2Icon color="#373E5A" width="20" />
              </div>
              <div className="flex items-center justify-center leading-5 h-full">
                <Text className="text-primary-nav text-sm" full={false} value={translations.RING_TONE} />
              </div>
            </div>
          </div>
        )
      }
    </Modal>
  );
};

export default OrderRinger;
