import { gql } from '@apollo/client';

export const GENERATE_SALES_REPORT = gql`
  mutation SalesReportExport($storeId: String!) {
    salesReportExport(restaurantId: $storeId)
  }
`;

export const ORDERS = gql`
  query Orders(
    $storeId: String!
    $status: OrderStatus
    $paymentStatuses: [OrderPaymentStatus]
    $paymentMethod: [PaymentMethod]
    $deliveryType: [DeliveryType]
    $isManualOrder: Boolean
    $areas: [String]
    $branchId: String!
    $submittedAt: [String]
    $phone: String
    $number: String
    $sort: OrderSorter
    $statuses: [OrderStatus]
    $voucherCode: String
    $deliveryZoneIn: [String]
    # New args
    $searchValue: String
    $paginateFrom: Int
  ) {
    orders(
      restaurantId: $storeId
      status: $status
      statuses: $statuses
      paymentStatuses: $paymentStatuses
      paginateFrom: $paginateFrom
      searchValue: $searchValue
      filters: {
        branch_id: $branchId
        submitted_at: $submittedAt
        phone_number: [$phone]
        number: [$number]
        payment_methods: $paymentMethod
        delivery_type: $deliveryType
        is_manual_order: $isManualOrder
        areas: $areas
        voucher_code: $voucherCode
        delivery_zone_in: $deliveryZoneIn
      }
      sorter: $sort
    ) {
      orders {
        id
        number
        isScheduled
        expectedAt
        firingTime
        isManualOrder
        customerName
        areaNameEn
        areaNameAr
        customerPhoneNumber
        updatingStatus {
          orderGettingUpdated
          nextStatus
        }
        timeSlot
        deliveryType
        branchData {
          titleAr
          titleEn
          lat
          lng
          id
        }
        branchName
        deliveryZone {
          zoneName
        }
        paidThrough
        status
        paymentStatus
        total
        createdAt
        submittedAt
        deliveryCourierId
        deliveryCourierName
        deliveryCourier {
          id
          driverName
          hasDriverInfo
          driverPhoneNumber
          driverAssigned
          trackingLink
          referenceId
          externalOrderIdentifierLink
          externalOrderIdentifierType
        }
        stateHistories {
          state
          createdAt
          actionBy
        }
        gift
        inBetweenTransitions
        partnerErrors
        cashbackAmount
      }
      totalCount
      statusCount {
        ready
        dispatched
        canceled
        accepted
        submitted
        delivered
        fulfilled
        all
        paid
        paymentFailed
        paymentExpired
        waitingForPayment
        redirectUrl
      }
    }
  }
`;
export const GET_PAYMENT_METHODS = gql`
  query paymentMethods($restaurantId: Int!) {
    paymentMethods(restaurantId: $restaurantId) {
      paymentMethods
    }
  }
`;

export const RESTAURANT_AREAS = gql`
  query restaurantAreas($restaurantId: Int!, $branchId: Int) {
    restaurantAreas(restaurantId: $restaurantId, branchId: $branchId) {
      id
      titleEn
      titleAr
    }
  }
`;
