import React from 'react';
import { WalletIcon } from '@zydadesign/zac-icons-react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as DeliveryCard } from 'assets/card-delivery.svg';
import { ReactComponent as CreditCard } from 'assets/credit-card.svg';
import { ReactComponent as Cash } from 'assets/cash.svg';
import { ReactComponent as Knet } from 'assets/knet.svg';
import { ReactComponent as ApplePay } from 'assets/apple-pay.svg';
import { ReactComponent as Mada } from 'assets/mada.svg';
import { ReactComponent as Meeza } from 'assets/meeza-icon.svg';
import fawry from 'assets/fawry.png';
import benefit from 'assets/benefit.png';
import { PAYMENT_METHODS_ENUMS } from 'components/common/orders/Frame/utils';

export const PaidMethodsIcon = ({ paidThrough, width = '24', height = '16', lang }) => {
  switch (paidThrough) {
    case PAYMENT_METHODS_ENUMS.BENEFIT:
      return <img src={benefit} width={width} height={height} />;
    case PAYMENT_METHODS_ENUMS.CASH:
      return <Cash />;
    case PAYMENT_METHODS_ENUMS.CARD_ON_DELIVERY:
      return <DeliveryCard />;
    case PAYMENT_METHODS_ENUMS.CREDIT_CARD:
    case PAYMENT_METHODS_ENUMS.WALLET_AND_CREDIT_CARD:
      return <CreditCard />;
    case PAYMENT_METHODS_ENUMS.MEEZA:
      return <Meeza />;
    case PAYMENT_METHODS_ENUMS.KNET:
      return <Knet />;
    case PAYMENT_METHODS_ENUMS.APPLE_PAY:
      return <ApplePay />;
    case PAYMENT_METHODS_ENUMS.MADA:
      return <Mada />;
    case PAYMENT_METHODS_ENUMS.WALLET:
      return (
        <span
          className={cx(
            lang === 'ar' ? 'ml-2' : 'mr-2',
            'rounded-full bg-primary-50 w-8 h-8 flex items-center justify-center',
          )}
        >
          <span className="h-5">
            <WalletIcon width="20px" height="20px" />
          </span>
        </span>
      );

    case PAYMENT_METHODS_ENUMS.FAWRY:
      return <img src={fawry} width={width} height={height} />;
    default:
      return '';
  }
};

PaidMethodsIcon.propTypes = {
  paidThrough: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  lang: PropTypes.string,
};
