import React from 'react';
import * as yup from 'yup';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

const validationSchema = yup.object().shape({
  name: yup.string().required(<Text value={translations.FULL_NAME_IS_REQ} />),
  email: yup
    .string()
    .email(<Text value={translations.INVALID_EMAIL_FORMAT} />)
    .nullable(),
  phone: yup.string().required(<Text value={translations.PHONE_IS_REQ} />),
  jobType: yup.string().required(<Text value={translations.JOB_TYPE_IS_REQ} />),
  language: yup.string().required(<Text value={translations.LANGUAGE_IS_REQ} />),
  branches: yup.array().of(yup.string()),
});

export default validationSchema;
