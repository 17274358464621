import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import cx from 'classnames';

import { Button, DropDown, SpinnerAlt } from 'components/kit';
import { THIRD_PARTY_TYPE_ENUM } from 'utils/enums';
import { ORDER_STATUS } from 'constants/order';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { useSelectedStore } from 'hooks';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import { useFetchVerdDrivers, useAssignDriverToOrder } from 'service/hooks';
import updateDeliveryCourierCache from 'utils/updatingCache/updateDeliveryCourierCache';
import * as schemas from '../../../schemas';
import { DELIVERY_ENUMS } from '../../../constants';
import StoreRider from './StoreRider';
import StoreCourier from './StoreCourier';
import StoreRiderInfo from './StoreRiderInfo';
import StoreCourierInfo from './StoreCourierInfo';

export const OnFleetDeliveryActionButtons = ({
  order,
  isUpdatingStatus,
  shouldDisableActionBtns,
  sendChangeStatus,
  deliveryType,
  openOrderDeliveryConfirmationPopUp,
  isExpectedAtValid,
}) => {
  const { status: orderStatus, branchData } = order || {};
  const { lang, direction } = useContext(localeContext);
  const selectedStoreId = useSelectedStore();
  const isArabic = lang === 'ar';
  const { user } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const assignDriverToOrder = useAssignDriverToOrder();
  const {
    selectedStore: { restaurantCourierSetting },
    settings,
  } = useContext(userContext);
  const verd = restaurantCourierSetting?.find(courier => courier.isInternalDelivery);
  const isVerdEnabled = settings?.enableVerd;
  const filteredRestaurantCourierSetting = restaurantCourierSetting.filter(
    courier => !courier.isInternalDelivery && courier.businessType === THIRD_PARTY_TYPE_ENUM.Delivery,
  );

  const { data: activeRiders, mutate: mutateFetchVerdDrivers } = useFetchVerdDrivers(
    isVerdEnabled && {
      storeId: selectedStoreId,
      includeDeactivated: false,
      branchId: parseInt(branchData.id),
    },
  );

  const { data: storeCouriersList } = useQuery(schemas.LIST_COURIERS, {
    variables: {
      businessType: DELIVERY_ENUMS.DELIVERY,
    },
    skip: filteredRestaurantCourierSetting.length === 0,
  });

  // States
  const [isRiderInfoLoading, setIsRiderInfoLoading] = useState(false);
  const [isCourierInfoLoading, setIsCourierInfoLoading] = useState(false);

  // variables
  const orderDeliveryCourierId = order?.deliveryCourier?.courierId;
  const orderDeliveryPhoneNumber = order?.deliveryCourier?.driverPhoneNumber;
  const quickAssignCourierId = order?.deliveryCourierId;
  const isOrderDeliveryCourierNotVerd = quickAssignCourierId !== Number(verd?.courierId);
  const trackingLink = order?.deliveryCourier?.trackingLink;
  const deliveryOrderStatus = order?.deliveryCourier?.deliveryOrderStatus;
  const isScheduledOrder = order?.isScheduled;
  const firingTime = order?.firingTime;
  const referenceId = order?.deliveryCourier?.referenceId;
  const isInternalDelivery = order?.deliveryCourier?.isInternalDelivery;
  const courierDriverName = order?.deliveryCourier?.driverName;
  const hasCourierDriverInfo = order?.deliveryCourier?.hasDriverInfo;
  const courierDriverAssigned = order?.deliveryCourier?.driverAssigned;
  const quickAssignCourier = restaurantCourierSetting.find(
    courier => Number(courier.courierId) === quickAssignCourierId,
  );
  const riders = activeRiders?.length ? activeRiders.filter(rider => rider.onShift) : [];
  const numberOfAvaliableRiders = riders?.filter(rider => rider.assignedOrdersCount !== verd?.driverMaxCapacity)
    ?.length;
  const dropDownPlaceHolder =
    !isVerdEnabled && courierDriverName ? courierDriverName : <Text value={translations.SELECT_RIDER} />;
  const hasRiderInfo = orderDeliveryPhoneNumber && isInternalDelivery;
  const hasCourierInfo = orderDeliveryCourierId && isOrderDeliveryCourierNotVerd;
  const isCourierNotDeclinedTheRequest = hasCourierInfo && deliveryOrderStatus !== ORDER_RIDER_STATUS.DECLINED;
  const restaurantCouriersIds = storeCouriersList?.couriers.map(courier => courier.id);
  const numberOfCouriers = filteredRestaurantCourierSetting?.length;
  const isOrderDeliveredOrCancelled = orderStatus === ORDER_STATUS.DELIVERED || orderStatus === ORDER_STATUS.CANCELED;
  const isSubmittedOrder = orderStatus === ORDER_STATUS.SUBMITTED;
  const restaurantCouriersWithLogos = filteredRestaurantCourierSetting?.map(courier => {
    const normalizingCourierId = Number(courier.courierId);
    if (restaurantCouriersIds?.includes(normalizingCourierId)) {
      const matchedCourier = storeCouriersList?.couriers.find(
        targetCourier => targetCourier.id === normalizingCourierId,
      );
      return { ...courier, logo: matchedCourier?.logoUrl };
    }
    return courier;
  });
  const assignedDriverId = activeRiders?.find(driver => driver.phone === orderDeliveryPhoneNumber)?.id;
  const assignedCourierId = Number(
    filteredRestaurantCourierSetting?.find(courier => Number(courier.courierId) === orderDeliveryCourierId)?.courierId,
  );
  const handleUpdateCourierInCache = assignedCourier => {
    const updatedOrder = {
      ...order,
      deliveryCourier: {
        ...order.deliveryCourier,
        courierId: assignedCourier ? Number(assignedCourier?.courierId) : '',
        deliveryOrderStatus: assignedCourier ? ORDER_RIDER_STATUS.PENDING : '',
      },
    };
    const orderVariables = {
      orderId: order.id,
      storeId: selectedStoreId,
    };
    updateDeliveryCourierCache(orderVariables, updatedOrder);
  };

  const handleAssignCourierToOrder = async (assignedCourier, notifyUser, newExpectedAt) => {
    setIsCourierInfoLoading(true);
    const response = await assignDriverToOrder({
      driverId: -1,
      courierId: assignedCourier.courierId,
      orderNumber: order.number,
      userId: user?.id,
      ...(notifyUser && { notifyUser }),
      ...(newExpectedAt && { scheduledDeliveryAt: newExpectedAt }),
    });
    setIsCourierInfoLoading(false);
    if (response.hasError) {
      response.errors?.map(error => notifications.show(error.message, 'error'));
    } else {
      mutateFetchVerdDrivers();
      handleUpdateCourierInCache(assignedCourier);
    }
  };

  const handleUnAssignCourierToOrder = async () => {
    setIsCourierInfoLoading(true);
    const response = await assignDriverToOrder({ courierId: -1, orderNumber: order.number, userId: user?.id });
    setIsCourierInfoLoading(false);
    if (response.hasError) {
      response.errors?.map(error => notifications.show(error.message, 'error'));
    } else {
      mutateFetchVerdDrivers();
      handleUpdateCourierInCache();
    }
  };

  const accpetAndAssignAction = () => {
    sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false });
    handleAssignCourierToOrder(quickAssignCourier);
  };

  const showNextStatus = () => {
    switch (orderStatus) {
      case ORDER_STATUS.SUBMITTED:
        return (
          <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse')}>
            {assignedCourierId || !isOrderDeliveryCourierNotVerd || !quickAssignCourierId ? (
              <div>
                <Button
                  isSpinning={isUpdatingStatus}
                  disabled={shouldDisableActionBtns}
                  onClick={() => sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false })}
                  kind="primary"
                  size="base"
                  data-testid="accept-order-button"
                >
                  <Text value={translations.ACCEPT_ORDER} />
                </Button>
              </div>
            ) : (
              <>
                <div className={cx(isArabic ? 'ml-4' : 'mr-4')}>
                  <Button
                    isSpinning={isUpdatingStatus}
                    disabled={shouldDisableActionBtns}
                    onClick={() => sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false })}
                    kind="outline"
                    size="base"
                    data-testid="accept-order-button"
                  >
                    <Text value={translations.ACCEPT_ONLY_ORDER} />
                  </Button>
                </div>
                <div>
                  <Button
                    isSpinning={isUpdatingStatus}
                    disabled={shouldDisableActionBtns}
                    onClick={() => {
                      !isExpectedAtValid
                        ? openOrderDeliveryConfirmationPopUp({
                            assignDriverToOrder: handleAssignCourierToOrder,
                            assignee: quickAssignCourier,
                            acceptOrder: true,
                          })
                        : accpetAndAssignAction();
                    }}
                    kind="primary"
                    size="base"
                    data-testid="accept-order-button"
                  >
                    <Text value={translations.ACCEPT_AND_ASSIGN} />
                  </Button>
                </div>
              </>
            )}
          </div>
        );
      case ORDER_STATUS.ACCEPTED:
        return (
          <Button
            isSpinning={isUpdatingStatus}
            disabled={shouldDisableActionBtns}
            onClick={() => {
              sendChangeStatus({ status: ORDER_STATUS.READY, sendCourierId: false });
            }}
            kind="primary"
            size="base"
            data-testid="ready-order-button"
          >
            <Text value={translations.READY_ORDER} />
          </Button>
        );
      case ORDER_STATUS.READY:
        if (deliveryType === DELIVERY_BUSY_MODE.PICKUP || deliveryType === DELIVERY_BUSY_MODE.BEACH) {
          return (
            <Button
              isSpinning={isUpdatingStatus}
              disabled={shouldDisableActionBtns}
              onClick={() => {
                sendChangeStatus({ status: ORDER_STATUS.FULFILLED, sendCourierId: false });
              }}
              kind="primary"
              size="base"
              data-testid="fulfill-order-button"
            >
              <Text value={translations.FULFILL_ORDER} />
            </Button>
          );
        }
        return (
          <Button
            isSpinning={isUpdatingStatus}
            disabled={shouldDisableActionBtns}
            onClick={() => {
              sendChangeStatus({ status: ORDER_STATUS.DISPATCHED, sendCourierId: false });
            }}
            kind="primary"
            size="base"
            data-testid="dispatch-order-button"
          >
            <Text value={translations.DISPATCH_ORDER} />
          </Button>
        );

      case ORDER_STATUS.DISPATCHED:
        return (
          <Button
            isSpinning={isUpdatingStatus}
            disabled={shouldDisableActionBtns}
            onClick={() => {
              sendChangeStatus({ status: ORDER_STATUS.DELIVERED, sendCourierId: false });
            }}
            kind="primary"
            size="base"
            data-testid="deliver-order-button"
          >
            <Text value={translations.DELIVER_ORDER} />
          </Button>
        );
      case ORDER_STATUS.DELIVERED:
      default:
        return null;
    }
  };

  const ridersList = riders?.map(rider => ({
    id: rider.id,
    title: rider.name,
    body: onToggle => (
      <StoreRider
        rider={rider}
        order={order}
        verd={verd}
        isExpectedAtValid={isExpectedAtValid}
        isCourierNotDeclinedTheRequest={isCourierNotDeclinedTheRequest}
        openOrderDeliveryConfirmationPopUp={openOrderDeliveryConfirmationPopUp}
        setIsLoading={setIsRiderInfoLoading}
        assignedDriverId={assignedDriverId}
        mutateFetchVerdDrivers={mutateFetchVerdDrivers}
        onToggle={onToggle}
      />
    ),
  }));

  const couriersList = restaurantCouriersWithLogos?.map(courier => ({
    id: Number(courier.courierId),
    title: courier.courierDetails[isArabic ? 'displayNameAr' : 'displayNameEn'],
    body: onToggle => (
      <StoreCourier
        order={order}
        courier={courier}
        isExpectedAtValid={isExpectedAtValid}
        openOrderDeliveryConfirmationPopUp={openOrderDeliveryConfirmationPopUp}
        onToggle={onToggle}
        isCourierNotDeclinedTheRequest={isCourierNotDeclinedTheRequest}
        setIsLoading={setIsCourierInfoLoading}
        assignedCourierId={assignedCourierId}
        quickAssignCourierId={quickAssignCourierId}
        handleAssignCourierToOrder={handleAssignCourierToOrder}
        handleUnAssignCourierToOrder={handleUnAssignCourierToOrder}
      />
    ),
  }));

  const loadingIcon = (
    <div className="w-full flex justify-center mt-4">
      <span className={cx('w-4 h-4 ', direction === 'rtl' ? 'ml-2' : 'mr-2')}>
        <SpinnerAlt color="primary-base" />
      </span>
    </div>
  );

  return (
    <div className="flex flex-col justify-between w-1/2">
      <div className="flex flex-col">
        {!isOrderDeliveredOrCancelled && (
          <>
            <span className="text-sm font-semibold w-full mb-4">
              <Text value={translations.DELIVER_WITH} />
            </span>
            <DropDown
              testId_openDropDown="riders-open"
              testId_selectedData="riders-data"
              testId_listData="riders-list"
              float="left"
              firstTitle={
                isVerdEnabled && <Text value={translations.ACTIVE_RIDERS} payload={numberOfAvaliableRiders} />
              }
              secondTitle={
                filteredRestaurantCourierSetting.length > 0 && (
                  <Text value={translations.ACTIVE_COURIERS} payload={numberOfCouriers} />
                )
              }
              placeholder={dropDownPlaceHolder}
              optionSelected={
                assignedDriverId ||
                assignedCourierId ||
                (isSubmittedOrder && isOrderDeliveryCourierNotVerd && quickAssignCourierId)
              }
              data={ridersList || []}
              extraOptions={couriersList || []}
              icon="keyboard_arrow_down"
              position={lang === 'ar' ? 'right' : 'left'}
              scrollable
              wFull
              full
              noHover
              isDropUp
              isMultipleGroupOfOptions
              width="w-full"
              responsiveLabel
              labelClassNames="text-gray-700"
              noCloseOnSelect
              containerStyle={{
                backgroundColor: 'white',
                marginBottom: '20px',
                opacity: (isRiderInfoLoading || isCourierInfoLoading) && '.4',
              }}
              listContainerStyle={{ maxHeight: ridersList.length ? '250px' : '190px' }}
            />
          </>
        )}

        {isRiderInfoLoading
          ? loadingIcon
          : hasRiderInfo && (
              <StoreRiderInfo
                deliveryOrderStatus={deliveryOrderStatus}
                orderDeliveryPhoneNumber={orderDeliveryPhoneNumber}
              />
            )}
        {isCourierInfoLoading
          ? loadingIcon
          : hasCourierInfo && (
              <StoreCourierInfo
                deliveryOrderStatus={deliveryOrderStatus}
                referenceId={referenceId}
                hasDriverInfo={hasCourierDriverInfo}
                driverAssigned={courierDriverAssigned}
                orderDeliveryPhoneNumber={orderDeliveryPhoneNumber}
                driverName={courierDriverName}
                trackingLink={trackingLink}
                isScheduledOrder={isScheduledOrder}
                firingTime={firingTime}
              />
            )}
      </div>
      <div className="flex justify-end">{showNextStatus()}</div>
    </div>
  );
};

OnFleetDeliveryActionButtons.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    number: PropTypes.string,
    status: PropTypes.string.isRequired,
    branchData: PropTypes.shape({ id: PropTypes.string }),
    restaurantRiderBranchOrderData: PropTypes.shape({ restaurantRider: PropTypes.string, status: PropTypes.string }),
  }),
  isUpdatingStatus: PropTypes.bool.isRequired,
  shouldDisableActionBtns: PropTypes.bool,
  sendChangeStatus: PropTypes.func.isRequired,
  deliveryType: PropTypes.string.isRequired,
  openOrderDeliveryConfirmationPopUp: PropTypes.func.isRequired,
  isExpectedAtValid: PropTypes.bool.isRequired,
};
export default OnFleetDeliveryActionButtons;
