import './style.css';
import './assets/fonts/inter/index.css';
import 'moment-timezone';
import 'constants/ar-kw';
import 'moment/locale/en-gb';

import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { Router, Redirect, globalHistory } from '@reach/router';
import { Page, ErrorBoundary } from 'components/service';
import * as guards from './guards';
import Context from './context';
import * as auth from './pages/auth';
import * as general from './pages/general';
import * as orders from './pages/orders';
import * as salesAnalytics from './pages/analytics/SalesAnalytics';
import * as breakdown from './pages/analytics/BehaviorBreakdown';
import * as bestSelling from './pages/analytics/BestSelling';
import * as geoAnalytics from './pages/analytics/GeoAnalytics';
import * as reports from './pages/reports';

import * as onboarding from './pages/onboarding';

import * as information from './pages/setup/information';
import * as branding from './pages/setup/branding';
import * as branch from './pages/setup/branch';
import * as delivery from './pages/setup/delivery';

import * as productCatalog from './pages/setup/product/catalog';
import * as productOptionGroups from './pages/setup/product/optionGroups';
import * as productStockManagement from './pages/setup/stockManagement';
import * as ordersManagement from './pages/setup/orders';
import * as storeLayout from './pages/setup/storeLayout';

import { integrationsPage, integrationsStatusPage } from './pages/setup/integrations';

import * as paymentMethods from './pages/setup/paymentMethods';
import * as team from './pages/team';
import * as verd from './pages/verd';
import * as webLinks from './pages/setup/webLinks';
import * as billing from './pages/billing';
import * as editUserInfo from './pages/editUserInfo';
import * as vouchers from './pages/marketing/vouchers';
import * as campaigns from './pages/marketing/campaigns';
import * as Customers from 'pages/customers';

// ORDERFAST COMPONENTS
import OrderfastLinksPage from './pages/orderfast/links';
import Loyalty from './pages/orderfast/loyalty';

// MENU COMPONENTS
import MenuOverviewPage from './pages/menu/overview/List/MenuOverviewPage';
import StockVariants from './pages/menu/manageVariants/stockManagment/Variants/StockVariants';
import ItemTracking from './pages/menu/manageVariants/itemTracking/Variants/ItemTracking';
import StockSettings from './pages/menu/generalSettings/StockSettings';
import StockHistory from './pages/menu/historyLog/StockHistory';
import Social from './pages/marketing/trackingAndSocialMedia';
import MenuOptionGroups from './pages/menu/optionGroups/MenuOptionGroups';

import * as utils from './utils';
import * as paths from './paths';
import * as translations from 'constants/translations';
import { PATH_NAMES } from 'constants/segment';
import { NODE_ENV_STG, NODE_ENV_PROD } from 'constants/environments';
import { VitallyScripts } from '../src/components/service/Vitally';
import logger from 'common/utils/logger';
import Wallet from 'pages/orderfast/wallet';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const shouldReportToSentry = [NODE_ENV_STG, NODE_ENV_PROD].includes(process.env.REACT_APP_ENV);

if (shouldReportToSentry) {
  const userId = localStorage.getItem('user_id');
  try {
    Sentry.init({
      release: `${process.env.REACT_APP_SENTRY_RELEASE_PREFIX}${process.env.REACT_APP_SENTRY_RELEASE}`,
      dsn: SENTRY_DSN,
      integrations: [new BrowserTracing({})],
      environment: process.env.REACT_APP_ENV,
      tracesSampleRate: 0.7,
      autoSessionTracking: true,
      initialScope: {
        user: { id: userId },
      },
    });
  } catch (error) {
    console.log('Sentry Failed to initialize with error', error);
  }
}

const { pageNames } = translations;

if (process.env.REACT_APP_VITALLY) {
  VitallyScripts();
}

logger.init();

const initChatwootWidget = () => {
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.id = 'chatwoot-script-loader';

  var BASE_URL = 'https://chat.halamboha.com';
  script.src = BASE_URL + '/packs/js/sdk.js';
  var scriptElement = document.getElementsByTagName('script')[0];
  scriptElement.parentNode.insertBefore(script, scriptElement);

  script.onload = function() {
    window.chatwootSDK.run({
      websiteToken: 'AHYjPD69GQ2j2eNkXHhysmbL',
      baseUrl: BASE_URL,
    });
  };
};

// initChatwootWidget();

utils.freshchat.init();

if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
  utils.initSegment(process.env.REACT_APP_SEGMENT_WRITE_KEY);
  const filePathRegex = /^\/.+:.*/i;
  // Single-Page Apps not supported by default in Segment so each router change need to be detected manually
  globalHistory.listen(({ action, location }) => {
    if (utils.getCookie('ajs_user_id') !== '') {
      if (
        action === 'PUSH' &&
        location.pathname !== PATH_NAMES.SIGN_IN &&
        location.pathname !== PATH_NAMES.FORGOT_PASSWORD &&
        !location.pathname.includes(PATH_NAMES.RESET_PASSWORD) &&
        !filePathRegex.test(location.pathname)
      ) {
        window.analytics.page();
      }
    }
  });
}

ReactDOM.render(
  <ApolloProvider client={utils.apollo}>
    <ErrorBoundary>
      <Context>
        <Router>
          <Page path={paths.main} component={general.Main} guard={guards.storeCreated} />
          <Page path={paths.facebookConnect} component={general.FacebookConnect} guard={guards.storeCreated} />
          <Page path={paths.switchStore} component={general.SwitchStore} guard={guards.storeCreated} />
          <Page path={paths.editUserInfo} component={editUserInfo.EditInfo} guard={guards.storeCreated} />
          <Page path={paths.changePassword} component={editUserInfo.ChangePassword} guard={guards.storeCreated} />
          <Page path={paths.billing} component={billing.Details} guard={guards.storeCreated} />
          <Page path={paths.renew} component={billing.Renew} guard={guards.storeCreated} />
          <Page path={paths.invoices} component={billing.Invoices} guard={guards.storeCreated} />

          <Redirect noThrow from={paths.orderfast} to={paths.loyalty} guard={guards.storeCreated} />
          <Page name={pageNames.links} path={paths.links} component={OrderfastLinksPage} guard={guards.storeCreated} />
          <Page name={pageNames.loyalty} path={paths.loyalty} component={Loyalty} guard={guards.storeCreated} />
          <Page name={pageNames.wallet} path={paths.wallet} component={Wallet} guard={guards.storeCreated} />

          <Page
            name={pageNames.allOrders}
            path={paths.allOrders}
            component={orders.All}
            guard={guards.storeCreatedAndSubscriped}
          />
          <Page
            name={pageNames.pendingOrders}
            path={paths.pendingOrders}
            component={orders.Pending}
            guard={guards.storeCreatedAndSubscriped}
          />
          <Page
            name={pageNames.acceptedOrders}
            path={paths.acceptedOrders}
            component={orders.Accepted}
            guard={guards.storeCreatedAndSubscriped}
          />
          <Page
            name={pageNames.readyOrders}
            path={paths.readyOrders}
            component={orders.Ready}
            guard={guards.storeCreatedAndSubscriped}
          />
          <Page
            name={pageNames.dispatchedOrders}
            path={paths.dispatchedOrders}
            component={orders.Dispatched}
            guard={guards.storeCreatedAndSubscriped}
          />
          <Page
            name={pageNames.pickedUpOrders}
            path={paths.pickedUpOrders}
            component={orders.PickedUp}
            guard={guards.storeCreatedAndSubscriped}
          />
          <Page
            name={pageNames.canceledOrders}
            path={paths.canceledOrders}
            component={orders.Canceled}
            guard={guards.storeCreatedAndSubscriped}
          />
          <Page
            name={pageNames.refundedOrders}
            path={paths.refundedOrders}
            component={orders.Refunded}
            guard={guards.storeCreatedAndSubscriped}
          />
          <Page
            name={pageNames.completedOrders}
            path={paths.completedOrders}
            component={orders.Completed}
            guard={guards.storeCreatedAndSubscriped}
          />
          <Redirect
            noThrow
            from={paths.analytics}
            to={paths.salesAnalytics}
            guard={guards.storeCreatedAndAvail}
            name={pageNames.analytics}
          />
          <Page
            name={pageNames.salesAnalytics}
            path={paths.salesAnalytics}
            component={salesAnalytics.SalesAnalytics}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.salesBreakdown}
            path={paths.salesBreakdown}
            component={salesAnalytics.SaleBreakdown}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.behaviorBreakdown}
            path={paths.behaviorBreakdown}
            component={breakdown.BehaviorBreakdown}
            guard={guards.storeCreated}
          />
          <Page
            name={pageNames.behaviorBreakdown}
            path={paths.breakdownDeliveryPickup}
            component={breakdown.DeliveryPickup}
            guard={guards.storeCreated}
          />
          <Page
            name={pageNames.behaviorBreakdown}
            path={paths.breakdownPaymentMethods}
            component={breakdown.PaymentMethods}
            guard={guards.storeCreated}
          />
          <Page
            name={pageNames.behaviorBreakdown}
            path={paths.breakdownNewVsReturning}
            component={breakdown.NewVsReturning}
            guard={guards.storeCreated}
          />
          <Page
            name={pageNames.bestSelling}
            path={paths.bestSelling}
            component={bestSelling.BestSelling}
            guard={guards.storeCreated}
          />
          <Page
            name={pageNames.productsAnalytics}
            path={paths.productsAnalytics}
            component={bestSelling.ProductAnalytics}
            guard={guards.storeCreated}
          />
          <Page
            name={pageNames.geoAnalytics}
            path={paths.geoAnalytics}
            component={geoAnalytics.GeoAnalytics}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.geoAnalytics}
            path={paths.geoAnalyticsBranches}
            component={geoAnalytics.GeoBranches}
            guard={guards.storeCreated}
          />
          <Page
            name={pageNames.geoAnalytics}
            path={paths.geoAnalyticsBranchAreas}
            component={geoAnalytics.GeoAreas}
            guard={guards.storeCreated}
          />
          <Page
            name={pageNames.waitingForPaymentOrders}
            path={paths.waitingForPaymentOrders}
            component={orders.WaitingForPayment}
            guard={guards.storeCreated}
          />
          <Page
            name={pageNames.paymentFailedOrders}
            path={paths.paymentFailedOrders}
            component={orders.PaymentFailed}
            guard={guards.storeCreated}
          />
          <Page
            name={pageNames.pushFailedOrders}
            path={paths.pushFailedOrders}
            component={orders.PushFailed}
            guard={guards.storeCreated}
          />
          <Page path={paths.salesReports} component={reports.SalesReport} guard={guards.storeCreatedAndAvail} />
          <Redirect noThrow from={paths.reports} to={paths.oldsalesReports} guard={guards.storeCreatedAndAvail} />
          <Page
            name={pageNames.oldsalesReports}
            path={paths.oldsalesReports}
            component={reports.Sales}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.customersReports}
            path={paths.customersReports}
            component={reports.Customers}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.menu}
            path={paths.itemTracking}
            component={ItemTracking}
            guard={guards.storeCreatedAndSubscriped}
          />
          {/* Redirect for Mobile App */}
          <Redirect noThrow from={paths.oldProducts} to={paths.products} guard={guards.storeCreatedAndAvail} />
          <Redirect
            noThrow
            from={paths.oldProductsCatalog}
            to={paths.productsCatalog}
            guard={guards.storeCreatedAndAvail}
          />
          <Redirect
            noThrow
            from={paths.oldProductsOptionGroups}
            to={paths.productsOptionGroups}
            guard={guards.storeCreatedAndAvail}
          />
          <Redirect
            noThrow
            from={paths.oldBaseProductCreation}
            to={paths.baseProductCreation}
            guard={guards.storeCreatedAndAvail}
          />
          <Redirect
            noThrow
            from={paths.oldAdvancedProductCreation}
            to={paths.advancedProductCreation}
            guard={guards.storeCreatedAndAvail}
          />
          <Redirect
            noThrow
            from={paths.oldPreviewProductCreation}
            to={paths.previewProductCreation}
            guard={guards.storeCreatedAndAvail}
          />
          <Redirect
            noThrow
            from={paths.oldProductEdition}
            to={paths.productEdition}
            guard={guards.storeCreatedAndAvail}
          />
          <Redirect
            noThrow
            from={paths.oldStockManagement}
            to={paths.stockManagement}
            guard={guards.storeCreatedAndAvail}
          />
          <Redirect
            noThrow
            from={paths.oldStockManagementVariants}
            to={paths.stockManagementVariants}
            guard={guards.storeCreatedAndAvail}
          />
          <Redirect noThrow from={paths.menu} to={paths.variantItemTracking} guard={guards.storeCreatedAndAvail} />
          <Redirect
            noThrow
            from={paths.oldStockManagementGeneralSettings}
            to={paths.stockManagementGeneralSettings}
            guard={guards.storeCreatedAndAvail}
          />
          <Redirect
            noThrow
            from={paths.oldStockHistoryLog}
            to={paths.stockHistoryLog}
            guard={guards.storeCreatedAndAvail}
          />
          <Redirect
            noThrow
            from={paths.oldStockHistoryLog}
            to={paths.itemTrackingHistoryLog}
            guard={guards.storeCreatedAndAvail}
          />
          {/* End Redirect for Mobile App */}

          <Redirect noThrow from={paths.setup} to={paths.businessInformation} guard={guards.storeCreatedAndAvail} />
          <Redirect noThrow from={paths.ordersManagement} to={paths.ordersManagementScheduling} />
          <Page
            name={pageNames.businessInformation}
            path={paths.businessInformation}
            component={information.Root}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.branding}
            path={paths.branding}
            component={branding.Root}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.branchList}
            path={paths.branchList}
            component={branch.List}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.branchCreation}
            path={paths.branchCreation}
            component={branch.Creation}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.branchCreationSuccess}
            path={paths.branchCreationSuccess}
            component={branch.SuccessCreation}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.branchEdition}
            path={paths.branchEdition}
            component={branch.Edition}
            guard={guards.storeCreatedAndAvail}
          />
          {/* Begin :: Setup -> Delivery */}
          <Page
            name={pageNames.deliveryList}
            path={paths.deliveryList}
            component={delivery.List}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.deliveryList}
            path={paths.zonesCreation}
            component={delivery.Zones}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.deliveryList}
            path={paths.zonesTableList}
            component={delivery.ZonesList}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.deliveryZoneTemplates}
            path={paths.deliveryZoneTemplates}
            component={delivery.Templates}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.deliveryZoneTemplates}
            path={paths.deliveryZoneTemplatesByCity}
            component={delivery.Templates}
            guard={guards.storeCreatedAndAvail}
          />

          <Page
            name={pageNames.deliveryCreation}
            path={paths.deliveryCreation}
            component={delivery.Creation}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.deliveryEdition}
            path={paths.deliveryEdition}
            component={delivery.Edition}
            guard={guards.storeCreatedAndAvail}
          />
          {/* End :: Delivery -> Setup */}
          <Redirect
            noThrow
            from={paths.stockManagement}
            to={paths.stockManagementVariants}
            guard={guards.storeCreatedAndAvail}
          />
          <Redirect noThrow from={paths.menu} to={paths.menuOverview} guard={guards.storeCreatedAndAvail} />
          <Page
            name={pageNames.menu}
            path={paths.menuOverview}
            component={MenuOverviewPage}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.stockManagementVariant}
            path={paths.stockManagementVariants}
            component={StockVariants}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.menu}
            path={paths.variantItemTracking}
            component={ItemTracking}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.stockManagementSettings}
            path={paths.stockManagementGeneralSettings}
            component={StockSettings}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.stockManagementHistoryLog}
            path={paths.stockHistoryLog}
            component={StockHistory}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.stockManagementHistoryLog}
            path={paths.itemTrackingHistoryLog}
            component={StockHistory}
            guard={guards.storeCreatedAndAvail}
          />
          {/* START OF RETAIL PAGES */}
          <Page
            name={pageNames.productsCatalog}
            path={paths.productsCatalog}
            component={productCatalog.List}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.baseProductCreation}
            path={paths.baseProductCreation}
            component={productCatalog.BaseCreation}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.advancedProductCreation}
            path={paths.advancedProductCreation}
            component={productCatalog.AdvancedCreation}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.previewProductCreation}
            path={paths.previewProductCreation}
            component={productCatalog.PreviewCreation}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.productEdition}
            path={paths.productEdition}
            component={productCatalog.Edition}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.menuOptionGroups}
            path={paths.menuOptionGroups}
            component={MenuOptionGroups}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.menuOptionGroups}
            path={paths.operatorOptionGroups}
            component={MenuOptionGroups}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.productsCatalog}
            path={paths.productsOptionGroups}
            component={productOptionGroups.List}
            guard={guards.storeCreatedAndAvail}
          />
          <Redirect
            noThrow
            from={paths.productStockManagement}
            to={paths.productStockVariants}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.stockManagementVariant}
            path={paths.productStockVariants}
            component={productStockManagement.Variants}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.stockManagementVariant}
            path={paths.productStockSettings}
            component={productStockManagement.GeneralSettings}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.stockManagementVariant}
            path={paths.productStockHistory}
            component={productStockManagement.HistoryLog}
            guard={guards.storeCreatedAndAvail}
          />
          {/* END OF RETAIL PAGES */}
          <Page
            name={pageNames.ordersManagementScheduling}
            path={paths.ordersManagementScheduling}
            component={ordersManagement.Scheduling}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.integrations}
            path={paths.integrations}
            component={integrationsPage}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            path={paths.integrationsStatus}
            component={integrationsStatusPage}
            guard={guards.storeCreatedAndAvail}
          />

          <Page
            name={pageNames.storeLayout}
            path={paths.storeLayout}
            component={storeLayout.Root}
            guard={guards.storeCreatedAndAvail}
          />

          <Page
            name={pageNames.paymentGateways}
            path={paths.paymentGateways}
            component={paymentMethods.Root}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.paymentGateways}
            path={paths.paymentGateway}
            component={paymentMethods.PaymentGateway}
            guard={guards.storeCreatedAndAvail}
          />

          <Page
            name={pageNames.onlinePaymentCreationForm}
            path={paths.onlinePaymentCreationForm}
            component={paymentMethods.Creation}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.onlinePaymentEditionForm}
            path={paths.onlinePaymentEditionForm}
            component={paymentMethods.Edition}
            guard={guards.storeCreatedAndAvail}
          />
          {/*  */}
          <Page
            name={pageNames.webLinks}
            path={paths.webLinks}
            component={webLinks.List}
            guard={guards.storeCreatedAndAvail}
          />
          <Page name={pageNames.signIn} path={paths.signIn} component={auth.SignIn} guard={guards.unauthenticated} />
          <Page
            name={pageNames.forgetPassword}
            path={paths.forgetPassword}
            component={auth.ForgetPassword}
            guard={guards.unauthenticated}
          />
          <Page
            name={pageNames.resetPassword}
            path={paths.resetPassword}
            component={auth.ResetPassword}
            guard={guards.unauthenticated}
          />

          <Page
            name={pageNames.storeCreation}
            path={paths.onboarding.storeCreation}
            component={onboarding.StoreCreation}
            guard={guards.storeNotCreated}
          />
          <Page
            name={pageNames.gettingStarted}
            path={paths.onboarding.gettingStarted}
            component={onboarding.GettingStarted}
            guard={guards.storeCreatedAndAvail}
          />
          <Page path={paths.team} component={team.Users} guard={guards.storeCreatedAndAvail} />
          <Page path={paths.createUser} component={team.CreateUser} guard={guards.storeCreatedAndAvail} />
          <Page path={paths.editUser} component={team.EditUser} guard={guards.storeCreatedAndAvail} />
          <Page path={paths.verd} component={verd.Riders} guard={guards.storeCreatedAndAvail} />
          <Page path={paths.createRider} component={verd.CreateRider} guard={guards.storeCreatedAndAvail} />
          <Page path={paths.editRider} component={verd.EditRider} guard={guards.storeCreatedAndAvail} />
          <Redirect
            noThrow
            from={paths.marketing}
            to={paths.allVouchers}
            guard={guards.storeCreatedAndAvail}
            name={pageNames.marketing}
          />
          <Page
            name={pageNames.allVouchers}
            path={paths.allVouchers}
            component={vouchers.All}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.socialmediatracking}
            path={paths.socialmediatracking}
            component={Social}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.activeVouchers}
            path={paths.activeVouchers}
            component={vouchers.Active}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.deactivatedVouchers}
            path={paths.deactivatedVouchers}
            component={vouchers.Deactivated}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.scheduleVouchers}
            path={paths.scheduleVouchers}
            component={vouchers.Schedule}
            guard={guards.storeCreatedAndAvail}
          />
          <Page
            name={pageNames.expiredVouchers}
            path={paths.expiredVouchers}
            component={vouchers.Expired}
            guard={guards.storeCreatedAndAvail}
          />
          <Page path={paths.createVoucher} component={vouchers.Create} guard={guards.storeCreatedAndAvail} />
          <Page path={paths.editVoucher} component={vouchers.Edit} guard={guards.storeCreatedAndAvail} />
          <Page
            name={pageNames.campaigns}
            path={paths.campaigns}
            component={campaigns.List}
            guard={guards.storeCreated}
          />
          <Page
            name={pageNames.campaigns}
            path={paths.campaignSubscriptions}
            component={campaigns.Subscriptions}
            guard={guards.storeCreated}
          />
          <Page path={paths.createCampaign} component={campaigns.Create} guard={guards.storeCreatedAndAvail} />
          <Page path={paths.editCampaign} component={campaigns.Edit} guard={guards.storeCreatedAndAvail} />
          {/* START CUSTOMERS PAGES */}
          <Page
            path={paths.customers}
            component={Customers.Customers}
            guard={guards.storeCreatedAndAvail}
            name={pageNames.customers}
          />
          <Page
            path={paths.customerProfile}
            component={Customers.CustomerProfile}
            guard={guards.storeCreatedAndAvail}
            name={pageNames.customerProfile}
          />
          {/* END CUSTOMERS PAGES */}
        </Router>
      </Context>
    </ErrorBoundary>
  </ApolloProvider>,
  document.getElementById('root'),
);
