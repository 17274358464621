import React, { useState, useRef } from "react"
import { ChromePicker } from "react-color"
import { useClickOutside } from "hooks/index"

export default ({ name, value, onChange }) => {
  const [isOpened, setOpened] = useState(false)
  const ref = useRef()

  useClickOutside(ref, () => setOpened(false))

  return (
    <div ref={ref} className="flex relative w-full">
      <div
        style={{
          borderColor: value,
        }}
        className="border-2 rounded-full"
      >
        <div
          data-testid="primary-color-btn"
          className="h-10 w-10 rounded-full border-2 border-white cursor-pointer"
          onClick={() => setOpened(!isOpened)}
          style={{
            backgroundColor: value,
          }}
        />
        {isOpened && (
          <div className="absolute mt-3 z-50">
            <ChromePicker
              color={value}
              onChangeComplete={({ rgb, ...rest }) => {
                onChange({
                  target: {
                    name,
                    value: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`,
                  },
                })
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
