import React, { useContext, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { Text } from "components/service"
import { Formik, Form } from "formik"
import { context as notificationsContext } from "context/notifications"
import { context as localeContext } from "context/locale"
import cx from "classnames"
import styled from "styled-components"
import { useSelectedStore } from "hooks/index"
import * as R from "ramda"
import * as translations from "constants/translations"
import * as paths from "paths.js"
import * as schemas from "./schemas"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import { Footer } from "components/form/generic"
import { Button, Spinner } from "components/kit"
import { HomeLayout1 } from "../RadioImage"
import { HomeLayout2 } from "../RadioImage"
import { HomeLayout3 } from "../RadioImage"
import { CategoryLayout1 } from "../RadioImage"
import { CategoryLayout2 } from "../RadioImage"
import { CategoryLayout3 } from "../RadioImage"
import { STORE_LAYOUT_LINKS } from "constants/helperLinks"

export default () => {
  const storeId = useSelectedStore()
  const notifications = useContext(notificationsContext)
  const { lang, direction } = useContext(localeContext)
  const [updateSettings, { loading: loadingChange }] = useMutation(
    schemas.UPDATE_LAYOUT,
    {
      onError: err =>
        notifications.show(
          err.graphQLErrors[0].extensions.exception.body.store_ui[0],
          "error"
        ),
      onCompleted: () =>
        notifications.show(<Text value={translations.STORE_LAYOUT_UPDATED} />),
    }
  )
  const { data, loading } = useQuery(schemas.GET_LAYOUT, {
    variables: {
      storeId: storeId,
    },
  })
  const initialValues = {
    menuItemsLayout: "1",
    menuSectionLayout: "1",
  }
  return (
    <Layout
      top={
        <Breadcrumbs
          helperLinks={STORE_LAYOUT_LINKS}
          path={translations.breadcrumbs.SETUP_STORE_LAYOUT}
        />
      }
    >
        {loading ? (
          <Spinner />
        ) : (
          <Formik
            onSubmit={async data => {
              await updateSettings({
                variables: {
                  storeId,
                  menuSectionLayout: parseInt(data.menuSectionLayout),
                  menuItemsLayout: parseInt(data.menuItemsLayout),
                },
              })
            }}
            initialValues={R.mergeDeepRight(initialValues, {
              menuItemsLayout:
                data && data.settings.storeUi.menuItemsLayout.toString(),
              menuSectionLayout:
                data && data.settings.storeUi.menuSectionLayout.toString(),
            })}
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form style={{ direction }}>
                <div className="rounded border border-gray-300 p-4">
                  <Text
                    className="font-semibold"
                    value={translations.HOME_LAYOUT}
                  />
                  <Text value={translations.CHOOSE_STORE_HOME_LAYOUT} />
                  <div
                    id="homeLayout"
                    className="flex flex-wrap justify-between md:text-center"
                  >
                    <RadioG
                      data-testid="category-layout-1"
                      className="flex flex-row-reverse md:flex-col items-center px-2"
                      checked={values.menuSectionLayout === "1"}
                    >
                      <HomeLayout1 checked={values.menuSectionLayout === "1"} />
                      <div
                        className={cx(
                          "w-1/2 md:w-auto",
                          lang === "ar" && "mr-4 text-xs"
                        )}
                      >
                        <Text
                          className="font-semibold"
                          value={translations.HOME_LAYOUT}
                          postfix=" 1"
                        />
                        <Text
                          className="mt-2 text-gray-500 text-xs mb-1"
                          value={translations.RECOMMENDED_FOOD_INDUSTRY}
                        />

                        <input
                          type="radio"
                          name="menuSectionLayout"
                          value="1"
                          checked={values.menuSectionLayout === "1"}
                          onChange={e => {
                            handleChange(e)
                            setFieldValue("menuItemsLayout", "1")
                          }}
                          className="invisible md:visible"
                        />
                      </div>
                    </RadioG>
                    <RadioG
                      data-testid="category-layout-2"
                      className="flex flex-row-reverse md:flex-col items-center px-2"
                      checked={values.menuSectionLayout === "2"}
                    >
                      <HomeLayout2 checked={values.menuSectionLayout === "2"} />
                      <div
                        className={cx(
                          "w-1/2 md:w-auto",
                          lang === "ar" && "mr-4 text-xs"
                        )}
                      >
                        <Text
                          className="font-semibold"
                          value={translations.HOME_LAYOUT}
                          postfix=" 2"
                        />
                        <Text
                          className="mt-2 text-gray-500 text-xs mb-1"
                          value={translations.RECOMMENDED_FASHION_INDUSTRY}
                        />

                        <input
                          type="radio"
                          name="menuSectionLayout"
                          value="2"
                          checked={values.menuSectionLayout === "2"}
                          onChange={handleChange}
                          className="invisible md:visible"
                        />
                      </div>
                    </RadioG>
                    <RadioG
                      data-testid="category-layout-3"
                      className="flex flex-row-reverse md:flex-col items-center px-2"
                      checked={values.menuSectionLayout === "3"}
                    >
                      <HomeLayout3 checked={values.menuSectionLayout === "3"} />
                      <div
                        className={cx(
                          "w-1/2 md:w-auto",
                          lang === "ar" && "mr-4 text-xs"
                        )}
                      >
                        <Text
                          className="font-semibold"
                          value={translations.HOME_LAYOUT}
                          postfix=" 3"
                        />
                        <Text
                          className="mt-2 text-gray-500 text-xs mb-1"
                          value={
                            translations.RECOMMENDED_GROCERY_COSMETICS_INDUSTRY
                          }
                        />

                        <input
                          type="radio"
                          name="menuSectionLayout"
                          value="3"
                          checked={values.menuSectionLayout === "3"}
                          onChange={handleChange}
                          className="invisible md:visible"
                        />
                      </div>
                    </RadioG>
                  </div>
                </div>

                <div className="border border-gray-300 p-4 mt-4">
                  <Text
                    className="font-semibold"
                    value={translations.CATEGORY_LAYOUT}
                  />
                  <Text value={translations.CHOOSE_STORE_CATEGORY_LAYOUT} />

                  <div
                    id="categoryLayout"
                    className="flex flex-wrap justify-between md:text-center"
                  >
                    <RadioG
                      data-testid="items-layout-1"
                      className="flex flex-row-reverse md:flex-col items-center px-2"
                      checked={values.menuItemsLayout === "1"}
                      dimmed={values.menuSectionLayout === "1"}
                    >
                      <CategoryLayout1
                        checked={values.menuItemsLayout === "1"}
                        dimmed={values.menuSectionLayout === "1"}
                      />
                      <div
                        className={cx(
                          "w-1/2 md:w-auto",
                          lang === "ar" && "mr-4 text-xs"
                        )}
                      >
                        <Text
                          className="font-semibold"
                          value={translations.CATEGORY_LAYOUT}
                          postfix=" 1"
                        />
                        <Text
                          className="mt-2 text-gray-500 text-xs mb-1"
                          value={translations.RECOMMENDED_FOOD_INDUSTRY}
                        />

                        <input
                          type="radio"
                          name="menuItemsLayout"
                          disabled={values.menuSectionLayout === "1"}
                          value="1"
                          checked={values.menuItemsLayout === "1"}
                          dimmed={values.menuSectionLayout === "1"}
                          onChange={handleChange}
                          className="invisible md:visible"
                        />
                      </div>
                    </RadioG>
                    <RadioG
                      data-testid="items-layout-2"
                      className="flex flex-row-reverse md:flex-col items-center px-2"
                      checked={values.menuItemsLayout === "2"}
                    >
                      <CategoryLayout2
                        checked={values.menuItemsLayout === "2"}
                      />
                      <div
                        className={cx(
                          "w-1/2 md:w-auto",
                          lang === "ar" && "mr-4 text-xs"
                        )}
                      >
                        <Text
                          className="font-semibold"
                          value={translations.CATEGORY_LAYOUT}
                          postfix=" 2"
                        />
                        <Text
                          className="mt-2 text-gray-500 text-xs mb-1"
                          value={translations.RECOMMENDED_FASHION_INDUSTRY}
                        />

                        <input
                          type="radio"
                          name="menuItemsLayout"
                          disabled={values.menuSectionLayout === "1"}
                          value="2"
                          checked={values.menuItemsLayout === "2"}
                          onChange={handleChange}
                          className="invisible md:visible"
                        />
                      </div>
                    </RadioG>
                    <RadioG
                      data-testid="items-layout-3"
                      className="flex flex-row-reverse md:flex-col items-center px-2"
                      checked={values.menuItemsLayout === "3"}
                    >
                      <CategoryLayout3
                        checked={values.menuItemsLayout === "3"}
                      />
                      <div
                        className={cx(
                          "w-1/2 md:w-auto",
                          lang === "ar" && "mr-4 text-xs"
                        )}
                      >
                        <Text
                          className="font-semibold"
                          value={translations.CATEGORY_LAYOUT}
                          postfix=" 3"
                        />
                        <Text
                          className="mt-2 text-gray-500 text-xs mb-1"
                          value={
                            translations.RECOMMENDED_GROCERY_COSMETICS_INDUSTRY
                          }
                        />
                        <input
                          type="radio"
                          name="menuItemsLayout"
                          disabled={values.menuSectionLayout === "1"}
                          value="3"
                          checked={values.menuItemsLayout === "3"}
                          onChange={handleChange}
                          className="invisible md:visible"
                        />
                      </div>
                    </RadioG>
                  </div>
                </div>
                <Footer>
                  <Button
                    data-testid="save-layout-changes"
                    kind="primary"
                    size="base"
                    isSpinning={loadingChange}
                  >
                    <Text value={translations.SAVE_CHANGE} />
                  </Button>
                </Footer>
              </Form>
            )}
          </Formik>
        )}
    </Layout>
  )
}

const RadioG = styled.label`
  margin: 16px 0;
  cursor: pointer;
  border: #ecedf1 solid 1px;
  border-radius: 0.25rem;

  ${({ checked }) =>
    checked &&
    `
    border-color: #3852ca;
    `}
  ${({ dimmed }) =>
    dimmed &&
    `
    border-color: #eee;
		`}

  input {
    line-height: 22px;
    height: 22px;
    padding-left: 22px;
    position: relative;
    margin: 0 auto 24px auto;

    &:before,
    &:after {
      content: "";
      width: 22px;
      height: 22px;
      display: block;
      border-radius: 50%;
      left: -1px;
      top: 0;
      position: absolute;
    }
    &:before {
      background: #d1d7e3;
      transition: background 0.2s ease,
        transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1);
    }
    &:after {
      background: #fff;
      transform: scale(0.9);
      transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
    }

    &:checked {
      &:before {
        transform: scale(1.04);
        background: #3852ca;
        ${({ dimmed }) =>
          dimmed &&
          `
            background: #e2e8f0;
		    `}
      }
      &:after {
        transform: scale(0.4);
        transition: transform 0.3s ease;
      }
    }
  }
`
