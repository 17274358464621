import React from "react"

export const HomeLayout1 = ({ checked }) => {
  const color1 = checked ? "#D0D7F5" : "#DDE4E9"
  const color2 = checked ? "#F2F5FF" : "#F4F7F9"
  return (
    <svg
      className="w-1/2 md:w-3/4 my-4 mx-auto h-auto"
      xmlns="http://www.w3.org/2000/svg"
      width="304"
      height="545"
      fill="none"
      viewBox="0 0 304 545"
    >
      <rect width="304" height="545" fill={color1} rx="40" />
      <rect
        width="112.593"
        height="11.237"
        x="95.704"
        y="16.855"
        fill="#fff"
        rx="5.619"
      />
      <rect
        width="281.482"
        height="438.247"
        x="11.259"
        y="44.948"
        fill={color2}
        rx="5"
      />
      <rect width="105" height="90" x="150" y="256" fill={color1} rx="5" />
      <rect width="80" height="10" x="50" y="306" fill={color1} rx="5" />
      <rect width="50" height="10" x="50" y="286" fill={color1} rx="5" />
      <rect width="105" height="90" x="150" y="362" fill={color1} rx="5" />
      <rect width="80" height="10" x="50" y="412" fill={color1} rx="5" />
      <rect width="50" height="10" x="50" y="392" fill={color1} rx="5" />
      <rect width="105" height="30" x="150" y="468" fill={color1} rx="5" />
      <rect width="80" height="10" x="50" y="518" fill={color1} rx="5" />
      <rect width="50" height="10" x="50" y="498" fill={color1} rx="5" />
      <rect width="50" height="50" x="20" y="146" fill={color1} rx="25" />
      <rect width="80" height="10" x="90" y="176" fill={color1} rx="5" />
      <rect width="50" height="10" x="90" y="156" fill={color1} rx="5" />
      <rect width="264" height="85" x="20" y="53" fill={color1} rx="5" />
      <rect
        width="28.148"
        height="28.093"
        x="138.489"
        y="500.052"
        fill="#fff"
        rx="14.046"
      />
      <rect width="95" height="20" x="47" y="212" fill={color1} rx="10" />
      <rect width="95" height="20" x="162" y="212" fill={color1} rx="10" />
    </svg>
  )
}

export const HomeLayout2 = ({ checked }) => {
  const color1 = checked ? "#D0D7F5" : "#DDE4E9"
  const color2 = checked ? "#F2F5FF" : "#F4F7F9"
  return (
    <svg
      className="w-1/2 md:w-3/4 my-4 mx-auto h-auto"
      xmlns="http://www.w3.org/2000/svg"
      width="304"
      height="545"
      fill="none"
      viewBox="0 0 304 545"
    >
      <rect width="304" height="545" fill={color1} rx="40" />
      <rect
        width="112.593"
        height="11.237"
        x="95.704"
        y="16.855"
        fill="#fff"
        rx="5.619"
      />
      <rect
        width="281.482"
        height="438.247"
        x="11.259"
        y="44.948"
        fill={color2}
        rx="5"
      />
      <rect width="50" height="50" x="20" y="146" fill={color1} rx="25" />
      <rect width="80" height="10" x="90" y="176" fill={color1} rx="5" />
      <rect width="50" height="10" x="90" y="156" fill={color1} rx="5" />
      <rect width="264" height="85" x="20" y="53" fill={color1} rx="5" />
      <rect
        width="28.148"
        height="28.093"
        x="138.489"
        y="500.052"
        fill="#fff"
        rx="14.046"
      />
      <rect width="95" height="20" x="47" y="212" fill={color1} rx="10" />
      <rect
        width="118.222"
        height="101.333"
        x="22"
        y="256"
        fill={color1}
        rx="5"
      />
      <rect
        width="90.074"
        height="11.259"
        x="22"
        y="391.519"
        fill={color1}
        rx="5.63"
      />
      <rect
        width="56.296"
        height="11.259"
        x="22"
        y="369"
        fill={color1}
        rx="5.63"
      />
      <rect width="118" height="68" x="22" y="419" fill={color1} rx="5" />
      <rect
        width="118.222"
        height="101.333"
        x="163"
        y="256"
        fill={color1}
        rx="5"
      />
      <rect
        width="90.074"
        height="11.259"
        x="163"
        y="391.519"
        fill={color1}
        rx="5.63"
      />
      <rect
        width="56.296"
        height="11.259"
        x="163"
        y="369"
        fill={color1}
        rx="5.63"
      />
      <rect width="118" height="68" x="163" y="419" fill={color1} rx="5" />
      <rect width="95" height="20" x="162" y="212" fill={color1} rx="10" />
    </svg>
  )
}

export const HomeLayout3 = ({ checked }) => {
  const color1 = checked ? "#D0D7F5" : "#DDE4E9"
  const color2 = checked ? "#F2F5FF" : "#F4F7F9"
  return (
    <svg
      className="w-1/2 md:w-3/4 my-4 mx-auto h-auto"
      xmlns="http://www.w3.org/2000/svg"
      width="304"
      height="545"
      viewBox="0 0 304 545"
    >
      <rect width="304" height="545" fill={color1} rx="40" />
      <rect
        width="112.593"
        height="11.237"
        x="95.704"
        y="16.855"
        fill="#fff"
        rx="5.619"
      />
      <rect
        width="281.482"
        height="438.247"
        x="11.259"
        y="44.948"
        fill={color2}
        rx="5"
      />
      <rect width="50" height="50" x="20" y="146" fill={color1} rx="25" />
      <rect width="80" height="10" x="90" y="176" fill={color1} rx="5" />
      <rect width="50" height="10" x="90" y="156" fill={color1} rx="5" />
      <rect width="105" height="90" x="20" y="256" fill={color1} rx="5" />
      <rect width="80" height="10" x="20" y="376" fill={color1} rx="5" />
      <rect width="50" height="10" x="20" y="356" fill={color1} rx="5" />
      <rect width="105" height="90" x="135" y="256" fill={color1} rx="5" />
      <rect width="80" height="10" x="135" y="376" fill={color1} rx="5" />
      <rect width="50" height="10" x="135" y="356" fill={color1} rx="5" />
      <rect width="50" height="90" x="250" y="256" fill={color1} rx="5" />
      <rect width="50" height="10" x="250" y="376" fill={color1} rx="5" />
      <rect width="50" height="10" x="250" y="356" fill={color1} rx="5" />
      <rect width="105" height="90" x="20" y="402" fill={color1} rx="5" />
      <rect width="80" height="10" x="20" y="522" fill={color1} rx="5" />
      <rect width="50" height="10" x="20" y="502" fill={color1} rx="5" />
      <rect width="105" height="90" x="135" y="402" fill={color1} rx="5" />
      <rect width="80" height="10" x="135" y="522" fill={color1} rx="5" />
      <rect width="50" height="10" x="135" y="502" fill={color1} rx="5" />
      <rect width="50" height="90" x="250" y="402" fill={color1} rx="5" />
      <rect width="50" height="10" x="250" y="502" fill={color1} rx="5" />
      <rect width="264" height="85" x="20" y="53" fill={color1} rx="5" />
      <rect
        width="28.148"
        height="28.093"
        x="138.489"
        y="500.052"
        fill="#fff"
        rx="14.046"
      />
      <rect width="95" height="20" x="47" y="212" fill={color1} rx="10" />
      <rect width="95" height="20" x="162" y="212" fill={color1} rx="10" />
    </svg>
  )
}

export const CategoryLayout1 = ({ checked, dimmed }) => {
  const color1 = checked && !dimmed ? "#D0D7F5" : "#DDE4E9"
  const color2 = checked && !dimmed ? "#F2F5FF" : "#F4F7F9"

  return (
    <svg
      className="w-1/2 md:w-3/4 my-4 mx-auto h-auto"
      xmlns="http://www.w3.org/2000/svg"
      width="304"
      height="545"
      fill="none"
      viewBox="0 0 304 545"
    >
      <rect width="304" height="545" fill={color1} rx="40" />
      <rect
        width="112.593"
        height="11.237"
        x="95.704"
        y="16.856"
        fill="#fff"
        rx="5.619"
      />
      <rect
        width="281.482"
        height="438.247"
        x="11.259"
        y="44.949"
        fill={color2}
        rx="5"
      />
      <rect width="105" height="90" x="150" y="67" fill={color1} rx="5" />
      <rect width="80" height="10" x="50" y="117" fill={color1} rx="5" />
      <rect width="50" height="10" x="50" y="97" fill={color1} rx="5" />
      <rect width="105" height="90" x="150" y="279" fill={color1} rx="5" />
      <rect width="80" height="10" x="50" y="329" fill={color1} rx="5" />
      <rect width="50" height="10" x="50" y="309" fill={color1} rx="5" />
      <rect width="105" height="90" x="150" y="173" fill={color1} rx="5" />
      <rect width="80" height="10" x="50" y="223" fill={color1} rx="5" />
      <rect width="50" height="10" x="50" y="203" fill={color1} rx="5" />
      <rect width="105" height="90" x="150" y="385" fill={color1} rx="5" />
      <rect width="80" height="10" x="50" y="435" fill={color1} rx="5" />
      <rect width="50" height="10" x="50" y="415" fill={color1} rx="5" />
      <rect
        width="28.148"
        height="28.093"
        x="138.489"
        y="500.052"
        fill="#fff"
        rx="14.046"
      />
    </svg>
  )
}

export const CategoryLayout2 = ({ checked }) => {
  const color1 = checked ? "#D0D7F5" : "#DDE4E9"
  const color2 = checked ? "#F2F5FF" : "#F4F7F9"
  return (
    <svg
      className="w-1/2 md:w-3/4 my-4 mx-auto h-auto"
      xmlns="http://www.w3.org/2000/svg"
      width="304"
      height="545"
      fill="none"
      viewBox="0 0 304 545"
    >
      <rect width="304" height="545" fill={color1} rx="40" />
      <rect
        width="112.593"
        height="11.237"
        x="95.704"
        y="16.856"
        fill="#fff"
        rx="5.619"
      />
      <rect
        width="281.482"
        height="438.247"
        x="11.259"
        y="44.949"
        fill={color2}
        rx="5"
      />
      <rect
        width="28.148"
        height="28.093"
        x="138.489"
        y="500.052"
        fill="#fff"
        rx="14.046"
      />
      <rect
        width="118.222"
        height="101.333"
        x="22"
        y="67"
        fill={color1}
        rx="5"
      />
      <rect
        width="90.074"
        height="11.259"
        x="22"
        y="202.519"
        fill={color1}
        rx="5.63"
      />
      <rect
        width="56.296"
        height="11.259"
        x="22"
        y="180"
        fill={color1}
        rx="5.63"
      />
      <rect
        width="118.222"
        height="101.333"
        x="22"
        y="230"
        fill={color1}
        rx="5"
      />
      <rect
        width="90.074"
        height="11.259"
        x="22"
        y="365.519"
        fill={color1}
        rx="5.63"
      />
      <rect
        width="56.296"
        height="11.259"
        x="22"
        y="343"
        fill={color1}
        rx="5.63"
      />
      <rect
        width="118.222"
        height="101.333"
        x="22"
        y="393"
        fill={color1}
        rx="5"
      />
      <rect
        width="90.074"
        height="11.259"
        x="22"
        y="528.519"
        fill={color1}
        rx="5.63"
      />
      <rect
        width="56.296"
        height="11.259"
        x="22"
        y="506"
        fill={color1}
        rx="5.63"
      />
      <rect
        width="118.222"
        height="101.333"
        x="163"
        y="67"
        fill={color1}
        rx="5"
      />
      <rect
        width="90.074"
        height="11.259"
        x="163"
        y="202.519"
        fill={color1}
        rx="5.63"
      />
      <rect
        width="56.296"
        height="11.259"
        x="163"
        y="180"
        fill={color1}
        rx="5.63"
      />
      <rect
        width="118.222"
        height="101.333"
        x="163"
        y="230"
        fill={color1}
        rx="5"
      />
      <rect
        width="90.074"
        height="11.259"
        x="163"
        y="365.519"
        fill={color1}
        rx="5.63"
      />
      <rect
        width="56.296"
        height="11.259"
        x="163"
        y="343"
        fill={color1}
        rx="5.63"
      />
      <rect
        width="118.222"
        height="101.333"
        x="163"
        y="393"
        fill={color1}
        rx="5"
      />
      <rect
        width="90.074"
        height="11.259"
        x="163"
        y="528.519"
        fill={color1}
        rx="5.63"
      />
    </svg>
  )
}

export const CategoryLayout3 = ({ checked }) => {
  const color1 = checked ? "#D0D7F5" : "#DDE4E9"
  const color2 = checked ? "#F2F5FF" : "#F4F7F9"
  return (
    <svg
      className="w-1/2 md:w-3/4 my-4 mx-auto h-auto"
      xmlns="http://www.w3.org/2000/svg"
      width="304"
      height="545"
      fill="none"
      viewBox="0 0 304 545"
    >
      <rect width="304" height="545" fill={color1} rx="40" />
      <rect
        width="112.593"
        height="11.237"
        x="95.704"
        y="16.856"
        fill="#fff"
        rx="5.619"
      />
      <rect
        width="281.482"
        height="438.247"
        x="11.259"
        y="44.949"
        fill={color2}
        rx="5"
      />
      <rect width="105" height="90" x="20" y="67" fill={color1} rx="5" />
      <rect width="80" height="10" x="20" y="187" fill={color1} rx="5" />
      <rect width="50" height="10" x="20" y="167" fill={color1} rx="5" />
      <rect width="105" height="90" x="135" y="67" fill={color1} rx="5" />
      <rect width="80" height="10" x="135" y="187" fill={color1} rx="5" />
      <rect width="50" height="10" x="135" y="167" fill={color1} rx="5" />
      <rect width="50" height="90" x="250" y="67" fill={color1} rx="5" />
      <rect width="50" height="10" x="250" y="187" fill={color1} rx="5" />
      <rect width="50" height="10" x="250" y="167" fill={color1} rx="5" />
      <rect width="105" height="90" x="20" y="359" fill={color1} rx="5" />
      <rect width="80" height="10" x="20" y="479" fill={color1} rx="5" />
      <rect width="50" height="10" x="20" y="459" fill={color1} rx="5" />
      <rect width="105" height="90" x="135" y="359" fill={color1} rx="5" />
      <rect width="80" height="10" x="135" y="479" fill={color1} rx="5" />
      <rect width="50" height="10" x="135" y="459" fill={color1} rx="5" />
      <rect width="50" height="90" x="250" y="359" fill={color1} rx="5" />
      <rect width="50" height="10" x="250" y="479" fill={color1} rx="5" />
      <rect width="50" height="10" x="250" y="459" fill={color1} rx="5" />
      <rect width="105" height="90" x="20" y="213" fill={color1} rx="5" />
      <rect width="80" height="10" x="20" y="333" fill={color1} rx="5" />
      <rect width="50" height="10" x="20" y="313" fill={color1} rx="5" />
      <rect width="105" height="90" x="135" y="213" fill={color1} rx="5" />
      <rect width="80" height="10" x="135" y="333" fill={color1} rx="5" />
      <rect width="50" height="10" x="135" y="313" fill={color1} rx="5" />
      <rect width="50" height="90" x="250" y="213" fill={color1} rx="5" />
      <rect width="50" height="10" x="250" y="333" fill={color1} rx="5" />
      <rect width="50" height="10" x="250" y="313" fill={color1} rx="5" />
      <rect
        width="28.148"
        height="28.093"
        x="138.489"
        y="500.052"
        fill="#fff"
        rx="14.046"
      />
    </svg>
  )
}
