import React, { useState, useContext } from "react"
import { context as localeContext } from "context/locale"
import { Text, Link } from "components/service"
import * as translations from "constants/translations"
import styled from "styled-components"
import { Button } from "components/kit"
import * as paths from "paths.js"
import cx from "classnames"
import { ReactComponent as Illustration } from "assets/illustration-campaigns.svg"
import { ReactComponent as RetentionCampaignIcon } from 'assets/retention-campaign-refresh.svg';
import { ReactComponent as SegmentTargetingCampaignIcon } from 'assets/segment-targeting-users.svg';

export default () => {
  const { translate, lang } = useContext(localeContext)
  const [compaignType, setCampaignType] = useState("retention")
  return (
    <div className="flex flex-col items-center mt-24 pb-32 px-10 text-center">
      <Illustration />
      <Text
        className="text-lg mb-1 font-semibold mt-4"
        value={translations.START_FIRT_CAMPAIGN}
      />
      <Text
        className="text-gray-700 mb-6"
        value={translations.YOU_CAN_START_ADDING_CAMPAIGNS}
      />
      <div className="flex flex-wrap items-center justify-between text-gray-700">
        <div className="w-full md:w-1/2 p-1 ">
          <RadioG
            className="flex flex-col items-center px-2"
            checked={compaignType === "retention"}
          >
            <RetentionCampaignIcon
              className={cx(
                "h-10 fill-current",
                compaignType === "retention" && "text-primary-base"
              )}
            />
            <Text
              className={cx(
                "font-semibold my-2",
                compaignType === "retention" && "text-primary-base"
              )}
              value={translations.RETENTION}
            />

            <Text
              className="mb-4 text-gray-500 text-xs"
              value={translations.ENCOURAGE_YOUR_CURRENT_CUSTOMERS}
            />

            <input
              type="radio"
              name="campaignType"
              checked={compaignType === "retention"}
              onChange={() => setCampaignType("retention")}
              className="invisible md:visible"
            />
          </RadioG>
        </div>
        <div className="w-full md:w-1/2 p-1">
          <RadioG
            className="flex flex-col items-center px-2"
            checked={compaignType === "conversion"}
          >
            <SegmentTargetingCampaignIcon
              className={cx(
                "h-10 fill-current",
                compaignType === "conversion" && "text-primary-base"
              )}
            />
            <div
              className={cx(
                "font-semibold my-2",
                compaignType === "conversion" && "text-primary-base"
              )}
            >
              <Text value={translations.SEGMENT_TARGETING} className="inline" />
            </div>

            <Text
              className="mb-1 text-gray-500 text-xs"
              value={translations.SEGMENT_TARGETING_DESCRIPTION}
            />
            <input
              type="radio"
              name="campaignType"
              checked={compaignType === "conversion"}
              onChange={() => setCampaignType("conversion")}
              className="invisible md:visible"
            />
          </RadioG>
        </div>
      </div>
      <Link className="w-48 mt-4" to={paths.createCampaign}>
        <Button size="full" kind="primary">
          <Text value={translations.CREATE} />
        </Button>
      </Link>
    </div>
  )
}

const RadioG = styled.label`
  height: 172px;
  padding: 16px 0;

  cursor: ${({ dimmed }) => (dimmed ? "not-allowed;" : "pointer;")}
  border: #ecedf1 solid 1px;
  border-radius: 0.25rem;

  ${({ checked }) =>
    checked &&
    `
    border-color: #3852ca;
    `}
  ${({ dimmed }) =>
    dimmed &&
    `
    border-color: #eee;
		`}

  input {
    line-height: 22px;
    height: 22px;
    padding-left: 22px;
    position: relative;
    margin: 0 auto;

    &:before,
    &:after {
      content: "";
      width: 22px;
      height: 22px;
      display: block;
      border-radius: 50%;
      left: -1px;
      top: 0;
      position: absolute;
    }
    &:before {
      background: #d1d7e3;
      transition: background 0.2s ease,
        transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1);
    }
    &:after {
      background: #fff;
      transform: scale(0.9);
      transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
    }

    &:checked {
      &:before {
        transform: scale(1.04);
        background: #3852ca;
        ${({ dimmed }) =>
          dimmed &&
          `
            background: #e2e8f0;
		    `}
      }
      &:after {
        transform: scale(0.4);
        transition: transform 0.3s ease;
      }
    }
  }
`
