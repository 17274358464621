import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Error } from 'components/form/generic';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';

const sizes = {
  xs: 'py-1 px-2 text-base',
  sm: 'py-1 px-3 text-base',
  base: 'p-6 text-base',
};

const colors = {
  'primary-base': '#234cfb',
  'gray-600': '#718096',
};

const CampaignTypeRadioGroup = ({ items, onChange, value, name, size = 'base', error = '' }) => {
  const { lang, direction } = useContext(localeContext);
  const itemsLength = items.length;
  const handleOnClick = targetValue => {
    onChange({
      target: {
        name,
        value: targetValue,
      },
    });
  };
  return (
    <div>
      <div className={`grid grid-rows-${itemsLength} md:grid-cols-${itemsLength} md:gap-4 pb-32`}>
        {items.map((item, index) => {
          const isSelected = item.value === value;
          return (
            <div
              role="radiogroup"
              tabIndex={index}
              key={item.value}
              className={cx(
                'flex flex-row mt-4 items-center cursor-pointer border hover:border-primary-base',
                sizes[size],
                lang === 'en' ? '' : '',
                isSelected ? 'border-primary-base bg-primary-50' : 'border-gray-300',
              )}
              onKeyDown={() => handleOnClick(item.value)}
              onClick={() => handleOnClick(item.value)}
            >
              {item.icon && (
                <div className={cx('flex flex-col justify-start h-full', lang === 'en' ? 'pr-2' : 'pl-2')}>
                  {item.icon(isSelected ? colors['primary-base'] : colors['gray-600'])}
                </div>
              )}
              <div className="flex flex-col justify-start h-full">
                <Text
                  className={cx('font-semibold pb-1', isSelected ? 'text-primary-base' : 'text-gray-600')}
                  value={item.title}
                />
                <Text className="font-normal text-sm text-gray-600" value={item.description} />
              </div>
            </div>
          );
        })}
      </div>
      {error && <Error direction={direction}>{error}</Error>}
    </div>
  );
};

CampaignTypeRadioGroup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'base']),
  error: PropTypes.string,
};

export default CampaignTypeRadioGroup;
