import { gql } from 'graphql-request';

const createStoreDriverMutation = gql`
  mutation($input: CreateStoreDriverMutationInput!) {
    createStoreDriver(input: $input) {
      id
      branches
      storeId
      jobType
      email
      name
      phone
      isActive
      onShift
      errors {
        field
        messages
      }
    }
  }
`;

export default createStoreDriverMutation;
