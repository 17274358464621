import { gql } from "@apollo/client"

export const GET_LAYOUT = gql`
  query getSettings($storeId: String!) {
    settings(restaurantId: $storeId) {
      id
      restaurantId
      storeUi {
        menuSectionLayout
        menuItemsLayout
      }
    }
  }
`
export const UPDATE_LAYOUT = gql`
  mutation($storeId: String!, $menuItemsLayout: Int, $menuSectionLayout: Int) {
    updateSettings(
      restaurantId: $storeId
      menuItemsLayout: $menuItemsLayout
      menuSectionLayout: $menuSectionLayout
    ) {
      id
      restaurantId
      storeUi {
        menuSectionLayout
        menuItemsLayout
      }
    }
  }
`
